import React, { useState, useEffect } from "react";
import { BsFillGridFill, BsFilter, BsListUl } from "react-icons/bs";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  Card,
  Flex,
  Spacer,
  InputGroup,
  Input,
  TableContainer,
  Text,
  Button,
  Spinner,
  useToast,
  Table,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Link,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import { SearchIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/AuthProvider";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import {
  converterToThaiDate,
  converterToThaiDateAndTime,
} from "../../util/dateUtils";

import ReactPaginate from "react-paginate";
import "../../css/pagination.css";

import DiagnosisFilterModal from "../modal/DiagnosisFilterModal";
import PatientCard from "../card/PatientCard";
import FilterOptionDisplay from "../card/FilterOptionDisplay";

function Diagnosis() {
  const [userRole, setUserRole] = useState();
  const [userDoctorHospital, setUserDoctorHospital] = useState();
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [selectedPageLength, setSelectedPageLength] = useState(10);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    province: "",
    district: "",
    subdistrict: "",
    hospital: "",
    startDate: "",
    endDate: "",
    isDiagnosisChecked: true,
    isDiagnosisWait: true,
    isDiagnosisCav: true,
    isDiagnosisMetal: true,
  });

  const handleFilterSubmit = (selectedOptions) => {
    //console.log("Selected Filter Options:", selectedOptions);
    setFilterOptions(selectedOptions);
  };

  const auth = useAuth();
  const role = auth.user.role;
  const headers = {
    Authorization: auth.user.token,
  };
  const toast = useToast();

  const handlePageClick = async (event) => {
    const newOffset = event.selected * selectedPageLength;
    setOffset(newOffset);
    setCurrentPage(event.selected); // Set the current page

    fetchData(newOffset);
  };

  const handlePageLengthChange = (value) => {
    setSelectedPageLength(value);
    setOffset(0);
    setCurrentPage(0);
  };

  async function fetchData(offset) {
    try {
      setLoading(true);
      const response1 = await axios.post(
        `${API_BASE_URL}/api/site/diagnosis`,
        {
          searchText,
          limit: selectedPageLength,
          offset,
          filters: filterOptions,
        },
        { headers }
      );
      const data = response1.data.results;
      const total = response1.data.totalRecords;

      setTotalRecords(total);
      parseAndPushImages(data);
      setDiagnosisData(data);
      setPageCount(Math.ceil(total / selectedPageLength));
      setLoading(false);
    } catch (error) {
      toast({
        title: "ไม่สามารถติดต่อ server ได้",
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
      console.error("Error making API calls:", error.message);
      setLoading(false);
    }
  }

  function parseAndPushImages(array) {
    array.forEach((obj) => {
      const imageIds = obj.images
        .split(",")
        .reverse()
        .map((image) => ({ detected_filename: image }));

      if (!Array.isArray(obj.suspicious_point)) {
        obj.suspicious_point = [obj.suspicious_point];
      }

      obj.images = imageIds;
    });
  }

  async function fetchDataDoctor() {
    const response = await axios.post(
      `${API_BASE_URL}/api/site/doctor_detail`,
      {},
      { headers }
    );
    if (response.data.message === "success") {
      setUserDoctorHospital(response.data.results[0].hospital);
    } else {
      toast({
        title: response.data.message,
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
    }
  }
  useEffect(() => {
    if (selectedPageLength) {
      //alert('a')
      setOffset(0);
      setCurrentPage(0);
      fetchData(0);
      setUserRole(role);
      if (role === "doctor") {
        fetchDataDoctor();
      }
    }
  }, [filterOptions, selectedPageLength]);

  const columns = [
    "วันที่บันทึก",
    "ชื่อ - สกุล",
    "จำนวนจุดต้องสงสัยล่าสุด",
    "สถานะการวินิจฉัย",
    "บันทึกการวินิจฉัย",
  ];

  const handleSearch = () => {
    fetchData(offset);
  };

  const handleFilterChange = (filters) => {
    console.log(filters);
    setFilterOptions(filters);
  };

  const startDisplay = localStorage.getItem("displayStyle");
  const [displayStyle, setDisplayStyle] = useState(startDisplay);
  const toggleValue = () => {
    if (displayStyle === "card") {
      setDisplayStyle("table");
    } else {
      setDisplayStyle("card");
    }
  };
  useEffect(() => {
    localStorage.setItem("displayStyle", displayStyle);
  }, [displayStyle]);

  return (
    <>
      <Box p={4}>
        <Card mt={2} p={5} minH={"800px"}>
          <Flex mb={1} direction={{ base: "column", md: "row" }}>
            <Flex direction={"row"} pb={{ base: "1", md: "0" }}>
              <Button
                p={2}
                mr={3}
                mb={{ base: "1", md: "0" }}
                width={{ base: "3em", md: "auto" }}
                colorScheme={displayStyle === "card" ? "orange" : "blue"}
                onClick={toggleValue}
              >
                {displayStyle === "card" ? (
                  <BsListUl size={30} />
                ) : (
                  <BsFillGridFill size={30} />
                )}
              </Button>
              {userRole == "doctor" && (
                <Text
                  as="b"
                  fontSize="xl"
                  color={displayStyle === "card" ? "orange.700" : "blue.700"}
                  mt={1}
                >
                  {userDoctorHospital}
                </Text>
              )}
            </Flex>
            <Spacer />
            <Flex mb={6} direction={"column"} align={"end"}>
              <Flex>
                <Flex>
                  <InputGroup>
                    <Input
                      width={300}
                      placeholder="ค้นหาชื่อ..."
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSearch(searchText);
                        }
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button isDisabled={loading} size="md" onClick={handleSearch}>
                        ค้นหา
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>

                <Button
                  isDisabled={loading}
                  p={2}
                  ml={1}
                  mb={{ base: "1", md: "0" }}
                  width={{ base: "3em", md: "auto" }}
                  colorScheme={"teal"}
                  onClick={() => setIsFilterModalOpen(true)}
                >
                  <BsFilter size={30} />
                </Button>

                <DiagnosisFilterModal
                  isOpen={isFilterModalOpen}
                  onClose={() => setIsFilterModalOpen(false)}
                  userRole={userRole}
                  initialOptions={filterOptions}
                  onSubmit={handleFilterSubmit}
                />
              </Flex>
              <FilterOptionDisplay
                filterOptions={filterOptions}
                handleFilterChange={handleFilterChange}
              />
            </Flex>
          </Flex>

          {loading ? (
            <Box
              minH={"400px"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner
                thickness="5px"
                speed="0.75s"
                emptyColor="gray.200"
                color="green.600"
                size="lg"
              />
            </Box>
          ) : (
            <Box border="1px solid" borderColor="gray.200" borderRadius="md">
              <Flex
                pb={3}
                px={3}
                justify={"space-between"}
                bgColor="gray.50"
                border="1px solid"
                borderColor="gray.200"
              >
                <Select
                  maxW={150}
                  mt={3}
                  size="sm"
                  value={selectedPageLength}
                  onChange={(e) => handlePageLengthChange(e.target.value)}
                >
                  <option value="">เลือกจำนวนรายการ</option>
                  <option value={10}>แสดง 10 รายการ</option>
                  <option value={20}>แสดง 20 รายการ</option>
                  <option value={50}>แสดง 50 รายการ</option>
                  {/* Add more options as needed */}
                </Select>
                <Text mt={4} color="gray">
                  พบ {totalRecords} รายการ
                </Text>

                <ReactPaginate
                  nextLabel="ถัดไป"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="ก่อนหน้า"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                  renderOnZeroPageCount={null}
                  forcePage={currentPage}
                />
              </Flex>
              {displayStyle === "card" ? (
                <PatientCard diagnosisData={diagnosisData} />
              ) : (
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        {columns.map((col, index) => (
                          <Th key={index}>{col}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {diagnosisData.map((row, index) => (
                        <Tr key={index}>
                          <Td>
                            {converterToThaiDateAndTime(row.created_datetime)}
                          </Td>
                          <Td>{`${row.first_name} ${row.last_name}`}</Td>
                          <Td>{row.suspicious_point}</Td>
                          <Td>
                            <Flex
                              flex="1"
                              gap="4"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              <Box>
                                {row.diagnosis_status === "waiting" ? (
                                  <Text color={"orange"}>รอตรวจ</Text>
                                ) : (
                                  <Text color={"green"}>ตรวจแล้ว</Text>
                                )}
                              </Box>
                            </Flex>
                          </Td>
                          <Td>
                            <Link
                              as={ReactRouterLink}
                              to={`/diagnosis/${row.id}`}
                            >
                              คลิ๊กดู <ExternalLinkIcon mx="2px" />
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
}

export default Diagnosis;
