import moment from "moment";

export const converterToThaiDate = (birthday) => {
	const date = new Date(birthday);
	const options = { year: "numeric", month: "long", day: "numeric" };
	const thaiDate = date.toLocaleDateString("th-TH", options);
	return thaiDate;
};

export const isValidBuddhistDate = (date) => {
	const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
	if (!regex.test(date)) {
	  return false;
	}
	const [day, month, year] = date.split('/');
	const dayInt = parseInt(day, 10);
	const monthInt = parseInt(month, 10);
	const yearInt = parseInt(year, 10);
  
	// Check valid range for day, month and Buddhist year
	if (
	  dayInt < 1 || dayInt > 31 ||
	  monthInt < 1 || monthInt > 12 ||
	  yearInt < 2400 || yearInt > 2599
	) {
	  return false;
	}
  
	return true;
};

export const convertBuddhistDateToCristianDate = (date) => {
	const [day, month, buddhistYear] = date.split('/');
	const dayInt = day.padStart(2, '0'); // Ensure day is 2 digits
	  const monthInt = month.padStart(2, '0'); // Ensure month is 2 digits
	const christianYear = parseInt(buddhistYear, 10) - 543;
	return `${christianYear}-${monthInt}-${dayInt}`;
};

export const convertToThaiDate = (dateString) => {
	const thaiMonths = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    const [year, month, day] = dateString.split('-').map(Number);    
    const thaiYear = year + 543;
    const thaiMonth = thaiMonths[month - 1];
    return `${day} ${thaiMonth} ${thaiYear}`;
};

function formatDate(input) {
	// Check if the input is null
	if (input === null) {
	  return null; // Return null if the input is null
	}
  
	// Check if the input is a valid date string
	const dateObject = new Date(input);
	if (!isNaN(dateObject.getTime())) {
	  // If it's a valid date string, format it to YYYY-MM-DD
	  const year = dateObject.getFullYear();
	  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
	  const day = String(dateObject.getDate()).padStart(2, '0');
	  return `${year}-${month}-${day}`;
	} else {
	  // If it's not a valid date string, return null or handle it as needed
	  return null;
	}
  }

export const thaiMonthName = (number) => {
    const monthNames = {
      1: "มกราคม",
      2: "กุมภาพันธ์",
      3: "มีนาคม",
      4: "เมษายน",
      5: "พฤษภาคม",
      6: "มิถุนายน",
      7: "กรกฎาคม",
      8: "สิงหาคม",
      9: "กันยายน",
      10: "ตุลาคม",
      11: "พฤศจิกายน",
      12: "ธันวาคม"
    };

    return monthNames[number] || "Invalid month number";
  };

export const convertToThaiMonth = (englishMonth) => {
	const monthMapping = {
	  January: "มกราคม",
	  February: "กุมภาพันธ์",
	  March: "มีนาคม",
	  April: "เมษายน",
	  May: "พฤษภาคม",
	  June: "มิถุนายน",
	  July: "กรกฎาคม",
	  August: "สิงหาคม",
	  September: "กันยายน",
	  October: "ตุลาคม",
	  November: "พฤศจิกายน",
	  December: "ธันวาคม",
	};
  
	if(englishMonth){
		const titleCaseMonth = englishMonth.charAt(0).toUpperCase() + englishMonth.slice(1).toLowerCase();
		return monthMapping[titleCaseMonth] || "";
	}
	return "";
}

export const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if birthday has occurred this year
    const hasBirthdayOccurred =
      currentDate.getMonth() > birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() >= birthDate.getDate());

    // If birthday hasn't occurred yet, subtract 1 from the age
    if (!hasBirthdayOccurred) {
      age--;
    }

    return age;
  }

export  const formatThaiDate = (dateString) => {
    const date = new Date(dateString);
    const thaiMonthNames = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];

    const thaiYear = date.getFullYear() + 543;
    const thaiMonth = thaiMonthNames[date.getMonth()];
    const thaiDay = date.getDate();

    return `${thaiDay} ${thaiMonth} ${thaiYear}`;
  };

export const converterToThaiDateAndTime = (datetime) => {
	const date = new Date(datetime);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		// second: "numeric",
	};
	const thaiDate = date.toLocaleDateString("th-TH", options);
	return thaiDate;
};

export const converterForInput = (initialDateString) => {
	if (initialDateString !== null) {
		const dateObject = new Date(initialDateString);
		const year = dateObject.getFullYear();
		const month = String(dateObject.getMonth() + 1).padStart(2, "0");
		const day = String(dateObject.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	} else {
		return "";
	}
};

export const converterToAge = (birthday) => {
	const today = new Date();
	const birthDate = new Date(birthday);
	let age = today.getFullYear() - birthDate.getFullYear();
	if (
		today <
		new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
	) {
		age--;
	}
	return age;
};

export const thaiDateToMySQLDate = (thaiDateString) => {
	// Thai month names mapping
	const thaiMonths = {
		มกราคม: "01",
		กุมภาพันธ์: "02",
		มีนาคม: "03",
		เมษายน: "04",
		พฤษภาคม: "05",
		มิถุนายน: "06",
		กรกฎาคม: "07",
		สิงหาคม: "08",
		กันยายน: "09",
		ตุลาคม: "10",
		พฤศจิกายน: "11",
		ธันวาคม: "12",
	};

	// Extract components of the Thai date string
	const [day, month, year] = thaiDateString.split(" ");

	// Convert Thai Buddhist Era year to Common Era
	const buddhistYear = parseInt(year, 10) - 543;

	// Format the date as YYYY-MM-DD
	const mysqlDate = `${buddhistYear}-${thaiMonths[month]}-${day}`;

	return mysqlDate;
};

export const convertToDateForDatabase = (dateString) => {
	const parts = dateString.split("/");
	return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

export const excelSerialToJSDate = (serial) => {
	const utcDays = Math.floor(serial - 25569);
	const utcValue = utcDays * 86400;
	const dateInfo = new Date(utcValue * 1000);

	const year = dateInfo.getUTCFullYear();
	const month = dateInfo.getUTCMonth() + 1;
	const day = dateInfo.getUTCDate();

	return `${year}-${month < 10 ? "0" : ""}${month}-${
		day < 10 ? "0" : ""
	}${day}`;
};
