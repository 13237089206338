import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Flex,
	FormControl,
	Heading,
	Input,
	Stack,
	useColorModeValue,
	Text,
	useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

const ResetPassword = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const params = useParams();

	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};
	const handleConfirmPasswordChange = (event) => {
		setConfirmPassword(event.target.value);
	};
	const handleSubmitEvent = async (e) => {
		e.preventDefault();
		if (
			password === undefined ||
			password === "" ||
			confirmPassword === undefined ||
			confirmPassword === ""
		) {
			toast({
				title: "กรอกรหัสผ่านและยืนยันรหัสผ่านก่อนยืนยัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else if (password !== confirmPassword) {
			toast({
				title: "รหัสผ่านและยืนยันรหัสผ่านไม่เหมือนกัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/reset_password`,
					{ token: params.id, password },
					{}
				);
				if (response.data.message === "success") {
					toast({
						title: "เรียบร้อย",
						position: "top-right",
						status: "success",
						isClosable: true,
					});
					navigate("/login");
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						duration: 30000,
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};
	return (
		<>
			<Flex
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack
					spacing={4}
					w={"full"}
					maxW={"md"}
					bg={useColorModeValue("white", "gray.700")}
					rounded={"xl"}
					boxShadow={"lg"}
					p={6}
					my={12}
				>
					<Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
						กำหนดรหัสผ่าน
					</Heading>
					<Text
						fontSize={{ base: "sm", sm: "md" }}
						color={useColorModeValue("gray.800", "gray.400")}
					>
						กรุณาใส่รหัสผ่านใหม่ของคุณ
					</Text>
					<FormControl>
						<Input
						    placeholder="รหัสผ่าน"
							type="password"
							value={password}
							onChange={handlePasswordChange}
						/>
					</FormControl>
					<FormControl>
						<Input
						    placeholder="ยืนยันรหัสผ่าน"
							type="password"
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
						/>
					</FormControl>
					<Stack spacing={6}>
						<Button
							type="submit"
							bg={"blue.400"}
							color={"white"}
							_hover={{
								bg: "blue.500",
							}}
							onClick={handleSubmitEvent}
						>
							ยืนยัน
						</Button>
					</Stack>
				</Stack>
			</Flex>
		</>
	);
};

export default ResetPassword;
