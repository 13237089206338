import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme, { globalStyles } from "./theme/styles";

import Home from "./page/Home";
import Contact from "./page/Contact";
import About from "./page/About";
import NotFound from "./page/NotFound";

import Login from "./page/Login";
import Register from "./page/Register";
import ForgotPassword from "./page/ForgotPassword";
import ResetPassword from "./page/ResetPassword";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";

import Dashboard from "./components/screen/Dashboard";
import Diagnosis from "./components/screen/Diagnosis";
import Report from "./components/screen/Report";
import Usermanagement from "./components/screen/Usermanagement";
import Profile from "./components/screen/Profile";
import Undercare from "./components/screen/Undercare";
import UserListTable from "./components/screen/usermanagement/UserListTable";

import '@fontsource/kanit'; // Defaults to weight 400


function App() {
	return (
		<ChakraProvider theme={theme}>
			<Router>
				<AuthProvider>
					<Routes>
						<Route path="*" element={<NotFound />} />
						<Route path="/" element={<Home />} />
						<Route path="/contact" element={<Contact />}></Route>
						<Route path="/about" element={<About />}></Route>

						<Route path="/login" element={<Login />} />
						<Route path="/loginadmin" element={<Login loginRole={"admin"} />} />
						<Route path="/register" element={<Register />} />
						<Route path="/forgotpassword" element={<ForgotPassword />} />
						<Route path="/setpassword/:id" element={<ResetPassword />} />
						<Route element={<PrivateRoute />}>
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/diagnosis" element={<Diagnosis />} />
							<Route path="/diagnosis/:id" element={<Profile />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/report" element={<Report />} />
							<Route path="/usermanagement" element={<Usermanagement />} />
							<Route path="/undercare" element={<Undercare />} />
							<Route path="/undercare/:staff_id/:name/:email" element={<Undercare />} />
							<Route path="/userlist" element={<UserListTable />} />
						</Route>
					</Routes>
				</AuthProvider>
			</Router>
		</ChakraProvider>
	);
}

export default App;
