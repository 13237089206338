import React, { useState, useEffect, useRef } from "react";
import {
	Button,
	Flex,
	Image,
	AspectRatio,
	Box,
	Container,
	forwardRef,
	Heading,
	Input,
	Stack,
	Text,
	HStack,
	VStack,
	CircularProgress,
	CircularProgressLabel,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import axios from "axios";
import { AnimateKeyframes } from "react-simple-animate";
import io from "socket.io-client";
import constants from "../util/constant";
import consteps from "../util/steps";
import { FaDownload, FaUpload } from "react-icons/fa";

import DrawRectangles from "./DrawRectangles";

const UploadComponent = ({ setActiveStep, setDecayTeethNum }) => {
	const controls = useAnimation();
	const startAnimation = () => controls.start("hover");
	const stopAnimation = () => controls.stop();
	const [selectedFile, setSelectedFile] = useState();
	const [uploadingVal, setUploadingVal] = useState(0);
	const [uploadedImg, setUploadedImg] = useState("");
	const [imageID, setImageID] = useState("");
	const [detectedImg, setDetectedImg] = useState("");
	const [detectedNum, setDetectedNum] = useState(0);
	const [socketId, setSocketId] = useState(null);
	const [detectedClasses, setDetectedClass] = useState()
	const socketRef = useRef(null); // Store the socket instance

    const PreviewImage = forwardRef((props, ref) => {
        return (
            <Box
                bg="white"
                top="0"
                height="100%"
                width="100%"
                position="absolute"
                borderWidth="1px"
                borderStyle="solid"
                rounded="sm"
                borderColor="gray.400"
                as={motion.div}
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                {...props}
                ref={ref}
            />
        );
    });
    

	const {
		isOpen: isOpenReportModal,
		onOpen: openReportModal,
		onClose: closeReportModal,
	} = useDisclosure();

	const onFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const config = {
		onUploadProgress: (progressEvent) => {
			let percentComplete = progressEvent.loaded / progressEvent.total;
			percentComplete = parseInt(percentComplete * 100);
			setUploadingVal(percentComplete);
			// console.log("percent: " + percentComplete);
		},
	};

	useEffect(() => {
		if (!socketRef.current) {

			console.log("trying to connect socket id");
			const socket = io(constants.socketio_host, {
				secure: true,
				transports: ["websocket"],  // Ensure WebSocket is used
				reconnection: true,         // Allow reconnections in case of failure
				reconnectionAttempts: 5,    // Retry connection up to 5 times
			});

			socketRef.current = socket;

			// Listen for the 'connect' event
			socket.on("connect", () => {
				// Access the socket ID once the connection is established
				const newSocketId = socket.id;
				console.log('Socket ID:', newSocketId);
				setSocketId(newSocketId); // Store the socket ID in state
			});

			socket.on("cavity_detection", (data) => {
				// Handle the received data from the event
				//console.log('cavity_detection:', data);
				var obj = data.message;

				// console.log("socket_id:" + obj.socket_id);
				// console.log("socket.id:" + socket.id);

				if (`${obj.socket_id}` === socket.id) {
					setDetectedClass(obj.object_classess);
					setDetectedImg(obj.file);
					setDetectedNum(obj.detection_num);
					setActiveStep(3);
					setDecayTeethNum(obj.detection_num);
				}
			});

			// Make sure to handle any disconnections or errors as well
			socket.on("disconnect", () => {
				// console.log("Socket disconnected");
			});

			socket.on("error", (err) => {
				console.error("Socket connection error:", err);
			});
			

			return () => {
				if (socketRef.current) {
					console.log("Disconnecting socket...");
					socketRef.current.disconnect();
					socketRef.current = null;
				}
			};
		}
	}, [setActiveStep, setDecayTeethNum]);

	useEffect(() => {
		if (
			selectedFile &&
			uploadingVal === 0 &&
			uploadedImg === "" &&
			detectedImg === ""
		) {
			onFileUpload();
		}
		if (
			selectedFile &&
			uploadingVal === 100 &&
			uploadedImg !== "" &&
			detectedImg === ""
		) {
			setActiveStep(2);
			console.log(`socketId: ${socketId}, uploadedImg: ${uploadedImg}, imageID: ${imageID}`)

			axios
				.get(`${constants.host}/test_detect`, {
					params: {
						img: `${uploadedImg}`,
						image_id: imageID,
						socket_id: socketId,
					},
				})
				.then((res) => {
					var resp = res.data;
					// console.log(resp);
					//alert(resp.file)
				});
		}
	}, [selectedFile, uploadingVal, uploadedImg]);

	const onFileUpload = () => {
		const formData = new FormData();

		formData.append("file", selectedFile, selectedFile.name);

		// console.log(selectedFile);
		axios.post(`${constants.host}/upload_img`, formData, config).then((res) => {
			var resp = res.data;
			console.log(resp)
			setImageID(resp.image_id)
			setUploadedImg(resp.file);
		});
	};

	const downloadImage = () => {
		//saveAs(`${constants.host}/show_img?img=${detectedImg}`, `${detectedImg}`);
		const link = document.createElement('a');
		link.download = 'image_with_rectangles.png';
		link.href = `${detectedImg}`;
		link.click();
	  };
	
	  const handleParentDownload = (ref) => {
		if (ref.current) {
			const canvas = ref.current;
			//const link = document.createElement('a');
			//link.download = 'image_with_rectangles.png';
			//link.href = canvas.toDataURL('image/png');
			//link.click();
	
			setDetectedImg(canvas.toDataURL('image/png'));
		}
	  };

	return (
		<>
			<Modal
				onClose={closeReportModal}
				isOpen={isOpenReportModal}
				size="5xl"
				isCentered
				zIndex="2000"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>ผลการตรวจจับตำแหน่งความผิดปกติโดย AI</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Image
							cursor={"pointer"}
							alt={"Hero Image"}
							fit={"cover"}
							align={"center"}
							w={"100%"}
							h={"100%"}
							//src={`${constants.host}/show_img?img=${detectedImg}`}
							src={`${detectedImg}`}
						/>
					</ModalBody>
					<ModalFooter>
						<Button onClick={closeReportModal}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Container my={{ base: "1", md: "5" }} centerContent zIndex={0}>
				{uploadingVal === 0 && (
					<AspectRatio width="72%" ratio={3 / 2}>
						<Box
							bgColor="blue.400"
							borderColor="gray.300"
							borderStyle="dashed"
							borderWidth="2px"
							rounded="md"
							shadow="sm"
							role="group"
							transition="all 150ms ease-in-out"
							_hover={{
								shadow: "md",
							}}
							as={motion.div}
							initial="rest"
							animate="rest"
							whileHover="hover"
						>
							<Box position="relative" height="100%" width="100%">
								<Box
									position="absolute"
									top="0"
									left="0"
									height="100%"
									width="100%"
									display="flex"
									flexDirection="column"
								>
									<Stack
										pt={4}
										height="100%"
										width="100%"
										display="flex"
										alignItems="center"
										justify="center"
										spacing="1"
									>
										<Box height="20" width="14" position="relative">
											<PreviewImage
												variants={consteps.first}
												backgroundImage="/images/mouth1.jpg"
											/>
											<PreviewImage
												variants={consteps.second}
												backgroundImage="/images/mouth3.jpg"
											/>
											<PreviewImage
												variants={consteps.third}
												backgroundImage="/images/mouth2.jpg"
											/>
										</Box>
										<Stack pt="4" textAlign="center" spacing="1">
											<Heading
												fontSize={{ base: "sm", md: "lg" }}
												color="gray.700"
												fontWeight="bold"
											>
												กดเพื่ออัพโหลดภาพ
											</Heading>
											<Text
												display={{ base: "none", md: "flex" }}
												fontWeight="light"
											>
												หรือวางภาพถ่ายช่องปากที่นี้
											</Text>
										</Stack>
									</Stack>
								</Box>
								<Input
									onChange={onFileChange}
									type="file"
									height="100%"
									width="100%"
									position="absolute"
									top="0"
									left="0"
									opacity="0"
									aria-hidden="true"
									accept="image/*"
									onDragEnter={startAnimation}
									onDragLeave={stopAnimation}
								/>
							</Box>
						</Box>
					</AspectRatio>
				)}

				{uploadingVal !== 0 && uploadingVal !== 100 && (
					<VStack>
						<CircularProgress
							value={uploadingVal}
							color="yellow.400"
							size="120px"
						>
							<CircularProgressLabel>{uploadingVal}%</CircularProgressLabel>
						</CircularProgress>
						<Text textColor="gray.300">กำอัพโหลดภาพ...</Text>
					</VStack>
				)}

				{uploadingVal === 100 && uploadedImg !== "" && detectedImg === "" && (
					<VStack w="full" h="full" m={0}>
						<AspectRatio width="full" maxW="250" ratio={2 / 1.5}>
							<AnimateKeyframes
								play
								duration={1.5}
								iterationCount="infinite"
								keyframes={[
									{ 0: "opacity: 0" }, // 0%
									{ 50: "opacity: 0.5" }, // 50%
									{ 100: "opacity: 1" }, // 100%
								]}
								delay={1}
							>
								<Image
									alt={"Hero Image"}
									fit={"cover"}
									align={"center"}
									w={"100%"}
									h={"100%"}
									src={`${constants.host}/show_img?img=${uploadedImg}`}
								/>
							</AnimateKeyframes>
						</AspectRatio>
						<div fontFamily="mono" className="loading">
							กำลังประมวลผล...
						</div>
					</VStack>
				)}

				{detectedClasses && <DrawRectangles setCanvasRef={(ref)=>{handleParentDownload(ref)}} imageUrl={`${constants.host}/show_img?img=${uploadedImg}`} data={detectedClasses} />}

				{uploadingVal === 100 && uploadedImg !== "" && detectedImg !== "" && (
					<Flex>
						<VStack>
							<AspectRatio width="full" ratio={3 / 2}>
								<Image
									borderColor="gray.300"
									borderWidth="2px"
									rounded="md"
									shadow="sm"
									cursor={"pointer"}
									alt={"Hero Image"}
									fit={"cover"}
									align={"center"}
									w={"100%"}
									h={"100%"}
									//src={`${constants.host}/show_img?img=${detectedImg}`}
									src={`${detectedImg}`}
									onClick={openReportModal}
								/>
							</AspectRatio>
							<HStack>
								<Button
									color="gray.700"
									size="sm"
									variant="ghost"
									leftIcon={<FaDownload />}
									onClick={downloadImage}
								>
									ดาวน์โหลดภาพ
								</Button>
								<Button
									color="gray.700"
									size="sm"
									variant="ghost"
									leftIcon={<FaUpload />}
									onClick={() => {
										setDetectedImg("");
										setUploadedImg("");
										setUploadingVal(0);
										setSelectedFile("");
										setActiveStep(0);
										setDetectedNum(0);
										setDecayTeethNum(0);
										setDetectedClass()
									}}
								>
									อัพโหลดภาพใหม่
								</Button>
							</HStack>
						</VStack>
						<Box
							display={{ base: "none", sm: "none", md: "flex" }}
							ml={{ base: 1, md: 4 }}
							p={{ base: 1, md: 4 }}
							h="full"
							minWidth={{ base: "100", sm: "100", md: "150" }}
							minHeight={180}
							w="full"
							bgColor="blue.400"
							borderColor="gray.300"
							borderStyle="dashed"
							borderWidth="2px"
							rounded="md"
							shadow="sm"
						>
							<VStack pt={{ base: 4, md: 1 }}>
								<Text
									textColor={"black"}
									fontSize={{ base: 14, sm: 14, md: 20 }}
								>
									พบความเสี่ยง
								</Text>
								<Flex>
									<Text
										textColor={"yellow.300"}
										fontSize={{ base: 30, sm: 30, md: 40 }}
									>
										{detectedNum}
									</Text>
								</Flex>
								<Text
									textColor={"black"}
									fontSize={{ base: 14, sm: 14, md: 20 }}
								>
									ตำแหน่ง
								</Text>
							</VStack>
						</Box>
					</Flex>
				)}
			</Container>
		</>
	);
};

export default UploadComponent;