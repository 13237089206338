import React, { useState } from "react";
import {
	Button,
	Flex,
	FormControl,
	Heading,
	Input,
	Stack,
	useColorModeValue,
	Text,
	useToast,
	Select,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

const ForgotPassword = () => {
	const toast = useToast();
	const [role, setRole] = useState();
	const [email, setEmail] = useState();

	const handleRoleChange = (event) => {
		setRole(event.target.value);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};
	const handleSubmitEvent = async (e) => {
		e.preventDefault();
		if (email === undefined || email === "") {
			toast({
				title: "กรอกอีเมลที่ต้องการตั้งรหัสผ่านใหม่",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/forgot_password`,
					{ role, email },
					{}
				);
				if (response.data.message === "success") {
					toast({
						title: "เรียบร้อย",
						description: "กรุณาตรวจสอบอีเมลของท่าน",
						position: "top-right",
						status: "success",
						isClosable: true,
					});
					setEmail("");
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						duration: 30000,
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};
	return (
		<>
			<Flex
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack
					spacing={4}
					w={"full"}
					maxW={"md"}
					bg={useColorModeValue("white", "gray.700")}
					rounded={"xl"}
					boxShadow={"lg"}
					p={6}
					my={12}
				>
					<Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
						ลืมรหัสผ่าน
					</Heading>
					<Text
						fontSize={{ base: "sm", sm: "md" }}
						color={useColorModeValue("gray.800", "gray.400")}
					>
						กรุณาเลือกประเภทผู้ใช้งานและระบุอีเมล <br></br>
						เราจะทำการส่งลิ้งเพื่อตั้งรหัสผ่านใหม่ไปยังอีเมลของคุณ
					</Text>
					<FormControl id="role">
						<Select
							name="role"
							value={role}
							onChange={handleRoleChange}
							placeholder="- เลือก ประเภทผู้ใช้งาน -"
						>
							<option value="public">ผู้ใช้งานทั่วไป</option>
							<option value="doctor">หมอ</option>
						</Select>
					</FormControl>
					<FormControl id="email">
						<Input
							placeholder="your-email@example.com"
							_placeholder={{ color: "gray.500" }}
							type="email"
							value={email}
							onChange={handleEmailChange}
						/>
					</FormControl>
					<Stack spacing={6}>
						<Button
							type="submit"
							bg={"blue.400"}
							color={"white"}
							_hover={{
								bg: "blue.500",
							}}
							onClick={handleSubmitEvent}
						>
							ยืนยัน
						</Button>
					</Stack>
				</Stack>
			</Flex>
		</>
	);
};

export default ForgotPassword;
