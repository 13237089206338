import React, { useState, useEffect, useRef } from 'react';

function getTHLabel(label){
    if(label == "Metal"){
        return "โลหะ"
    }
    else if(label == "Cav"){
        return "ฟันผุ"
    }
    else{
        return label
    }
}

function getLabelColor(label){
    if(label == "Metal"){
        return "blue"
    }
    else if(label == "Cav"){
        return "red"
    }
    else{
        return "green"
    }
}

function DrawRectangles({ imageUrl, data, setCanvasRef}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Ensuring canvas is available before accessing its context
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.crossOrigin = "anonymous";

    image.onload = () => {
      setImageLoaded(true);
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      
      // Draw rectangles and labels
      data.forEach(obj => {
        ctx.beginPath();
        ctx.rect(obj.x, obj.y, obj.w, obj.h);
        ctx.lineWidth = 5;
        ctx.strokeStyle = 'white';
        ctx.stroke();
        ctx.lineWidth = 3;
        ctx.strokeStyle = getLabelColor(obj.detected_type);
        ctx.stroke();

        ctx.font = '17px Arial';
        ctx.fillStyle = 'white';
        ctx.fillText(getTHLabel(obj.detected_type), obj.x, obj.y - 10);
        
        ctx.font = '16px Arial';
        ctx.fillStyle = getLabelColor(obj.detected_type);
        ctx.fillText(getTHLabel(obj.detected_type), obj.x, obj.y - 10);
      });

      setCanvasRef(canvasRef)
    };
    image.src = imageUrl;
  }, [imageUrl, data]);

  return (
    <div style={{display: "none"}}>
      <canvas ref={canvasRef} />
      {!imageLoaded && <p>Loading image...</p>}
    </div>
  );
}

export default DrawRectangles;
