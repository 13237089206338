import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import {
	Card,
	Flex,
	Box,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Input,
	TableContainer,
	InputGroup,
	InputLeftElement,
	Icon,
	Spacer,
	Center,
	Text,
	useToast,
	Spinner,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	HStack,
	Select,
	Avatar,
	VStack,
	Image,
	Link,
} from "@chakra-ui/react";
import {
	EditIcon,
	DeleteIcon,
	SearchIcon,
	ExternalLinkIcon,
	ArrowUpIcon,
} from "@chakra-ui/icons";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import {
	getUndercareColumns,
	validateNewUndercare,
} from "../../util/undercareUtil";
import { convertBuddhistDateToCristianDate, converterToAge, converterToThaiDate, convertToThaiDate, isValidBuddhistDate } from "../../util/dateUtils";
import EditUndercareModal from "../modal/EditUndercareModal";
import Papa from 'papaparse';
import Swal from "sweetalert2";

const Undercare = () => {
	const [undercares, setUndercares] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(10); // Default page size
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [totalRecords, setTotalRecords] = useState();

	const [isNewUndercareCSVModalOpen, setIsNewUndercareCSVModalOpen] =
		useState(false);

	const [editUndercare, setEditUndercare] = useState(null);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const [deleteUndercareId, setDeleteUndercareId] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const [arrSaveCSVData, setArrSaveCSVData] = useState();

	const [loading, setLoading] = useState(true);
	const fileInputRef = useRef(null);
	const [isValidCSV, setIsValidCSV] = useState(false);
	const [isUploadingCSV, setIsUploadingCSV] = useState(false);
	const [isCheckingCSV, setIsCheckingCSV] = useState(false);

	const auth = useAuth();
	const hospitals_id = auth.user.hospitals_id;
	const hospital_name = auth.user.hospital_name;
	const toast = useToast();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const tableHeaders = [
		"ชื่อ - สกุล",
		"วันเกิด",
		"สังกัด/หน่วยงาน",
		"สังกัด/หน่วยงาน(ย่อย)",
		"สังกัดโรงพยาบาล",
		"บันทึกการวินิจฉัย",
	];

	const params = useParams();

	useEffect(() => {
		fetchData(1, searchTerm);
	}, [pageSize]);

	useEffect(() => {
		if (editUndercare) {
			setIsEditModalOpen(true);
		}
	}, [editUndercare]);

	const fetchData = async (pageNumber = 1, searchTerm = "") => {
		var data = { pageNumber, pageSize, searchTerm }
		if(params.staff_id){
			data = { pageNumber, pageSize, searchTerm, user_id:  params.staff_id}
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/undercare`,
				data,
				{ headers }
			);
			const totalCount = response.data.totalCount;
			setUndercares(response.data.undercares);
			setPageCount(Math.ceil(totalCount / pageSize));
			setTotalRecords(totalCount);
			setLoading(false);
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};

	const handleNewUndercareModalOpen = () => {
		setIsEditModalOpen(true);
	};

	const handleAddNewUndercare = async (userData) => {
		if (validateNewUndercare(userData, toast)) {
			try {
				var data = { newUndercare: userData }
				if(params.staff_id){
					data = { newUndercare: userData, user_id:  params.staff_id}
				}

				const response = await axios.post(
					`${API_BASE_URL}/api/site/undercare_add`,
					data,
					{ headers }
				);
				if (response.data.message === "success") {
					fetchData();
					setIsEditModalOpen(false);
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	const handleNewUndercareCSVModalOpen = () => {
		setIsNewUndercareCSVModalOpen(true);
	};
	const csvFileRef = useRef(null);
	const handleDownloadClick = async () => {
		csvFileRef.current.click();
	};

	const handleAddNewUndercareCSV = async () => {
		const hasEmptyHospitalsId = arrSaveCSVData.some(
			(item) => item.hospitals_id === ""
		);
		if (hasEmptyHospitalsId) {
			toast({
				title: "กรุณาระบุข้อมูลสังกัดโรงพยาบาลให้ครบก่อนยืนยัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else {
			arrSaveCSVData.forEach((item) => {
				item.birthday = item.birthday || "";
			});
			try {
				var data = { arrSaveCSVData }
				console.log(arrSaveCSVData)
				if(params.staff_id){
					data = { arrSaveCSVData, user_id:  params.staff_id}
				}

				setIsUploadingCSV(true)
				const response = await axios.post(
					`${API_BASE_URL}/api/site/undercare_add_csv`,
					data,
					{ headers }
				);
				setIsUploadingCSV(false)

				if (response.data.message === "success") {
					setIsNewUndercareCSVModalOpen(false);
					setArrSaveCSVData(null);
					fetchData();
					
					toast({
						title: "เพิ่มผู้ใต้ความดูแลใหม่เรียบร้อย",
						position: "top-right",
						status: "success",
						isClosable: true,
					});
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						isClosable: true,
					});
				}
			} catch (error) {
				setIsUploadingCSV(false)
				toast({
					title: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			Papa.parse(file, {
				header: true,
				complete: async (results) => {
					let isValidInput = true;
					let invalidMSG = "";
					const idCardSet = new Set();
					const idCardArray = [];
					const requiredColumns = ["ชื่อ", "สกุล", "บัตรประชาชน", "วันเกิด", "สังกัด/หน่วยงาน", "สังกัด/หน่วยงาน ย่อย"];
					const colHeaders = results.meta.fields;
	
					// Check if all required columns are present
					const missingColumns = requiredColumns.filter(col => !colHeaders.includes(col));
					if (missingColumns.length > 0) {
						isValidInput = false;
						invalidMSG = `ไฟล์ CSV ขาดคอลัมน์ที่จำเป็น: ${missingColumns.join(', ')}. กรุณาตรวจสอบและลองใหม่อีกครั้ง.`;
					}
	
					if (isValidInput) {
						const filteredData = results.data.filter((row) => {
							const firstName = row["ชื่อ"];
							const lastName = row["สกุล"];
							const idCard = row["บัตรประชาชน"];
							const birthDate = row["วันเกิด"];
	
							if (!firstName && !lastName && !idCard) {
								return false; // Ignore this row if all fields are empty
							}
	
							if ((!firstName || !lastName || !idCard) && (firstName || lastName || idCard)) {
								isValidInput = false;
								invalidMSG = `แถวข้อมูลมีข้อผิดพลาด: ชื่อ, สกุล, และบัตรประชาชนต้องมีค่าครบทุกช่อง กรุณาตรวจสอบข้อมูล.`;
								return false;
							}
	
							if (!/^\d{13}$/.test(idCard)) {
								isValidInput = false;
								invalidMSG = `บัตรประชาชน ${idCard} ต้องเป็นตัวเลข 13 หลักเท่านั้น กรุณาตรวจสอบข้อมูล (หากเปิดไฟล์ใน Excel กรุณาใส่เครื่องหมาย ' นำหน้าเพื่อทำให้ข้อมูลอยู่ในรูปแบบ text เช่น '1350700001400)`;
								return false;
							}
	
							if (idCardSet.has(idCard)) {
								isValidInput = false;
								invalidMSG = `บัตรประชาชน ${idCard} ซ้ำ กรุณาตรวจสอบข้อมูล.`;
								return false;
							}
	
							idCardSet.add(idCard);
							idCardArray.push(idCard);
	
							if (birthDate && !isValidBuddhistDate(birthDate)) {
								isValidInput = false;
								invalidMSG = `วันเกิด ${birthDate} ไม่อยู่ในรูปแบบ DD/MM/YYYY โดย YYYY คือ พ.ศ. กรุณาตรวจสอบข้อมูล (หากเปิดไฟล์ใน Excel กรุณาใส่เครื่องหมาย ' นำหน้าเพื่อทำให้ข้อมูลอยู่ในรูปแบบ text เช่น '21/03/2530)`;
								return false;
							}
	
							return true;
						});
	
						if (isValidInput && idCardArray.length > 0) {
							//console.log(`idCardArray: ${idCardArray}`)
							try {
								setIsCheckingCSV(true)
								const response = await axios.post(`${API_BASE_URL}/api/site/check_id_cards`, { idCards: idCardArray }, { headers });
								const { redundantIdCards } = response.data;
								setIsCheckingCSV(false)
	
								if (redundantIdCards.length > 0) {
									isValidInput = false;
									invalidMSG = `บัตรประชาชน ${redundantIdCards.join(', ')} มีอยู่ในฐานข้อมูลแล้ว กรุณาตรวจสอบข้อมูล.`;
								}
							} catch (error) {
								isValidInput = false;
								invalidMSG = `เกิดข้อผิดพลาดในการตรวจสอบบัตรประชาชนจากฐานข้อมูล: ${error.message}`;
							}
						}
	
						if (isValidInput) {
							const formattedData = filteredData.map((row) => ({
								first_name: row["ชื่อ"],
								last_name: row["สกุล"],
								id_card: row["บัตรประชาชน"],
								birthday: row["วันเกิด"] ? convertBuddhistDateToCristianDate(row["วันเกิด"]):"",
								organization: row["สังกัด/หน่วยงาน"] ?? "",
								organization_sub: row["สังกัด/หน่วยงาน ย่อย"] ?? "",
								hospitals_id: hospitals_id, // assuming hospitals_id is the same for all rows
							}));
	
							console.log(formattedData);
							setArrSaveCSVData(formattedData);
	
							if (formattedData.length > 0) {
								setIsValidCSV(true)
							} else {
								setIsValidCSV(false)
								Swal.fire({
									title: "ไม่พบข้อมูลสำหรับนำเข้า",
									text: `กรุณากรอกข้อมูลตามรูปแบบไฟล์ CSV ตัวอย่าง`,
									icon: "warning"
								});
							}
						} else {
							Swal.fire({
								title: "กรุณาตรวจสอบข้อมูล",
								text: `${invalidMSG}`,
								icon: "warning"
							});
						}
					} else {
						Swal.fire({
							title: "กรุณาตรวจสอบข้อมูล",
							text: `${invalidMSG}`,
							icon: "warning"
						});
					}
				},
			});
		}
	
		clearFileInput();
	};		  

	const handleEdit = (row) => {
		setEditUndercare(row);
	};
	const handleEditDone = async (undercareData) => {
		if (validateNewUndercare(undercareData, toast)) {
			try {
				var data = { editedUndercare: undercareData }
				if(params.staff_id){
					data = { editedUndercare: undercareData, user_id:  params.staff_id}
				}

				const response = await axios.post(
					`${API_BASE_URL}/api/site/undercare_edit`,
					data,
					{ headers }
				);

				if (response.data.message === "success") {
					setEditUndercare(null);
					setIsEditModalOpen(false);
					fetchData();
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	const handleDelete = (userId) => {
		setDeleteUndercareId(userId);
		setIsDeleteModalOpen(true);
	};
	const handleDeleteConfirm = async () => {
		try {
			var data = { deleteUndercareId }
			if(params.staff_id){
				data = { deleteUndercareId, user_id:  params.staff_id}
			}

			const response = await axios.post(
				`${API_BASE_URL}/api/site/undercare_delete`,
				data,
				{ headers }
			);

			if (response.data.message === "success") {
				setDeleteUndercareId(null);
				setIsDeleteModalOpen(false);
				fetchData();
			} else {
				toast({
					title: response.data.message,
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};
	const handleDeleteCancel = () => {
		setIsDeleteModalOpen(false);
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchTerm(value);

		// Clear previous search timeout
		clearTimeout(searchTimeout);

		// Set a new search timeout
		const newSearchTimeout = setTimeout(() => {
			fetchData(1, value);
		}, 1500); // Adjust delay as needed

		setSearchTimeout(newSearchTimeout);
	};

	const onCloseEditModal = () => {
		setIsEditModalOpen(false);
		setEditUndercare(null);
	};

	const handlePageChange = ({ selected }) => {
		fetchData(selected + 1, searchTerm);
	};

	const handlePageSizeChange = (e) => {
		setPageSize(parseInt(e.target.value, 10));
	};

	const clearFileInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	const UserProfile = ({ name, email }) => {
		return (
			<Box
				w={"100%"}
				borderWidth="1px"
				borderRadius="lg"
				overflow="hidden"
				boxShadow="sm"
				p={5}
				bg="white"
			>
				<HStack spacing={4} alignItems="center">
					<VStack align="start" spacing={1}>
						<Flex>
							<Text fontWeight="bold" fontSize="lg" mr={1}>รายชื่อผู้ใต้ความดูแลของ: </Text>
							<Text fontSize="lg">{name}</Text>
						</Flex>
						{email && email !== "null" && <Text color="gray.500">อีเมลติดต่อ: {email}</Text>}
					</VStack>
				</HStack>
			</Box>
		);
	};

	const FormattedDataTable = ({ data }) => {
		return (
		  <TableContainer>
			<Table variant="simple">
			  <Thead>
			  <Tr>
					{[
						"ชื่อ",
						"สกุล",
						"บัตรประชาชน",
						"วันเกิด",
						"สังกัด/หน่วยงาน",
						"สังกัด/หน่วยงาน ย่อย",
						"สังกัดโรงพยาบาล",
					].map((label, index) => (
						<Th
							key={index}
							fontSize="1em"
							fontWeight="bold"
							color={"teal"}
							pb={3}
							textAlign={"center"}
						>
							{label}
						</Th>
					))}
				</Tr>
			  </Thead>
			  <Tbody>
				{data.map((row, index) => (
				  <Tr key={index}>
					<Td>{row.first_name}</Td>
					<Td>{row.last_name}</Td>
					<Td>{row.id_card}</Td>
					<Td>{row.birthday?convertToThaiDate(row.birthday):"-"}</Td>
					<Td>{row.organization ?? "-"}</Td>
					<Td>{row.organization_sub ?? "-"}</Td>
					<Td>{hospital_name}</Td>
				  </Tr>
				))}
			  </Tbody>
			</Table>
		  </TableContainer>
		);
	  };

	return (
		<Flex direction={"column"}>
			{params.staff_id && <Flex px={4} w="100%">
				<UserProfile name={params.name} email={params.email} />
			</Flex>}	

			<Box p={4}>
				<Card p={5}>
					<Flex mb={6} direction={{ base: "column", md: "row" }}>
						<Flex
							direction={{ base: "column", md: "row" }}
							pb={{ base: "1", md: "0" }}
						>
							<Button
								p={3}
								mr={3}
								mb={{ base: "1", md: "0" }}
								width={{ base: "full", md: "auto" }}
								colorScheme="teal"
								onClick={handleNewUndercareModalOpen}
							>
								เพิ่มผู้ใต้ความดูแลใหม่รายบุคคล
							</Button>
							<Button
								p={3}
								colorScheme="teal"
								width={{ base: "full", md: "auto" }}
								onClick={handleNewUndercareCSVModalOpen}
							>
								เพิ่มผู้ใต้ความดูแลใหม่รายกลุ่มจาก CSV
							</Button>
						</Flex>
						<Spacer />
						<Box>
							<InputGroup>
								<InputLeftElement
									pointerEvents="none"
									children={<Icon as={SearchIcon} color="gray.300" />}
								/>
								<Input
									placeholder="ค้นหาชื่อ..."
									value={searchTerm}
									onChange={handleSearch}
								/>
							</InputGroup>
						</Box>
					</Flex>

					<Box border="1px solid" borderColor="gray.200" borderRadius="md">
						<Flex
							mb={2}
							pb={3}
							px={3}
							justify={"space-between"}
							bgColor="gray.50"
							border="1px solid"
							borderColor="gray.200"
						>
							<Select
								maxW={150}
								mt={3}
								size="sm"
								value={pageSize}
								onChange={handlePageSizeChange}
							>
								<option value={10}>แสดง 10 รายการ</option>
								<option value={20}>แสดง 20 รายการ</option>
								<option value={50}>แสดง 50 รายการ</option>
							</Select>
							<Text mt={4} color="gray">
								พบ {totalRecords} รายการ
							</Text>
							<ReactPaginate
								pageCount={pageCount}
								pageRangeDisplayed={5}
								marginPagesDisplayed={2}
								onPageChange={handlePageChange}
								previousLabel="ก่อนหน้า"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextLabel="ถัดไป"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								activeLinkClassName="active-link"
								renderOnZeroPageCount={null}
							/>
						</Flex>
						{loading ? (
							<Box
								minH={"400px"}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Spinner
									thickness="5px"
									speed="0.75s"
									emptyColor="gray.200"
									color="green.600"
									size="lg"
								/>
							</Box>
						) : (
							<TableContainer>
								<Table variant="simple">
									<Thead>
										<Tr>
											{tableHeaders.map((header, index) => (
												<Th
													key={index}
													style={{ fontSize: "14px", fontWeight: "700" }}
												>
													{header}
												</Th>
											))}
											<Th></Th>
										</Tr>
									</Thead>
									<Tbody>
										{undercares.map((data) => (
											<Tr key={data.id}>
												<Td>
													{data.first_name} {data.last_name}
												</Td>
												<Td>
													{data.birthday !== null && data.birthday !== "" ? (
														<>{converterToThaiDate(data.birthday)}</>
													) : (
														"-"
													)}
												</Td>
												<Td>{data.organization}</Td>
												<Td>{data.organization_sub}</Td>
												<Td>{data.hospital}</Td>
												<Td>
													<ReactRouterLink to={`/diagnosis/${data.id}`}>
														คลิ๊กดู <ExternalLinkIcon mx="2px" />
													</ReactRouterLink>
												</Td>
												<Td>
													<HStack spacing={2}>
														<button onClick={() => handleEdit(data)}>
															<EditIcon boxSize={5} color="blue.500" />
														</button>
														<button onClick={() => handleDelete(data.id)}>
															<DeleteIcon boxSize={5} color="red.500" />
														</button>
													</HStack>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</TableContainer>
						)}
					</Box>
				</Card>
			</Box>

			{/* Add New Undercare Modal CSV */}
			<Modal
				isOpen={isNewUndercareCSVModalOpen}
				onClose={() => {
					setIsNewUndercareCSVModalOpen(false);
					setArrSaveCSVData(null);
					clearFileInput();
				}}
				size={"full"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color="green">
					เพิ่มผู้ใต้ความดูแลใหม่จาก CSV
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Button colorScheme="blue" onClick={handleDownloadClick}>
							<Icon icon="tabler:download" color="white" />
							<Text ml="0.5em" fontSize="13px">
								โหลดไฟล์แบบฟอร์มสำหรับกรอกข้อมูล
							</Text>
						</Button>
						<a
							ref={csvFileRef}
							href="https://lingosoft.co/files/format.csv"
							download="แบบฟอร์มสำหรับกรอกข้อมูล.csv"
							style={{ display: "none" }}
						/>
						<Center mt={2} border={"1px dashed gray"} minH={500}>
							{arrSaveCSVData && arrSaveCSVData.length > 0 && <FormattedDataTable data={arrSaveCSVData} />}
							{(!arrSaveCSVData || arrSaveCSVData.length == 0)  && <Flex>
								{!isCheckingCSV && 
								<VStack>
									<Image w="80%" p={5} objectFit={"contain"} src="/excel/csv_example.png" />
									<Button as="label" leftIcon={<ArrowUpIcon />} htmlFor="fileInput" colorScheme="teal" cursor={"pointer"}>
										อัพโหลดแบบฟอร์ม
										<Input
											ref={fileInputRef}
											type="file"
											id="fileInput"
											accept=".csv"
											display="none"
											onChange={handleFileChange}
										/>
									</Button>
									<Link download={"แบบฟอร์มสำหรับกรอกข้อมูล.csv"} href="https://lingosoft.co/files/format.csv">
										<Text color="blue.500">กรุณาดาวน์โหลดตัวอย่างไฟล์ CSV และทำการกรอกข้อมูลก่อนอัพโหลด</Text>
									</Link> 
								</VStack>
								}
								{isCheckingCSV && <Flex>
									<Spinner
										thickness="5px"
										speed="0.75s"
										emptyColor="gray.200"
										color="green.600"
										size="lg"
										mr={1}
									/>
									<Text>ตรวจสอบความถูกต้องข้อมูล...</Text>
								</Flex>}
							</Flex>}
						</Center>
					</ModalBody>
					<ModalFooter>
						<Flex w="100%" justify={"space-between"}>
							<Button colorScheme="blue" isDisabled={!arrSaveCSVData || arrSaveCSVData.length <= 0?true:false}
								onClick={() => {
									setArrSaveCSVData(null);
									clearFileInput();
								}}
							ml={2}>
								ล้างข้อมูล
							</Button>
							<Flex>
								<Button isLoading={isUploadingCSV} colorScheme="green" isDisabled={!isValidCSV || (!arrSaveCSVData || arrSaveCSVData.length <= 0)} onClick={handleAddNewUndercareCSV}>
									ยืนยัน
								</Button>
								<Button
									onClick={() => {
										setIsNewUndercareCSVModalOpen(false)
										setArrSaveCSVData(null);
										clearFileInput();
									}}
									ml={2}
								>
									ยกเลิก
								</Button>
							</Flex>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<EditUndercareModal
				isModalOpen={isEditModalOpen}
				onCloseModal={onCloseEditModal}
				userInfo={editUndercare}
				handleEditDone={handleEditDone}
				handleAddDone={handleAddNewUndercare}
			/>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"red.500"}>ลบข้อมูล</ModalHeader>
					<ModalCloseButton />
					<ModalBody>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" onClick={handleDeleteConfirm}>
							ลบ
						</Button>
						<Button onClick={handleDeleteCancel} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default Undercare;


