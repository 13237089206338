import React, { useEffect, useState } from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const FilterOptionDisplay = ({ filterOptions, handleFilterChange }) => {
  const [filters, setFilters] = useState({});
  const [optionsToDisplay, setOptionsToDisplay] = useState([]);

  useEffect(() => {
    setFilters(filterOptions);
  }, [filterOptions]);

  useEffect(() => {
    const updatedOptionsToDisplay = [];

    if (filters.province) {
      updatedOptionsToDisplay.push({
        key: "province",
        value: "จังหวัด: " + filters["selected_province"],
      });
    }
    if (filters.district) {
      updatedOptionsToDisplay.push({
        key: "district",
        value: "อำเภอ: " + filters["selected_district"],
      });
    }
    if (filters.subdistrict) {
      updatedOptionsToDisplay.push({
        key: "subdistrict",
        value: "ตำบล: " + filters["selected_subdistrict"],
      });
    }
    if (filters.hospital) {
      updatedOptionsToDisplay.push({
        key: "hospital",
        value: "โรงพยาบาล: " + filters["selected_hospital"],
      });
    }
    if (filters.startDate) {
      updatedOptionsToDisplay.push({
        key: "startDate",
        value: "ข้อมูลหลัง: " + filters.startDate,
      });
    }
    if (filters.endDate) {
      updatedOptionsToDisplay.push({
        key: "endDate",
        value: "ข้อมูลก่อน: " + filters.endDate,
      });
    }
    if (filters.isDiagnosisChecked && !filters.isDiagnosisWait) {
      updatedOptionsToDisplay.push({
        key: "isDiagnosisChecked",
        value: "สถานะตรวจแล้ว",
      });
    }
    if (!filters.isDiagnosisChecked && filters.isDiagnosisWait) {
      updatedOptionsToDisplay.push({
        key: "isDiagnosisWait",
        value: "สถานะรอตรวจ",
      });
    }
    if (filters.isDiagnosisCav && !filters.isDiagnosisMetal) {
      updatedOptionsToDisplay.push({ key: "isDiagnosisCav", value: "พบฟันผุ" });
    }
    if (!filters.isDiagnosisCav && filters.isDiagnosisMetal) {
      updatedOptionsToDisplay.push({
        key: "isDiagnosisMetal",
        value: "อุดอะมัลกัม",
      });
    }

    setOptionsToDisplay(updatedOptionsToDisplay);
  }, [filters]);

  const removeFilterOption = (key) => {
    const newFilterOptions = { ...filters };
    //delete newFilterOptions[key];
    if (typeof newFilterOptions[key] === "boolean") {
      newFilterOptions[key] = false;
      console.log("It is a boolean!");
    } else {
      newFilterOptions[key] = "";
      console.log("It is not a boolean!");

      if (key == "province") {
        newFilterOptions["district"] = "";
        newFilterOptions["subdistrict"] = "";
        newFilterOptions["hospital"] = "";
      } else if (key == "district") {
        newFilterOptions["subdistrict"] = "";
        newFilterOptions["hospital"] = "";
      } else if (key == "subdistrict") {
        newFilterOptions["hospital"] = "";
      }
    }

    handleFilterChange(newFilterOptions);
  };

  return (
    <Flex wrap="wrap" mt={2}>
      {optionsToDisplay.length > 0 && (
        <Flex justify={"center"} align={"center"}>
          <Text fontSize={12} mr={1}>
            กรองด้วย:{" "}
          </Text>
        </Flex>
      )}
      {optionsToDisplay.map((option, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Text mr={1} fontSize={12} color="grey"></Text>}
          <Flex
            justify={"center"}
            pl={1}
            align={"center"}
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
          >
            <Text fontSize={12} color="grey">
              {option.value}
            </Text>
            <IconButton
              icon={<CloseIcon />}
              size="xs"
              variant="ghost"
              colorScheme="orange"
              onClick={() => removeFilterOption(option.key)}
            />
          </Flex>
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default FilterOptionDisplay;
