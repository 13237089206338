import React, { useState, useEffect } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import axios from "axios";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Box,
    Flex,
    Text,
    Spacer,
    InputGroup,
    InputLeftElement,
    Icon,
    Badge,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Select,
    Grid,
    useToast,
    TableContainer,
    Link,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, SearchIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import ReactPaginate from "react-paginate";
import { API_BASE_URL } from "../../../config/apiConfig";
import { useAuth } from "../../../hooks/AuthProvider";

const UserListTable = ({ setCountUser, countUser }) => {
    const toast = useToast();
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10); // Default page size
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [totalRecords, setTotalRecords] = useState();

    const auth = useAuth();
    const role = auth.user.role;
    const headers = {
        Authorization: auth.user.token,
    };

    const tableHeaders = [
        "ชื่อ - สกุล",
        "อีเมลติดต่อ",
        "สังกัดโรงพยาบาล",
        "จำนวนผู้ใต้ความดูแล",
        "เข้าดู",
    ];

    const fetchData = async (pageNumber = 1, searchTerm = "") => {
        try {
            const response1 = axios.post(
                `${API_BASE_URL}/api/site/list_user`,
                { pageNumber, pageSize, searchTerm, hid: auth.user.hospitals_id },
                { headers }
            );
            const [data1] = await axios.all([response1]);
            const totalCount = data1.data.totalCount;

            // console.log(data1.data.doctors);
            setUsers(data1.data.users);
            setPageCount(Math.ceil(totalCount / pageSize));
            setTotalRecords(totalCount);
            //setCountUser(totalCount);
        } catch (error) {
            console.error("Error fetching doctors:", error);
        }
    };

    const handlePageChange = ({ selected }) => {
        fetchData(selected + 1, searchTerm);
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        // Clear previous search timeout
        clearTimeout(searchTimeout);

        // Set a new search timeout
        const newSearchTimeout = setTimeout(() => {
            fetchData(1, value);
        }, 1500); // Adjust delay as needed

        setSearchTimeout(newSearchTimeout);
    };
    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value, 10));
    };
    useEffect(() => {
        if(auth && auth.user.hospitals_id){
            fetchData(1, searchTerm);
        }
        //console.log(auth.user)
    }, [pageSize]);

    return <>
        <Flex mt={3} mb={3} justify={"space-between"}>
            <Flex>
                <Text fontSize={18} mr={1}>ผู้ใช้งานในสังกัด</Text>
                <Text fontSize={18}>{auth.user.hospital_name}</Text>
                </Flex>
            <Box>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<Icon as={SearchIcon} color="gray.300" />}
                    />
                    <Input
                        placeholder="ค้นหาชื่อ..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </InputGroup>
            </Box>
        </Flex>
        <Box border="1px solid" borderColor="gray.200" borderRadius="md">
            <Flex
                mb={2}
                pb={3}
                px={3}
                justify={"space-between"}
                bgColor="gray.50"
                border="1px solid"
                borderColor="gray.200"
            >
                <Select
                    maxW={150}
                    mt={3}
                    size="sm"
                    value={pageSize}
                    onChange={handlePageSizeChange}
                >
                    <option value={10}>แสดง 10 รายการ</option>
                    <option value={20}>แสดง 20 รายการ</option>
                    <option value={50}>แสดง 50 รายการ</option>
                    {/* Add more options as needed */}
                </Select>
                <Text mt={4} color="gray">
                    พบ {totalRecords} รายการ
                </Text>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    previousLabel="ก่อนหน้า"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLabel="ถัดไป"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    activeLinkClassName="active-link"
                    renderOnZeroPageCount={null}
                />
            </Flex>
            <TableContainer>
                <Table variant={"simple"} colorScheme={"green"} bgColor="white">
                    <Thead>
                        <Tr>
                            {tableHeaders.map((header, index) => (
                                <Th
                                    key={index}
                                    style={{ fontSize: "16px", fontWeight: "900" }}
                                >
                                    {header}
                                </Th>
                            ))}
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users?.map((user) => (
                            <Tr key={user.id}>
                                <Td>
                                    {user.first_name} {user.last_name}
                                </Td>
                                <Td>{user.email}</Td>
                                <Td>{user.hospital}</Td>
                                <Td>{user.under_care_count}</Td>
                                <Td>
                                    <HStack spacing={2}>
                                        <ExternalLinkIcon color={"green.600"} mx="2px" />
                                        <Link
                                            as={ReactRouterLink}
                                            to={`/undercare/${user.id}/${user.first_name} ${user.last_name}/${user.email}`}
                                        >
                                        คลิ๊กดู 
                                        </Link>
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    </>
}

export default UserListTable;