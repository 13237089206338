import React, { useState, useEffect } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import Select from "react-select";
import Chart from "react-apexcharts";
import axios from "axios";
import { API_BASE_URL } from "../../../config/apiConfig";
import { useAuth } from "../../../hooks/AuthProvider";
import { thaiMonthName } from "../../../util/dateUtils";

const DiagnosisChart = () => {
	const currentYear = new Date().getFullYear();
	const [year, setYear] = useState(currentYear);
	const [data, setData] = useState();
	const [chartOption, setChartOption] = useState()

	const auth = useAuth();
	const role = auth.user.role;
	const headers = {
		Authorization: auth.user.token,
	};

	useEffect(() => {
		const fetchData = async () => {
		  try {
			const response = await axios.post(`${API_BASE_URL}/api/site/diagnosis_stat`, {
			  year: year,
			},{ headers });

			setData(response.data.results);
		  } catch (error) {
			console.error("Error fetching data:", error);
		  }
		};
	
		fetchData();
	  }, [year]);

	  useEffect(()=>{
		if(data){
			setChartOption({
				options: {
					xaxis: {
						categories: data.map((item) => thaiMonthName(item.month)),
					},
				},
				series: [
					{
						name: "จำนวนการใช้งานระบบ",
						data: data.map((item) => item.diagnosis_count),
					},
				],
			})
		}
	  },[data])

	const yearOptions = Array.from({ length: 11 }, (_, index) => ({
		value: currentYear - index,
		label: `ปี ${currentYear - index}`,
	}));

	const handleYearChange = (selectedOption) => {
		setChartOption(null)
		setYear(selectedOption.value);
	};

	return (
		<Flex
		  direction={"column"}
		  w={"100%"}
		  bgColor={"white"}
		  p={5}
		  align={"center"}
		>
		  <Text fontSize="xl" mb={2}>
		  	กราฟแสดงจำนวนครั้งในการใช้งานระบบปี {year}
		  </Text>
		  <Select
			options={yearOptions}
			onChange={handleYearChange}
			defaultValue={yearOptions.find(
			  (option) => option.value === currentYear
			)}
		  />
		  <Flex
			w={"100%"}
			bgColor={"white"}
			direction={"column"}
			justify={"center"}
		  >
			{chartOption && (
			  <Chart
				options={chartOption.options}
				series={chartOption.series}
				type="bar"
				height={350}
			  />
			)}
		  </Flex>
		  {!chartOption && <Spinner mt={10} size={"md"} />}
		</Flex>
	  );
};

export default DiagnosisChart;
