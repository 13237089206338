import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Text,
  HStack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Switch,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  converterToThaiDate,
} from "../../util/dateUtils";

const PatientCard = ({ diagnosisData }) => {
  const bg = useColorModeValue("white", "gray.800");
  const [mainImages, setMainImages] = useState(
    diagnosisData.map((product) => "mockup.png")
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [showOriginal, setShowOriginal] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const urlImageFile = "https://flaskapi.dent.lingosoft.co/show_img?img=";

  const handleSmallImageClick = (index, smallImage) => {
    setMainImages((prevMainImages) => {
      const newMainImages = [...prevMainImages];
      newMainImages[index] = smallImage;
      return newMainImages;
    });
  };

  const handleMainImageClick = (image) => {
    setShowOriginal(false);
    setSelectedImage(image);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const handleImageError = () => {
    setImageLoading(false);
  };

  const handleSwitchImage = () => {
    setImageLoading(true);
    setShowOriginal(!showOriginal);
  };

  const getImageSrc = () => {
    if (showOriginal) {
      return selectedImage.replace("_output", "");
    } else {
      return selectedImage;
    }
  };

  useEffect(() => {
    setMainImages(
      diagnosisData.map((product) => {
        if (product.images.length === 0) {
          return "mockup.png";
        } else {
          return product.images[product.images.length-1].detected_filename;
        }
      })
    );
  }, [diagnosisData]);

  return (
    <Box py={6}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {diagnosisData && diagnosisData.length > 0 ? (
          diagnosisData.map((product, index) => (
            <Box
              key={`diag_${index}`}
              mx={"10px"}
              mb={"15px"}
              role={"group"}
              p={2}
              pb={3}
              maxW={"240px"}
              w={{ base: "175px", md: "full" }}
              bg={bg}
              boxShadow={"md"}
              rounded={"lg"}
              pos={"relative"}
              zIndex={1}
              cursor="pointer"
            >
              {mainImages[index]}
              <Box
                rounded={"lg"}
                mt={-10}
                pos={"relative"}
                height={"130px"}
                _after={{
                  transition: "all .3s ease",
                  content: '""',
                  w: "full",
                  h: "full",
                  pos: "absolute",
                  top: 5,
                  left: 0,
                  backgroundImage: `url(${urlImageFile}${mainImages[index]})`,
                  filter: "blur(15px)",
                  zIndex: -1,
                }}
                _groupHover={{
                  _after: {
                    filter: "blur(20px)",
                  },
                }}
              >
                <Image
                  rounded={"lg"}
                  height={150}
                  width={282}
                  objectFit={"cover"}
                  src={`${urlImageFile}${mainImages[index]}`}
                  alt="#"
                  onClick={() =>
                    handleMainImageClick(`${urlImageFile}${mainImages[index]}`)
                  }
                />
              </Box>
              <Box pt={3} align={"center"} pb={2}>
                <HStack spacing={2} justify="center" mt={4}>
                  {product.images.length > 5
                    ? product.images
                        .slice(-5)
                        .map((image, imgIndex) => (
                          <Image
                            key={`${imgIndex}`}
                            boxSize="38px"
                            objectFit="cover"
                            borderRadius={5}
                            src={`${urlImageFile}${image.detected_filename}`}
                            alt={`Small Image ${image.detected_filename}`}
                            cursor="pointer"
                            onClick={() =>
                              handleSmallImageClick(
                                index,
                                image.detected_filename
                              )
                            }
                          />
                        ))
                    : product.images.map((image, imgIndex) => (
                        <Image
                          key={`${imgIndex}`}
                          boxSize="38px"
                          objectFit="cover"
                          borderRadius={5}
                          src={`${urlImageFile}${image.detected_filename}`}
                          alt={`Small Image ${image.detected_filename}`}
                          cursor="pointer"
                          onClick={() =>
                            handleSmallImageClick(
                              index,
                              image.detected_filename
                            )
                          }
                        />
                      ))}
                </HStack>
              </Box>

              <Text fontSize={"sm"}>
                {product.first_name} {product.last_name}
              </Text>
              <Text color={"gray.500"} fontSize={"xs"}>
                {converterToThaiDate(product.created_datetime)}
              </Text>
              <Box textAlign={"right"}>
                <ReactRouterLink
                  target="_blank"
                  to={`/diagnosis/${product.id}`}
                  fontSize={"sm"}
                >
                  บันทึกการวินิจฉัย <ExternalLinkIcon />
                </ReactRouterLink>
              </Box>
            </Box>
          ))
        ) : (
          <Box mt={"24px"}>ไม่พบข้อมูล</Box>
        )}
      </div>

      {selectedImage && (
        <Modal
          isOpen={!!selectedImage} /* !!selectedImage means selectedImage !== null && selectedImage !== undefined */
          onClose={() => setSelectedImage("")}
          size="6xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Flex justify={"center"} align={"center"}>
                <Text fontSize={15}>
                  เลื่อนเพื่อแสดงภาพ
                  {!showOriginal ? " original" : "ที่ตรวจจับแล้ว"}
                </Text>
                <Switch
                  isDisabled={imageLoading}
                  mt={1}
                  colorScheme="teal"
                  size="md"
                  isChecked={showOriginal}
                  onChange={handleSwitchImage}
                  ml={2}
                />
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {imageLoading && (
                <Flex align={"center"} justify={"center"} h={400} maxH="100vh">
                  <Spinner size="md" mr={1} />
                  <Text>loading...</Text>
                </Flex>
              )}
              <Image
                src={getImageSrc()}
                alt="Enlarged Image"
                objectFit="contain"
                maxH="100vh"
                mx="auto"
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ display: imageLoading ? "none" : "block" }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default PatientCard;
