import React from "react";
import {
	Box,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	VStack,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	List,
	ListItem,
} from "@chakra-ui/react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const About = () => {
	return (
		<>
			<Header />
			<Navbar />
			<Container maxW={"7xl"}>
				<SimpleGrid
					columns={{ base: 1, lg: 2 }}
					spacing={{ base: 8, md: 10 }}
					py={{ base: 18, md: 24 }}
				>
					<Flex>
						<Image
							rounded={"md"}
							alt={"product image"}
							src={"/images/tooth-detection.svg"}
							fit={"cover"}
							align={"center"}
							w={"100%"}
							h={{ base: "100%", sm: "400px", lg: "500px" }}
						/>
					</Flex>
					<Stack spacing={{ base: 6, md: 10 }}>
						<Box as={"header"}>
							<Heading
								lineHeight={1.1}
								fontWeight={600}
								fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
							>
								เกี่ยวกับเรา
							</Heading>
							<Text
								color={useColorModeValue("gray.900", "gray.400")}
								fontWeight={300}
								fontSize={"2xl"}
							>
								About US
							</Text>
						</Box>

						<Stack
							spacing={{ base: 4, sm: 6 }}
							direction={"column"}
							divider={
								<StackDivider
									borderColor={useColorModeValue("gray.200", "gray.600")}
								/>
							}
						>
							<VStack spacing={{ base: 4, sm: 6 }}>
								<Text
									color={useColorModeValue("gray.500", "gray.400")}
									fontSize={"xl"}
									fontWeight={"400"}
								>
									ระบบตรวจจับฟันผุจากภาพถ่ายผ่านเว็บเป็นระบบที่ถูกพัฒนาขึ้นเพื่อช่วยในการตรวจสอบและวินิจฉัยสภาพของฟันผุจากภาพถ่ายที่ถ่ายมาผ่านเว็บเบราว์เซอร์
									ระบบนี้มีการใช้เทคโนโลยีการประมวลผลภาพและการเรียนรู้โดยใช้ปัญญาประดิษฐ์
									(Artificial Intelligence)
									เพื่อวิเคราะห์และตรวจสอบฟันผุในภาพถ่ายอัตโนมัติ
									ซึ่งช่วยลดเวลาและความล่าช้าในการวินิจฉัยสภาพฟันผุได้มากขึ้น
								</Text>
							</VStack>
							<Box>
								<Text
									fontSize={{ base: "16px", lg: "18px" }}
									color={useColorModeValue("yellow.500", "yellow.300")}
									fontWeight={"500"}
									textTransform={"uppercase"}
									mb={"4"}
								>
									คุณลักษณะ
								</Text>

								<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
									<List spacing={2}>
										<ListItem>ตรวจจับบริเวณที่คาดว่าจะมีเนื้อฟันผุ</ListItem>
										<ListItem>ตรวจจับบริเวณที่มีเนื้อฟันผิดปกติ</ListItem>{" "}
										<ListItem>บอกเปอร์เซ็นต์ความน่าจะเป็น</ListItem>
									</List>
									{/*<List spacing={2}>
                  <ListItem>ตรวจสอบปัญหา</ListItem>
                  <ListItem>Chronometer</ListItem>
                  <ListItem>Small seconds</ListItem>
                </List>*/}
								</SimpleGrid>
							</Box>
							<Box>
								<Text
									fontSize={{ base: "16px", lg: "18px" }}
									color={useColorModeValue("yellow.500", "yellow.300")}
									fontWeight={"500"}
									textTransform={"uppercase"}
									mb={"4"}
								>
									ประโยชน์
								</Text>

								<List spacing={2}>
									<ListItem>
										<Text as={"span"} fontWeight={"regular"}>
											ตรวจจับฟันผุอัตโนมัติ:
											แอพสามารถตรวจสอบและวินิจฉัยฟันผุจากภาพถ่ายอัตโนมัติ
											ทำให้ผู้ใช้ไม่ต้องพึ่งพาการวินิจฉัยของผู้เชี่ยวชาญในด้านทันตกรรมเพื่อรับรู้สภาพของฟันเอง
											ซึ่งช่วยให้อำนวยความสะดวกในการตรวจสอบฟันผุและสภาพทันตกรรมของผู้ใช้งานเอง
										</Text>
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"regular"}>
											การลดเวลาในการวินิจฉัย:
											ระบบตรวจจับฟันผุผ่านเว็บช่วยลดเวลาในการวินิจฉัยสภาพของฟันผุอย่างมีประสิทธิภาพ
											ทำให้สามารถรับรู้สภาพฟันผุได้ทันท่วงที
										</Text>
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"regular"}>
											ความสะดวกสบายและเข้าถึงได้ทุกที่ทุกเวลา:
											ระบบตรวจจับฟันผุผ่านเว็บทำให้ผู้ใช้สามารถใช้งานได้ทุกที่ทุกเวลาผ่านทางเว็บเบราว์เซอร์
											ไม่จำเป็นต้องมีการติดตั้งแอพพลิเคชันเพิ่มเติมในอุปกรณ์
										</Text>
									</ListItem>
								</List>
							</Box>
						</Stack>
					</Stack>
				</SimpleGrid>
			</Container>
			<Footer />
		</>
	);
};
export default About;
