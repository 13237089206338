import { extendTheme } from '@chakra-ui/react';
import '@fontsource/kanit'; // Import Kanit font

const theme = extendTheme({
  fonts: {
    heading: `'Kanit', sans-serif`, // Use Kanit for headings
    body: `'Kanit', sans-serif`,    // Use Kanit for body text
  },
});

export default theme;