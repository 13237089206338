import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Grid,
  Button,
  Checkbox,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";

const DiagnosisFilterModal = ({
  isOpen,
  onClose,
  userRole,
  initialOptions,
  onSubmit,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(initialOptions);

  const auth = useAuth();
  const role = auth.user.role;
  const headers = {
    Authorization: auth.user.token,
  };

  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    fetchProvinces();
  }, []);

  useEffect(() => {
    setSelectedOptions(initialOptions);
  }, [initialOptions]);

  const fetchProvinces = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/site/province_list`,
        {},
        { headers }
      );

      const data = await response.data.results;
      setProvinces(data);
      setSubDistricts([]);
      setHospitals([]);
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  const fetchDistricts = async (provinceId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/site/district_list`,
        { provinceId },
        { headers }
      );
      const data = await response.data.results;
      setDistricts(data);
      setHospitals([]);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const fetchSubDistricts = async (districtId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/site/sub_district_list`,
        { districtId },
        { headers }
      );
      const data = await response.data.results;
      setSubDistricts(data);
    } catch (error) {
      console.error("Error fetching sub-districts:", error);
    }
  };

  const fetchHospitals = async (subDistrictId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/site/hospital_list`,
        { subDistrictId },
        { headers }
      );
      const data = await response.data.results;
      setHospitals(data);
    } catch (error) {
      console.error("Error fetching hospitals:", error);
    }
  };

  const handleSelectChange = (field, value, key) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));

    if (field === "province") {
      fetchDistricts(value);
      setSubDistricts([]);
      setHospitals([]);

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        selected_province: key,
      }));
    } else if (field === "district") {
      fetchSubDistricts(value);
      setHospitals([]);

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        selected_district: key,
      }));
    } else if (field === "subdistrict") {
      fetchHospitals(value);
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        selected_subdistrict: key,
      }));
    } else if (field === "hospital") {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        selected_hospital: key,
      }));
    }
  };
  const handleCheckboxChange = (field) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [field]: !prevOptions[field],
    }));
  };

  const handleDateChange = (field, value) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    // Pass the selected options to the parent component
    onSubmit(selectedOptions);
    onClose();
  };

  return (
    <Modal size={"4xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>กรองข้อมูล</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {userRole == "admin" ? (
            <>
              <FormControl mb={8}>
                <FormLabel>สังกัด</FormLabel>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                  }}
                  gap={2}
                >
                  <Select
                    placeholder="- เลือกจังหวัด -"
                    onChange={(e) => {
                      const selectedIndex = e.target.selectedIndex;
                      const selectedOptionText =
                        e.target.options[selectedIndex].text;
                      handleSelectChange(
                        "province",
                        e.target.value,
                        selectedOptionText
                      );
                    }}
                    value={selectedOptions.province || ""}
                  >
                    {provinces.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    placeholder="- เลือกอำเภอ -"
                    onChange={(e) => {
                      const selectedIndex = e.target.selectedIndex;
                      const selectedOptionText =
                        e.target.options[selectedIndex].text;
                      handleSelectChange(
                        "district",
                        e.target.value,
                        selectedOptionText
                      );
                    }}
                    value={selectedOptions.district || ""}
                  >
                    {districts.map((district) => (
                      <option key={district.id} value={district.id}>
                        {district.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    placeholder="- เลือกตำบล -"
                    onChange={(e) => {
                      const selectedIndex = e.target.selectedIndex;
                      const selectedOptionText =
                        e.target.options[selectedIndex].text;
                      handleSelectChange(
                        "subdistrict",
                        e.target.value,
                        selectedOptionText
                      );
                    }}
                    value={selectedOptions.subdistrict || ""}
                  >
                    {subDistricts.map((subDistrict) => (
                      <option key={subDistrict.id} value={subDistrict.id}>
                        {subDistrict.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    placeholder="- เลือกโรงพยาบาล -"
                    onChange={(e) => {
                      const selectedIndex = e.target.selectedIndex;
                      const selectedOptionText =
                        e.target.options[selectedIndex].text;
                      handleSelectChange(
                        "hospital",
                        e.target.value,
                        selectedOptionText
                      );
                    }}
                    value={selectedOptions.hospital || ""}
                  >
                    {hospitals.map((hospital) => (
                      <option key={hospital.id} value={hospital.id}>
                        {hospital.name}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </FormControl>
            </>
          ) : (
            ""
          )}

          <FormControl mb={8}>
            <FormLabel>ช่วงวันที่บันทึก</FormLabel>
            <InputGroup>
              <InputLeftAddon children="จาก" />
              <Input
                type="date"
                borderRightRadius={"none"}
                onChange={(e) => handleDateChange("startDate", e.target.value)}
                value={selectedOptions.startDate || ""}
              />
              <InputLeftAddon children="ถึง" borderRadius={"none"} />
              <Input
                type="date"
                onChange={(e) => handleDateChange("endDate", e.target.value)}
                value={selectedOptions.endDate || ""}
              />
            </InputGroup>
          </FormControl>
          <FormControl mb={8}>
            <FormLabel>สถานะการวินิจฉัย</FormLabel>
            <Stack direction="row">
              <Checkbox
                isChecked={selectedOptions.isDiagnosisChecked}
                colorScheme="teal"
                pr={"3em"}
                onChange={() => handleCheckboxChange("isDiagnosisChecked")}
              >
                ตรวจแล้ว
              </Checkbox>
              <Checkbox
                isChecked={selectedOptions.isDiagnosisWait}
                colorScheme="teal"
                onChange={() => handleCheckboxChange("isDiagnosisWait")}
              >
                รอตรวจ
              </Checkbox>
            </Stack>
          </FormControl>
          <FormControl mb={8}>
            <FormLabel>สถานะการวินิจฉัย</FormLabel>
            <Stack direction="row">
              <Checkbox
                isChecked={selectedOptions.isDiagnosisCav}
                colorScheme="teal"
                pr={"5em"}
                onChange={() => handleCheckboxChange("isDiagnosisCav")}
              >
                ฟันผุ
              </Checkbox>

              <Checkbox
                isChecked={selectedOptions.isDiagnosisMetal}
                colorScheme="teal"
                pr={"4.5em"}
                onChange={() => handleCheckboxChange("isDiagnosisMetal")}
              >
                อุดอะมัลกัม
              </Checkbox>
            </Stack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>
            กรอง
          </Button>
          <Button ml={2} onClick={onClose}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DiagnosisFilterModal;
