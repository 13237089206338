import React, { useState, useEffect } from "react";
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import {
	Button,
	Link,
	Grid,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	Box,
	useColorModeValue,
	Center,
	Text,
	useToast,
	Select,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

const Register = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const [inputRegister, setInputRegister] = useState({
		user_type: "",
		first_name: "",
		last_name: "",
		id_card: "",
		day: "",
		month: "",
		year: "",
		email: "",
		password: "",
		confrim_password: "",
		hospitals_id: "",
	});
	const [daysInMonth, setDaysInMonth] = useState(Array.from({ length: 31 }, (_, i) => i + 1));
	const [optionProvince, setOptionProvince] = useState([]);
	const [optionDistrict, setOptionDistrict] = useState([]);
	const [optionSubDistrict, setOptionSubDistrict] = useState([]);
	const [optionHospital, setOptionHospital] = useState([]);

	const monthsInThai = [
		"มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
		"พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
		"กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
	];

	useEffect(() => {
		async function fetchData() {
			try {
				const res = await axios.get(`${API_BASE_URL}/api/site/provinces`);
				setOptionProvince(res.data.results);
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}

		fetchData();
	}, [toast]);

	const fetchDistricts = async (provinceId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/districts/${provinceId}`
			);
			setOptionDistrict(response.data.results);
			setOptionSubDistrict([]);
			setOptionHospital([]);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};
	const fetchSubDistricts = async (districtId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/subdistricts/${districtId}`
			);
			setOptionSubDistrict(response.data.results);
			setOptionHospital([]);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};
	const fetchHospital = async (subdistrictId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/hospital/${subdistrictId}`
			);
			setOptionHospital(response.data.results);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const handleInputChange = (id, value) => {
		if (id === "id_card" && value.length > 13) return;
		setInputRegister((prevInput) => ({
			...prevInput,
			[id]: value,
		}));

		if (id === "month" || id === "year") {
			const year = id === "year" ? value : inputRegister.year;
			const month = id === "month" ? value : inputRegister.month;
			if (year && month) {
				updateDaysInMonth(year, month);
			}
		}
	};

	const updateDaysInMonth = (year, month) => {
		const monthIndex = parseInt(month) - 1;
		const days = new Date(year, monthIndex + 1, 0).getDate();
		const daysArray = Array.from({ length: days }, (_, i) => i + 1);
		setDaysInMonth(daysArray);
	};

	const handleSubmitEvent = async (e) => {
		e.preventDefault();
		const { day, month, year } = inputRegister;
		const birthday = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		const registerData = { ...inputRegister, birthday };

		if (
			inputRegister.user_type === "" ||
			inputRegister.first_name === "" ||
			inputRegister.last_name === "" ||
			inputRegister.id_card === "" ||
			day === "" ||
			month === "" ||
			year === "" ||
			inputRegister.email === "" ||
			inputRegister.password === "" ||
			inputRegister.confrim_password === "" ||
			inputRegister.hospitals_id === ""
		) {
			toast({
				title: "กรุณาระบุข้อมูลให้ครบก่อนสมัครสมาชิก",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else if (inputRegister.id_card?.toString().length !== 13) {
			toast({
				title: "เลขบัตรประจำตัวต้องเป็นตัวเลข 13 หลัก",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else if (inputRegister.password !== inputRegister.confrim_password) {
			toast({
				title: "รหัสผ่านและยืนยันรหัสผ่านไม่เหมือนกัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		} else {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/register`,
					{ registerData },
					{}
				);
				if (response.data.message === "success") {
					if (inputRegister.user_type === "doctor") {
						toast({
							title: "สมัครสมาชิกเรียบร้อย",
							description:
								"กำลังส่งข้อมูลให้ผู้ดูแลระบบ กรุณารออีเมลยืนยันการตรวจสอบ",
							position: "top-right",
							status: "success",
							duration: 100000,
							isClosable: true,
						});
					} else {
						toast({
							title: "สมัครสมาชิกเรียบร้อย กรุณาลงชื่อเพื่อเริ่มต้นใช้งานระบบ",
							position: "top-right",
							status: "success",
							isClosable: true,
						});
					}
					navigate("/login");
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						duration: 30000,
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	const getBuddhistYear = (year) => (parseInt(year) + 543).toString();

	return (
		<>
			<Box
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack spacing={8} maxW={"xl"} py={12} px={6}>
					<Stack align={"center"}>
						<Heading fontSize={"3xl"}>สมัครสมาชิก ✌️</Heading>
					</Stack>
					<Box
						rounded={"lg"}
						bg={useColorModeValue("white", "gray.700")}
						boxShadow={"lg"}
						p={8}
					>
						<Stack spacing={4} w={"full"} maxW={"md"}>
							<FormControl>
								<FormLabel>ประเภทสมาชิก</FormLabel>
								<Select
									placeholder="- เลือก -"
									value={inputRegister?.user_type}
									onChange={(e) =>
										handleInputChange("user_type", e.target.value)
									}
								>
									<option value="public">ผู้ใช้งานทั่วไป</option>
									<option value="doctor">หมอ</option>
								</Select>
							</FormControl>

							<Grid templateColumns="repeat(2, 1fr)" gap={3}>
								<FormControl id="first_name">
									<FormLabel>ชื่อ</FormLabel>
									<Input
										type="text"
										value={inputRegister?.first_name}
										onChange={(e) =>
											handleInputChange("first_name", e.target.value)
										}
									/>
								</FormControl>
								<FormControl id="last_name">
									<FormLabel>สกุล</FormLabel>
									<Input
										type="text"
										value={inputRegister?.last_name}
										onChange={(e) =>
											handleInputChange("last_name", e.target.value)
										}
									/>
								</FormControl>
							</Grid>
							<FormControl>
								<FormLabel>เลขบัตรประชาชน</FormLabel>
								<Input
									type="number"
									value={inputRegister?.id_card}
									onChange={(e) => handleInputChange("id_card", e.target.value)}
									maxLength={13}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>วันเกิด</FormLabel>
								<Grid templateColumns="repeat(3, 1fr)" gap={3}>
									<Select
										placeholder="- วัน -"
										value={inputRegister?.day}
										onChange={(e) =>
											handleInputChange("day", e.target.value)
										}
									>
										{daysInMonth.map((day) => (
											<option key={day} value={day}>
												{day}
											</option>
										))}
									</Select>
									<Select
										placeholder="- เดือน -"
										value={inputRegister?.month}
										onChange={(e) =>
											handleInputChange("month", e.target.value)
										}
									>
										{monthsInThai.map((month, index) => (
											<option key={index + 1} value={index + 1}>
												{month}
											</option>
										))}
									</Select>
									<Select
										placeholder="- ปี -"
										value={inputRegister?.year}
										onChange={(e) =>
											handleInputChange("year", e.target.value)
										}
									>
										{[...Array(100).keys()].map((i) => {
											const year = new Date().getFullYear() - i;
											return (
												<option key={year} value={year}>
													{getBuddhistYear(year)}
												</option>
											);
										})}
									</Select>
								</Grid>
							</FormControl>
							<FormControl id="email">
								<FormLabel>อีเมล</FormLabel>
								<Input
									type="email"
									value={inputRegister?.email}
									onChange={(e) => handleInputChange("email", e.target.value)}
								/>
							</FormControl>
							<Grid templateColumns="repeat(2, 1fr)" gap={3}>
								<FormControl id="password">
									<FormLabel>รหัสผ่าน</FormLabel>
									<Input
										type="password"
										value={inputRegister?.password}
										onChange={(e) =>
											handleInputChange("password", e.target.value)
										}
									/>
								</FormControl>
								<FormControl id="confrim_password">
									<FormLabel>ยืนยันรหัสผ่าน</FormLabel>
									<Input
										type="password"
										value={inputRegister?.confrim_password}
										onChange={(e) =>
											handleInputChange("confrim_password", e.target.value)
										}
									/>
								</FormControl>
							</Grid>

							<FormControl id="province">
								<FormLabel>โรงพยาบาลต้นสังกัด</FormLabel>
								<Select
									value={inputRegister?.province_id}
									placeholder="- เลือจังหวัด -"
									onChange={(e) => {
										const selectedProvinceId = e.target.value;
										setInputRegister((prevDoctor) => ({
											...prevDoctor,
											province_id: selectedProvinceId,
										}));
										fetchDistricts(selectedProvinceId);
									}}
								>
									{optionProvince &&
										optionProvince.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</Select>
								<Select
									mt={3}
									value={inputRegister?.districts_id}
									placeholder="- เลือกอำเภอ -"
									onChange={(e) => {
										const selectedDistrictId = e.target.value;
										setInputRegister((prevDoctor) => ({
											...prevDoctor,
											districts_id: selectedDistrictId,
										}));
										fetchSubDistricts(selectedDistrictId);
									}}
								>
									{optionDistrict &&
										optionDistrict.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</Select>
								<Select
									mt={3}
									value={inputRegister?.sub_districts_id}
									placeholder="- เลือกตำบล -"
									onChange={(e) => {
										const selectedSubDistrictId = e.target.value;
										setInputRegister((prevDoctor) => ({
											...prevDoctor,
											sub_districts_id: selectedSubDistrictId,
										}));
										fetchHospital(selectedSubDistrictId);
									}}
								>
									{optionSubDistrict &&
										optionSubDistrict.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</Select>
								<Select
									mt={3}
									value={inputRegister?.hospitals_id}
									placeholder="- เลือกโรงพยาบาล -"
									onChange={(e) =>
										setInputRegister((prevDoctor) => ({
											...prevDoctor,
											hospitals_id: e.target.value,
										}))
									}
								>
									{optionHospital &&
										optionHospital?.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</Select>
							</FormControl>
							<Stack spacing={6}>
								<Button
									type="submit"
									bg={useColorModeValue("green.600", "green.900")}
									_hover={{
										bg: "green.700",
									}}
									color={"white"}
									variant={"solid"}
									onClick={handleSubmitEvent}
								>
									สมัครสมาชิก
								</Button>
								<Center>
									<Link
										as={ReactRouterLink}
										to="/login"
										color="green.700"
										textDecoration="none"
									>
										ลงชื่อเข้าใช้
									</Link>
								</Center>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</>
	);
};

export default Register;
