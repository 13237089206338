import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	Select,
	Button,
	ModalFooter,
} from "@chakra-ui/react";
import moment from "moment";
import DateComponent from "../widget/DateComponent";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import { IMaskInput } from "react-imask";

const EditUndercareModal = ({
	isModalOpen,
	onCloseModal,
	userInfo,
	handleEditDone,
	handleAddDone,
}) => {
	const [userEditInfo, setUserEditInfo] = useState({});
	const [isEditDate, setIsEditingDate] = useState(false);
	const [provinces, setProvinces] = useState();
	const [districts, setDistricts] = useState();
	const [subDistricts, setSubDistricts] = useState();
	const [hospitals, setHospitals] = useState();
	const [inspectorInfo, setInspectorInfo] = useState();

	const [selectedSubdistrictID, setSelectedSubdistrictID] = useState();
	const [selectedDistrictID, setSelectedDistrictID] = useState();
	const [selectedProvinceID, setSelectedProvinceID] = useState();
	const [selectedHospitalID, setSelectedHospitalID] = useState();

	const auth = useAuth();
	const headers = {
		Authorization: auth.user.token,
	};

	//if edit
	useEffect(() => {
		async function loadInspectorInfo() {
			await fetchInspectorAddress();
			fetchProvinces();
		}

		if (isModalOpen) {
			// console.log(isModalOpen);
			if (userInfo) {
				fetchProvinces();
				setUserEditInfo(userInfo || {});
			} else {
				setUserEditInfo({});
				loadInspectorInfo();

				if (inspectorInfo && inspectorInfo.hospitals_id) {
					setSelectedHospitalID(inspectorInfo.hospitals_id);
					setUserEditInfo((prev) => ({
						...prev,
						hospitals_id: inspectorInfo.hospitals_id,
					}));
					console.log(`hospitals_id: ${inspectorInfo.hospitals_id}`);
				}
			}
		}
	}, [userInfo, isModalOpen]);

	useEffect(() => {
		if (userInfo && userInfo.provinces_id) {
			setSelectedProvinceID(userInfo.provinces_id);
		} else if (inspectorInfo && inspectorInfo.provinces_id) {
			setSelectedProvinceID(inspectorInfo.provinces_id);
		}
	}, [provinces]);

	useEffect(() => {
		if (selectedProvinceID) {
			fetchDistricts(selectedProvinceID);
		}
	}, [selectedProvinceID]);

	useEffect(() => {
		if (userInfo && userInfo.districts_id) {
			setSelectedDistrictID(userInfo.districts_id);
		} else if (inspectorInfo && inspectorInfo.districts_id) {
			setSelectedDistrictID(inspectorInfo.districts_id);
		}
	}, [districts]);

	useEffect(() => {
		if (selectedDistrictID) {
			fetchSubDistricts(selectedDistrictID);
		}
	}, [selectedDistrictID]);

	useEffect(() => {
		if (userInfo && userInfo.sub_districts_id) {
			setSelectedSubdistrictID(userInfo.sub_districts_id);
		} else if (inspectorInfo && inspectorInfo.sub_districts_id) {
			setSelectedSubdistrictID(inspectorInfo.sub_districts_id);
		}
	}, [subDistricts]);

	useEffect(() => {
		if (selectedSubdistrictID) {
			fetchHospitals(selectedSubdistrictID);
		}
	}, [selectedSubdistrictID]);

	useEffect(() => {
		if (userInfo && userInfo.hospitals_id) {
			setSelectedHospitalID(userInfo.hospitals_id);
		} else if (inspectorInfo && inspectorInfo.hospitals_id) {
			setSelectedHospitalID(inspectorInfo.hospitals_id);
		}
	}, [hospitals]);

	useEffect(() => {
		if (selectedHospitalID) {
			setUserEditInfo((prev) => ({
				...prev,
				hospitals_id: selectedHospitalID,
			}));
		}
	}, [selectedHospitalID]);

	const fetchInspectorAddress = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/inspector_info`,
				{},
				{ headers }
			);

			const data = await response.data.results;
			if (data.length > 0) {
				setInspectorInfo(data[0]);
			}
		} catch (error) {
			console.error("Error fetching provinces:", error);
		}
	};

	const fetchProvinces = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/province_list`,
				{},
				{ headers }
			);

			const data = await response.data.results;
			setProvinces(data);
		} catch (error) {
			console.error("Error fetching provinces:", error);
		}
	};

	const fetchDistricts = async (provinceId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/district_list`,
				{ provinceId },
				{ headers }
			);
			const data = await response.data.results;
			setDistricts(data);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const fetchSubDistricts = async (districtId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/sub_district_list`,
				{ districtId },
				{ headers }
			);
			const data = await response.data.results;
			setSubDistricts(data);
		} catch (error) {
			console.error("Error fetching sub-districts:", error);
		}
	};

	const fetchHospitals = async (subDistrictId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/hospital_list`,
				{ subDistrictId },
				{ headers }
			);
			const data = await response.data.results;
			setHospitals(data);
		} catch (error) {
			console.error("Error fetching hospitals:", error);
		}
	};

	const formatDate = (inputDate) => {
		if (inputDate && inputDate.length > 0) {
			const formattedDate = moment(inputDate).format("YYYY-MM-DD");
			return formattedDate;
		}
		return "";
	};

	const mask = [{ mask: "0-0000-00000-00-0" }];

	const imaskStyles = {
		// Specify your desired styles here
		border: "1px solid #cbd5e0",
		borderRadius: "md",
		padding: "0.375rem 0.75rem",
		fontSize: "1rem",
		lineHeight: "1.5",
	};

	return (
		<Modal
			isOpen={isModalOpen}
			onClose={() => {
				setInspectorInfo(undefined);
				setUserEditInfo(undefined);
				setSelectedHospitalID(undefined);
				setSelectedSubdistrictID(undefined);
				setSelectedDistrictID(undefined);
				setSelectedProvinceID(undefined);
				setHospitals(undefined);
				setSubDistricts(undefined);
				setDistricts(undefined);
				setProvinces(undefined);

				onCloseModal();
			}}
			size={"2xl"}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader color={"blue.500"}>
					{userInfo ? "แก้ไขข้อมูล" : "เพิ่มผู้ใต้ความดูแล"}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl mb="4">
						<FormLabel>ชื่อ</FormLabel>
						<Input
							type="text"
							value={userEditInfo?.first_name || ""}
							onChange={(e) =>
								setUserEditInfo((prev) => ({
									...prev,
									first_name: e.target.value,
								}))
							}
						/>
					</FormControl>
					{/* Add other form fields as needed */}
					<FormControl mb="4">
						<FormLabel>สกุล</FormLabel>
						<Input
							type="text"
							value={userEditInfo?.last_name || ""}
							onChange={(e) =>
								setUserEditInfo((prev) => ({
									...prev,
									last_name: e.target.value,
								}))
							}
						/>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>เลขบัตรบัตรประชาชน</FormLabel>
						<IMaskInput
							style={imaskStyles}
							value={
								userEditInfo && userEditInfo?.id_card
									? userEditInfo?.id_card
									: ""
							}
							mask={mask}
							unmask={true} // true|false|'typed'
							// DO NOT USE onChange TO HANDLE CHANGES!
							// USE onAccept INSTEAD
							onAccept={(value, mask) => {
								setUserEditInfo((prev) => ({ ...prev, id_card: value }));
							}}
							placeholder="13 หลัก"
						/>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>วันเกิด</FormLabel>
						<DateComponent
							ml={4}
							isEditingDate={setIsEditingDate}
							date={formatDate(userEditInfo?.birthday)}
							onDateChange={(newDate) =>
								setUserEditInfo((prev) => ({ ...prev, birthday: newDate }))
							}
						/>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>
							สังกัด/หน่วยงาน{" "}
							<small>
								(เช่น <u>โรงเรียนเนรมิตวิทยา</u>, <u>โรงงานเย็บผ้าชมาพร</u>,{" "}
								<u>ห้องสมุดประชาชนบ้านแทน</u> เป็นต้น)
							</small>
						</FormLabel>
						<Input
							type="text"
							value={userEditInfo?.organization || ""}
							onChange={(e) =>
								setUserEditInfo((prev) => ({
									...prev,
									organization: e.target.value,
								}))
							}
						/>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>
							สังกัด/หน่วยงานย่อย{" "}
							<small>
								(เช่น <u>ป.1/1</u>, <u>ป.1/2</u>, <u>ป.2/1</u>, <u>ป.2/2</u> ,{" "}
								<u>แผนกเย็บ</u>, <u>แผนกประกันคุณภาพ</u> เป็นต้น)
							</small>
						</FormLabel>
						<Input
							type="text"
							value={userEditInfo?.organization_sub || ""}
							onChange={(e) =>
								setUserEditInfo((prev) => ({
									...prev,
									organization_sub: e.target.value,
								}))
							}
						/>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>สังกัดจังหวัด</FormLabel>
						<Select
							placeholder="- เลือกจังหวัด -"
							onChange={(e) => {
								const id = e.target.value;
								setSelectedProvinceID(id);
							}}
							value={selectedProvinceID || ""}
						>
							{provinces &&
								provinces.map((province) => (
									<option key={province.id} value={province.id}>
										{province.name}
									</option>
								))}
						</Select>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>สังกัดตำบลแขวง</FormLabel>
						<Select
							placeholder="- เลือกอำเภอ -"
							onChange={(e) => {
								const id = e.target.value;
								setSelectedDistrictID(id);
							}}
							value={selectedDistrictID || ""}
						>
							{districts &&
								districts.map((district) => (
									<option key={district.id} value={district.id}>
										{district.name}
									</option>
								))}
						</Select>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>สังกัดอำเภอ/เขต</FormLabel>
						<Select
							placeholder="- เลือกตำบล -"
							onChange={(e) => {
								const id = e.target.value;
								setSelectedSubdistrictID(id);
							}}
							value={selectedSubdistrictID || ""}
						>
							{subDistricts &&
								subDistricts.map((subDistrict) => (
									<option key={subDistrict.id} value={subDistrict.id}>
										{subDistrict.name}
									</option>
								))}
						</Select>
					</FormControl>
					<FormControl mb="4">
						<FormLabel>สังกัดโรงพยาบาล</FormLabel>
						<Select
							placeholder="- เลือกโรงพยาบาล -"
							onChange={(e) => {
								const id = e.target.value;
								setSelectedHospitalID(id);
								setUserEditInfo((prev) => ({ ...prev, hospitals_id: id }));
							}}
							value={selectedHospitalID || ""}
						>
							{hospitals &&
								hospitals.map((hospital) => (
									<option key={hospital.id} value={hospital.id}>
										{hospital.name}
									</option>
								))}
						</Select>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						isDisabled={isEditDate}
						colorScheme="blue"
						onClick={() => {
							const info = userEditInfo;
							info.birthday = formatDate(info.birthday);
							console.log(userEditInfo);

							if (userInfo) {
								handleEditDone(info);
							} else {
								console.log(info);
								handleAddDone(info);
							}
						}}
					>
						บันทึก
					</Button>
					<Button onClick={() => onCloseModal()} ml={2}>
						ยกเลิก
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default EditUndercareModal;
