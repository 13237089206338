import React, { useState, useEffect } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import {
	Button,
	Select,
	Checkbox,
	Flex,
	Text,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	Image,
	useColorModeValue,
	useToast,
	Link,
	Spinner,
	Box,
} from "@chakra-ui/react";
import { useAuth } from "../hooks/AuthProvider";

const Login = ({ loginRole }) => {
	const navigate = useNavigate();
	const { loginAction } = useAuth();
	const [loginPageRole, setLoginPageRole] = useState(loginRole);
	const [assetPage, setAssetPage] = useState({});
	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState({
		email: "",
		password: "",
		role: "",
	});
	const toast = useToast();

	useEffect(() => {
		if (loginRole === "admin") {
			setAssetPage({
				mainImage: "images/teeth-admin.jpg",
				header: "ลงชื่อเข้าใช้งานระบบ (แอดมิน)",
			});
			setInput({ role: "admin" });
		} else {
			setAssetPage({
				mainImage: "images/teeth-clinic.jpg",
				header: "ลงชื่อเข้าใช้งานระบบ",
			});
		}
		// console.log(assetPage);
	}, [loginRole]);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setInput((prevInput) => ({
			...prevInput,
			[id]: value,
		}));
	};

	const handleSubmitEvent = async (e) => {
		e.preventDefault();
		if (input.email !== "" && input.password !== "" && input.role !== "") {
			// Dispatch action from hooks
			setLoading(true);
			try {
				await loginAction({
					email: input.email,
					password: input.password,
					role: input.role,
				});
			} catch (error) {
				// Handle login error if needed
				console.error("Login failed:", error.message);
				toast({
					title: "ไม่สามารถเข้าใช้งานได้",
					status: "error",
					position: "top-right",
					isClosable: true,
				});
			} finally {
				setLoading(false);
			}
		} else {
			toast({
				title: "กรุณาระบุอีเมล, รหัสผ่านและประเภทผู้ใช้งานก่อนลงชื่อเข้าใช้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
		}
	};

	const [switchLoginLink, setSwitchLoginLink] = useState("");
	useEffect(() => {
		const currentPath = window.location.pathname;
		if (currentPath === "/login") {
			setSwitchLoginLink(
				<Link href={"loginadmin"} color="green.700">
					ผู้ดูแลระบบ
				</Link>
			);
		} else {
			setSwitchLoginLink(
				<Box textAlign={"center"} w={"full"}>
					<Link href={"login"} color="green.700">
						ผู้ใช้งานทั่วไป/หมอ
					</Link>
				</Box>
			);
		}
	}, []);

	return (
		<>
			<Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
				<Flex flex={1}>
					<Image
						alt={"Login Image"}
						objectFit={"cover"}
						src={assetPage.mainImage}
					/>
				</Flex>
				<Flex p={8} flex={1} align={"center"} justify={"center"}>
					<Stack spacing={4} w={"full"} maxW={"md"}>
						<Heading fontSize={"2xl"}>{assetPage.header}</Heading>
						<FormControl id="email">
							<FormLabel>อีเมล</FormLabel>
							<Input
								type="email"
								value={input.email}
								onChange={handleInputChange}
							/>
						</FormControl>
						<FormControl id="password">
							<FormLabel>รหัสผ่าน</FormLabel>
							<Input
								type="password"
								value={input.password}
								onChange={handleInputChange}
							/>
						</FormControl>
						{input.role !== "admin" ? (
							<FormControl id="role">
								<FormLabel>ประเภทผู้ใช้งาน</FormLabel>
								<Select
									name="role"
									value={input.role}
									onChange={handleInputChange}
									placeholder="- เลือก -"
								>
									<option value="public">ผู้ใช้งานทั่วไป</option>
									<option value="doctor">หมอ</option>
								</Select>
							</FormControl>
						) : (
							""
						)}

						<Stack spacing={4}>
							<Button
								isDisabled={loading}
								bg={useColorModeValue("green.600", "green.900")}
								_hover={{
									bg: "green.700",
								}}
								color={"white"}
								variant={"solid"}
								onClick={handleSubmitEvent}
							>
								{loading ? (
									<Spinner size="sm" color="white" />
								) : (
									"ลงชื่อเข้าใช้"
								)}
							</Button>
							<Stack
								direction={{ base: "column", sm: "row" }}
								align={"start"}
								justify={"space-between"}
							>
								{input.role !== "admin" ? (
									<>
										<Link
											as={ReactRouterLink}
											to="/register"
											color="green.700"
											textDecoration="none"
										>
											สมัครสมาชิก
										</Link>
										<Link
											as={ReactRouterLink}
											to="/forgotpassword"
											color="green.700"
										>
											ลืมรหัสผ่าน
										</Link>
									</>
								) : (
									""
								)}

								{switchLoginLink}
							</Stack>
						</Stack>
					</Stack>
				</Flex>
			</Stack>
		</>
	);
};

export default Login;
