import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const PrivateRoute = () => {
	const auth = useAuth();
	if (!auth.user.token) return <Navigate to="/login" />;
	return (
		<>
			<Header />
			<Sidebar />
		</>
	);
};

export default PrivateRoute;
