import React, { useState, useEffect } from "react";
import {
	Flex,
	Text,
	Box,
	HStack,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Select,
	TableContainer,
	InputGroup,
	InputLeftElement,
	Icon,
	Spacer,
	useToast,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { API_BASE_URL } from "../../../config/apiConfig";
import { useAuth } from "../../../hooks/AuthProvider";

const HospitalListTable = ({ setCountHospital, countHospital }) => {
	const [hospitals, setHospitals] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(10); // Default page size
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [totalRecords, setTotalRecords] = useState();

	const [newHospital, setNewHospital] = useState(null);
	const [isNewHospitalModalOpen, setIsNewHospitalModalOpen] = useState(false);

	const [editHospital, setEditHospital] = useState(null);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const [deleteHospitalId, setDeleteHospitalId] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const [optionProvince, setOptionProvince] = useState();
	const [optionDistrict, setOptionDistrict] = useState();
	const [optionSubDistrict, setOptionSubDistrict] = useState();

	const auth = useAuth();
	const role = auth.user.role;
	const headers = {
		Authorization: auth.user.token,
	};
	const toast = useToast();

	const tableHeaders = ["ชื่อโรงพยาบาล", "ตำบล", "อำเภอ", "จังหวัด"];

	const fetchData = async (pageNumber = 1, searchTerm = "") => {
		try {
			const response1 = axios.post(
				`${API_BASE_URL}/api/site/hospital`,
				{ pageNumber, pageSize, searchTerm },
				{ headers }
			);
			const response2 = axios.get(`${API_BASE_URL}/api/site/provinces`);

			const [data1, data2] = await axios.all([response1, response2]);

			// console.log(
			// 	data1.data.hospitals,
			// 	data1.data.totalCount,
			// 	data2.data.results
			// );
			setHospitals(data1.data.hospitals);

			const totalCount = data1.data.totalCount;
			setPageCount(Math.ceil(totalCount / pageSize));
			setTotalRecords(totalCount);

			setOptionProvince(data2.data.results);
			if (searchTerm === "") {
				setCountHospital(totalCount);
			}
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};

	useEffect(() => {
		fetchData();
	}, [role]);

	const fetchDistricts = async (provinceId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/districts/${provinceId}`
			);
			setOptionDistrict(response.data.results);
			setOptionSubDistrict([]);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};
	const fetchSubDistricts = async (districtId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/subdistricts/${districtId}`
			);
			setOptionSubDistrict(response.data.results);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const handlePageChange = ({ selected }) => {
		fetchData(selected + 1, searchTerm);
	};
	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchTerm(value);

		// Clear previous search timeout
		clearTimeout(searchTimeout);

		// Set a new search timeout
		const newSearchTimeout = setTimeout(() => {
			fetchData(1, value);
		}, 1500); // Adjust delay as needed

		setSearchTimeout(newSearchTimeout);
	};
	const handlePageSizeChange = (e) => {
		setPageSize(parseInt(e.target.value, 10));
	};
	useEffect(() => {
		fetchData(1, searchTerm);
	}, [pageSize]);

	// add
	const handleNewHospitalModalOpen = () => {
		setNewHospital(null);
		setIsNewHospitalModalOpen(true);
	};
	const validateNewHospital = () => {
		if (!newHospital || !newHospital.sub_districts_id || !newHospital.name) {
			toast({
				title: "กรุณากรอกข้อมูลให้ถูกต้องก่อนยืนยัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return false;
		}
		return true;
	};
	const handleAddNewHospital = async () => {
		if (validateNewHospital()) {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/hospital_add`,
					{ newHospital },
					{ headers }
				);
				// console.log(response);
				if (response.data.message === "success") {
					await fetchData(1, searchTerm);
					setIsNewHospitalModalOpen(false);
					setNewHospital(null);
					setCountHospital(countHospital + 1);
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	// edit
	const handleEdit = (row) => {
		setEditHospital(row);
		setIsEditModalOpen(true);
	};
	const handleEditDone = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/hospital_edit`,
				{ editedHospital: editHospital },
				{ headers }
			);

			if (response.data.message === "success") {
				await fetchData(1, searchTerm);
				setIsEditModalOpen(false);
				setEditHospital(null);
			} else {
				toast({
					title: response.data.message,
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};

	// delete
	const handleDelete = (userId) => {
		setDeleteHospitalId(userId);
		setIsDeleteModalOpen(true);
	};
	const handleDeleteConfirm = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/hospital_delete`,
				{ deleteHospitalId },
				{ headers }
			);

			if (response.data.message === "success") {
				await fetchData(1, searchTerm);
				setIsDeleteModalOpen(false);
				setCountHospital(countHospital - 1);
			} else {
				toast({
					title: response.data.message,
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};
	const handleDeleteCancel = () => {
		setIsDeleteModalOpen(false);
	};

	return (
		<>
			<Flex mb={3}>
				<Box>
					<Button colorScheme="teal" onClick={handleNewHospitalModalOpen}>
						เพิ่มโรงพยาบาล
					</Button>
				</Box>
				<Spacer />
				<Box>
					<InputGroup>
						<InputLeftElement
							pointerEvents="none"
							children={<Icon as={SearchIcon} color="gray.300" />}
						/>
						<Input
							placeholder="ค้นหาชื่อโรงพยาบาล..."
							value={searchTerm}
							onChange={handleSearch}
						/>
					</InputGroup>
				</Box>
			</Flex>

			<Flex
				mb={2}
				pb={3}
				px={3}
				justify={"space-between"}
				bgColor="gray.50"
				border="1px solid"
				borderColor="gray.200"
			>
				<Select
					maxW={150}
					mt={3}
					size="sm"
					value={pageSize}
					onChange={handlePageSizeChange}
				>
					<option value={10}>แสดง 10 รายการ</option>
					<option value={20}>แสดง 20 รายการ</option>
					<option value={50}>แสดง 50 รายการ</option>
				</Select>
				<Text mt={4} color="gray">
					พบ {totalRecords} รายการ
				</Text>
				<ReactPaginate
					pageCount={pageCount}
					pageRangeDisplayed={5}
					marginPagesDisplayed={2}
					onPageChange={handlePageChange}
					previousLabel="ก่อนหน้า"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextLabel="ถัดไป"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					activeLinkClassName="active-link"
					renderOnZeroPageCount={null}
				/>
			</Flex>

			<TableContainer>
				<Table variant="simple">
					<Thead>
						<Tr>
							{tableHeaders.map((header, index) => (
								<Th key={index} style={{ fontSize: "16px", fontWeight: "900" }}>
									{header}
								</Th>
							))}
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{hospitals?.map((data) => (
							<Tr key={data.id}>
								<Td>{data.name}</Td>
								<Td>{data.subdistrict}</Td>
								<Td>{data.district}</Td>
								<Td>{data.province}</Td>
								<Td>
									<HStack spacing={2}>
										<button onClick={() => handleEdit(data)}>
											<EditIcon boxSize={5} color="blue.500" />
										</button>
										<button onClick={() => handleDelete(data.id)}>
											<DeleteIcon boxSize={5} color="red.500" />
										</button>
									</HStack>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>

			{/* <TableContainer>
				<DataTable
					customStyles={customStyles}
					columns={columns}
					data={filteredData}
					noDataComponent={"ไม่พบข้อมูล"}
					pagination
				/>
			</TableContainer> */}

			{/* Add New Hospital Modal */}
			<Modal
				isOpen={isNewHospitalModalOpen}
				onClose={() => (setIsNewHospitalModalOpen(false), setNewHospital(null))}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color="green">เพิ่มโรงพยาบาลใหม่</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl mb="4">
							<FormLabel>จังหวัด</FormLabel>
							<Select
								value={newHospital?.provinces_id}
								placeholder="- เลือก -"
								onChange={(e) => {
									setNewHospital((prevHospital) => ({
										...prevHospital,
										provinces_id: e.target.value,
									}));
									fetchDistricts(e.target.value);
								}}
							>
								{optionProvince &&
									optionProvince.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
						<FormControl mb="4">
							<FormLabel>อำเภอ</FormLabel>
							<Select
								value={newHospital?.districts_id}
								placeholder="- เลือก -"
								onChange={(e) => {
									setNewHospital((prevHospital) => ({
										...prevHospital,
										districts_id: e.target.value,
									}));
									fetchSubDistricts(e.target.value);
								}}
							>
								{optionDistrict &&
									optionDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
						<FormControl mb="4">
							<FormLabel>ตำบล</FormLabel>
							<Select
								value={newHospital?.sub_districts_id}
								placeholder="- เลือก -"
								onChange={(e) =>
									setNewHospital((prevHospital) => ({
										...prevHospital,
										sub_districts_id: e.target.value,
									}))
								}
							>
								{optionSubDistrict &&
									optionSubDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
						<FormControl mb="4">
							<FormLabel>ชื่อโรงพยาบาล</FormLabel>
							<Input
								type="text"
								value={newHospital?.name}
								onChange={(e) =>
									setNewHospital((prevHospital) => ({
										...prevHospital,
										name: e.target.value,
									}))
								}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="green" onClick={handleAddNewHospital}>
							ยืนยัน
						</Button>
						<Button onClick={() => setIsNewHospitalModalOpen(false)} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Edit Modal */}
			<Modal
				isOpen={isEditModalOpen}
				onClose={() => (setIsEditModalOpen(false), setEditHospital(null))}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"blue.500"}>แก้ไขข้อมูล</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl mb="4">
							<FormLabel>จังหวัด</FormLabel>
							<Select
								value={editHospital?.provinces_id}
								placeholder="- เลือก -"
								onChange={(e) => {
									setEditHospital((prevHospital) => ({
										...prevHospital,
										provinces_id: e.target.value,
									}));
									fetchDistricts(e.target.value);
								}}
							>
								{optionProvince &&
									optionProvince.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
						<FormControl mb="4">
							<FormLabel>อำเภอ</FormLabel>
							<Select
								value={editHospital?.districts_id}
								placeholder="- เลือก -"
								onChange={(e) => {
									setEditHospital((prevHospital) => ({
										...prevHospital,
										districts_id: e.target.value,
									}));
									fetchSubDistricts(e.target.value);
								}}
							>
								{optionDistrict &&
									optionDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
						<FormControl mb="4">
							<FormLabel>ตำบล</FormLabel>
							<Select
								value={editHospital?.sub_districts_id}
								placeholder="- เลือก -"
								onChange={(e) =>
									setEditHospital((prevHospital) => ({
										...prevHospital,
										sub_districts_id: e.target.value,
									}))
								}
							>
								{optionSubDistrict &&
									optionSubDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
						<FormControl mb="4">
							<FormLabel>ชื่อโรงพยาบาล</FormLabel>
							<Input
								type="text"
								value={editHospital?.name}
								onChange={(e) =>
									setEditHospital((prevHospital) => ({
										...prevHospital,
										name: e.target.value,
									}))
								}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" onClick={handleEditDone}>
							บันทึก
						</Button>
						<Button onClick={() => setIsEditModalOpen(false)} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"red.500"}>ลบข้อมูล</ModalHeader>
					<ModalCloseButton />
					<ModalBody>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" onClick={handleDeleteConfirm}>
							ลบ
						</Button>
						<Button onClick={handleDeleteCancel} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default HospitalListTable;
