import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	Grid,
	InputGroup,
	InputRightElement,
	Icon,
	Text,
	useToast,
	Box,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";

const ProfileModal = ({ isOpen, onClose }) => {
	const toast = useToast();
	const auth = useAuth();
	const headers = {
		Authorization: auth.user.token,
	};

	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);

	useEffect(() => {
		const fatchProfileData = async () => {
			// console.log("fatchProfileData");
			let response = await axios.post(
				`${API_BASE_URL}/api/site/get_profile_data`,
				{},
				{ headers }
			);
			// console.log(response.data);
			const userData = response.data.results[0];

			let formattedDate = "";
			console.log(response.data.results[0].birthday);
			if (response.data.results[0].birthday !== undefined) {
				const dateString = userData?.birthday;
				const dateObject = new Date(dateString);
				formattedDate = dateObject.toISOString().split("T")[0];
			}

			setFormData({
				firstName: userData?.first_name,
				lastName: userData?.last_name,
				birthday: formattedDate,
				phone: userData?.phone,
				address: userData?.address,
				organization: userData?.organization,
				organization_sub: userData?.organization_sub,
			});
		};

		if (isOpen) {
			fatchProfileData();
		}
	}, [isOpen]);

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		birthday: "",
		phone: "",
		address: "",
		organization: "",
		organization_sub: "",
		password: "",
		confirmPassword: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.firstName || !formData.lastName) {
			toast({
				title: "เพิ่มชื่อ สกุล",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return;
		}
		if (
			formData.password?.trim() !== "" ||
			formData.confirmPassword?.trim() !== ""
		) {
			if (formData.password !== formData.confirmPassword) {
				toast({
					title: "กำหนดรหัสผ่านและยืนยันรหัสผ่านใหม่ตรงกัน",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				return;
			}
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/update_profile`,
				{ formData },
				{ headers }
			);
			// console.log(response.data);
			if (response.data.message === "success") {
				toast({
					title: "บันทึกเรียบร้อย",
					position: "top-right",
					status: "success",
					isClosable: true,
				});
				onClose();
			}
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>โปรไฟล์ผู้ใช้งาน</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={handleSubmit}>
					<ModalBody pb={6}>
						<Grid templateColumns="repeat(2, 1fr)" gap={6}>
							<FormControl gridColumn="span 1">
								<FormLabel>
									ชื่อ
									<Text as="span" color="red">
										*
									</Text>
								</FormLabel>
								<Input
									name="firstName"
									value={formData.firstName}
									onChange={handleChange}
								/>
							</FormControl>

							<FormControl gridColumn="span 1">
								<FormLabel>
									สกุล
									<Text as="span" color="red">
										*
									</Text>
								</FormLabel>
								<Input
									name="lastName"
									value={formData.lastName}
									onChange={handleChange}
								/>
							</FormControl>

							{auth.user.role !== "admin" ? (
								<>
									<FormControl gridColumn="span 1">
										<FormLabel>วันเกิด</FormLabel>
										<Input
											type="date"
											name="birthday"
											value={formData.birthday}
											onChange={handleChange}
										/>
									</FormControl>
								</>
							) : (
								""
							)}

							{auth.user.role === "public" ? (
								<>
									<FormControl gridColumn="span 1">
										<FormLabel>เบอร์โทร</FormLabel>
										<Input
											name="phone"
											value={formData.phone}
											onChange={handleChange}
										/>
									</FormControl>

									<FormControl gridColumn="span 2">
										<FormLabel>ที่อยู่</FormLabel>
										<Input
											name="address"
											value={formData.address}
											onChange={handleChange}
										/>
									</FormControl>

									<FormControl gridColumn="span 2">
										<FormLabel>สังกัด/หน่วยงาน</FormLabel>
										<Input
											name="organization"
											value={formData.organization}
											onChange={handleChange}
										/>
									</FormControl>

									<FormControl gridColumn="span 2">
										<FormLabel>สังกัด/หน่วยงาน ย่อย</FormLabel>
										<Input
											name="organization_sub"
											value={formData.organization_sub}
											onChange={handleChange}
										/>
									</FormControl>
								</>
							) : (
								""
							)}
						</Grid>
						<Grid templateColumns="repeat(2, 1fr)" gap={6} pt={6}>
							<FormControl gridColumn="span 1">
								<FormLabel>เปลี่ยนรหัสผ่าน</FormLabel>
								<InputGroup gridColumn="span 1">
									<Input
										type={show ? "text" : "password"}
										name="password"
										value={formData.password}
										onChange={handleChange}
									/>
									<InputRightElement width="4.5rem">
										<Button h="1.75rem" size="sm" onClick={handleClick}>
											{show ? (
												<Icon as={ViewOffIcon} />
											) : (
												<Icon as={ViewIcon} />
											)}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>

							<FormControl gridColumn="span 1">
								<FormLabel>ยืนยันรหัสผ่าน</FormLabel>
								<InputGroup gridColumn="span 1">
									<Input
										type={show ? "text" : "password"}
										name="confirmPassword"
										value={formData.confirmPassword}
										onChange={handleChange}
									/>
									<InputRightElement width="4.5rem">
										<Button h="1.75rem" size="sm" onClick={handleClick}>
											{show ? (
												<Icon as={ViewOffIcon} />
											) : (
												<Icon as={ViewIcon} />
											)}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>
						</Grid>
					</ModalBody>

					<ModalFooter>
						<Button onClick={onClose} mr={3}>
							ยกเลิก
						</Button>
						<Button colorScheme="blue" type="submit">
							บันทึก
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};

export default ProfileModal;
