import React, { useState } from "react";
import { Button, Text, Select, useToast, Flex } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/th"; // Import Thai locale for moment
import { formatThaiDate } from "../../util/dateUtils";
import { CalendarIcon } from "@chakra-ui/icons";
// ... (previous imports)

const DateComponent = ({ date, onDateChange, isEditingDate, ...rest }) => {
    const [isEditing, setIsEditing] = useState(false);
    const selectedDate = date ? moment(date, "YYYY-MM-DD") : null;
    const [selectedDay, setSelectedDay] = useState(selectedDate ? selectedDate.date() : null);
    const [selectedMonth, setSelectedMonth] = useState(selectedDate ? selectedDate.month() + 1 : null); // Month is 0-indexed
    const [selectedYear, setSelectedYear] = useState(selectedDate ? selectedDate.year() : null);
  
    const daysInMonth = selectedDate ? moment(`${selectedYear}-${selectedMonth}`, "YYYY-MM").daysInMonth() : 31;
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  
    const currentYear = moment().year();
    const last100Years = Array.from({ length: 100 }, (_, i) => currentYear - i);
    const toast = useToast()
  
    const handleEditClick = () => {
      setIsEditing(true);
      if(isEditingDate){
        isEditingDate(true)
      }
    };
  
    const handleSaveClick = () => {
      if (!!selectedYear && !!selectedMonth && !!selectedDay) {
        const newDate = moment(`${selectedYear}-${selectedMonth.toString().padStart(2, "0")}-${selectedDay.toString().padStart(2, "0")}`).format("YYYY-MM-DD");
        onDateChange(newDate);
        setIsEditing(false);
        if(isEditingDate){
            isEditingDate(false)
        }
      }
      else{
        toast({
            title: "กรุณาเลือกวันเกิด",
            position: "bottom",
            status: "warning",
            isClosable: true,
        });
      }
    };
  
    const handleCancelClick = () => {
      setIsEditing(false);
      if(isEditingDate){
        isEditingDate(false)
      }

      setSelectedDay(selectedDate ? selectedDate.date() : null);
      setSelectedMonth(selectedDate ? selectedDate.month() + 1 : null);
      setSelectedYear(selectedDate ? selectedDate.year() : null);
    };
  
    return (
      <Flex {...rest}>
        {selectedDate && !isEditing && <Text>{formatThaiDate(selectedDate)}</Text>}
        {!selectedDate && !isEditing && <Text>ไม่ระบุ</Text>}
        {isEditing ? (
          <>
            <Select maxW={"60px"} size={"sm"} value={selectedDay || ""} onChange={(e) => setSelectedDay(Number(e.target.value))} mr="2" placeholder="วัน">
              {daysArray.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </Select>
            <Select maxW={"120px"} size={"sm"} value={selectedMonth || ""} onChange={(e) => setSelectedMonth(Number(e.target.value))} mr="2" placeholder="เดือน">
              {moment.months().map((month, index) => (
                <option key={index + 1} value={index + 1}>
                  {month}
                </option>
              ))}
            </Select>
            <Select maxW={"80px"} size={"sm"} value={selectedYear || ""} onChange={(e) => setSelectedYear(Number(e.target.value))} mr="2" placeholder="ปี">
              {last100Years.map((year) => (
                <option key={year} value={year}>
                  {year + 543}
                </option>
              ))}
            </Select>
            <Button size={"sm"} colorScheme="blue" onClick={handleSaveClick} ml="2">
              เลือก
            </Button>
            <Button size={"sm"} onClick={handleCancelClick} ml="2">
              ยกเลิก
            </Button>
          </>
        ) : (
          <Button size={"sm"} onClick={handleEditClick} ml="2">
            <CalendarIcon/>
          </Button>
        )}
      </Flex>
    );
  };
  
  export default DateComponent;
  