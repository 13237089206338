import React, { useState } from "react";
import {
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Tabs,
	Box,
	Card,
	Flex,
	Text,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import DoctorListTable from "./usermanagement/DoctorListTable";
import HospitalListTable from "./usermanagement/HospitalListTable";

function Usermanagement() {
	const [countDoctor, setCountDoctor] = useState();
	const [countHospital, setCountHospital] = useState();

	return (
		<>
			<Box p={4}>
				<Card p={5} mt={3}>
					<Tabs align="end" variant="unstyled">
						<TabList p={2}>
							<Tab
								_selected={{ color: "white", bg: "pink.700" }}
								borderRadius={5}
								fontSize={"1em"}
								marginRight={3}
							>
								<Flex flexDirection={{ base: "column", md: "row" }}>
									<Icon icon="fontisto:doctor" width={45} />
									<Box ml={{ base: "0", md: "3" }}>
										<Text fontWeight="bold">รายชื่อหมอ</Text>
										<Text fontSize="sm">จำนวน {countDoctor} คน</Text>
									</Box>
								</Flex>
							</Tab>
							<Tab
								_selected={{ color: "white", bg: "cyan.700" }}
								borderRadius={5}
								fontSize={"1.2em"}
							>
								<Flex flexDirection={{ base: "column", md: "row" }}>
									<Icon icon="ri:hospital-fill" width={53} />
									<Box ml={{ base: "0", md: "3" }}>
										<Text fontWeight="bold">โรงพยาบาล</Text>
										<Text fontSize="sm">จำนวน {countHospital} แห่ง</Text>
									</Box>
								</Flex>
							</Tab>
						</TabList>

						<TabPanels mt={5}>
							<TabPanel px={0}>
								<DoctorListTable
									setCountDoctor={setCountDoctor}
									countDoctor={countDoctor}
								/>
							</TabPanel>
							<TabPanel px={0}>
								<HospitalListTable
									setCountHospital={setCountHospital}
									countHospital={countHospital}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Card>
			</Box>
		</>
	);
}

export default Usermanagement;
