import React, { useState, useEffect } from "react";
import {
	Flex,
	Image,
	Box,
	Container,
	Divider,
	Stack,
	Text,
	VStack,
	Collapse,
	useColorModeValue,
	useDisclosure,
	useSteps,
	Popover,
	PopoverTrigger,
	IconButton,
	PopoverContent,
	PopoverArrow,
	PopoverBody,
	List,
	ListItem,
	Button,
	useBreakpointValue,
} from "@chakra-ui/react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";

import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UploadComponent from "../components/UploadComponent";
import "../css/shimmer.css";

import ChatModal from "../components/modal/ChatModal";
import { BsCheck2Circle, BsQuestionCircle } from "react-icons/bs";
import { ArrowRightIcon, ChatIcon } from "@chakra-ui/icons";
import { MdList, MdOutlineZoomInMap } from "react-icons/md";
import DiagChatModal from "../components/modal/DiagChatModal";

const initialTexts = [
	"หากคุณมีคำถามเกี่ยวกับสุขภาพช่องปาก",
	"สุขใจพร้อมให้คำแนะนำคะ",
	"ให้สุขใจช่วยให้คำปรึกษาคุณนะคะ",
];

const healthAssessmentTexts = [
	"การประเมินสุขภาพช่องปากไม่ยากอย่างที่คิด",
	"ให้สุขใจช่วยประเมินสุขภาพช่องปากคุณนะคะ"
];

function getUniqueMachineId() {
	let machineId = localStorage.getItem("machineId");
	if (!machineId) {
		machineId = uuidv4();
		localStorage.setItem("machineId", machineId);
	}
	return machineId;
}

const ImageWithShimmer = ({ imageSrc, size = "auto" }) => {
	return (
		<Flex align="center" className="shimmer">
			<Image
				className="shimmer" // Hide image until loaded
				boxSize={size}
				src={imageSrc} // Dynamic image URL
				alt="With shimmer"
			/>
		</Flex>
	);
};

const Home = () => {
	const [decayTeethNum, setDecayTeethNum] = useState(0);
	const { activeStep, setActiveStep } = useSteps({ index: 0, count: 3 });
	const [isChatOpen, setChatOpen] = useState(false);
	const [isDiagChatOpen, setDiagChatOpen] = useState(false);

	const openChat = () => setChatOpen(true);
	const closeChat = () => setChatOpen(false);
	const openDiagChat = () => setDiagChatOpen(true);
	const closeDiagChat = () => setDiagChatOpen(false);

	const id = getUniqueMachineId();
	const diag_id = "_"+getUniqueMachineId();
	const { isOpen, onToggle } = useDisclosure();

	const [shownTexts, setShownTexts] = useState([]);
	const [hoveredButton, setHoveredButton] = useState("box1");
	const [imageSrc, setImageSrc] = useState("images/sukjai with elder.png");
	const [currentTexts, setCurrentTexts] = useState(initialTexts);
	const isDesktop = useBreakpointValue({ base: false, md: true });

	// useEffect to reanimate text whenever currentTexts changes
	useEffect(() => {
		setShownTexts([]); // Reset the text
		const interval = setInterval(() => {
			setShownTexts((prevTexts) => {
				const nextTextIndex = prevTexts.length;
				if (nextTextIndex < currentTexts.length) {
					return [...prevTexts, currentTexts[nextTextIndex]];
				} else {
					clearInterval(interval);
					return prevTexts;
				}
			});
		}, 500);

		return () => clearInterval(interval); // Cleanup on unmount or when currentTexts changes
	}, [currentTexts]);

	const handleHealthAssessmentClick = () => {
		setHoveredButton("box2");
		setImageSrc("images/sukjai diagnose 3.png"); // Change the image
		setCurrentTexts(healthAssessmentTexts); // Change the transition texts
	};

	const handleConsultationClick = () => {
		setHoveredButton("box1");
		setImageSrc("images/sukjai with elder.png"); // Change the image back
		setCurrentTexts(initialTexts); // Change the transition texts back
	};

	function FloatingMenu({ openChat }) {
		const { isOpen, onOpen, onClose } = useDisclosure();

		return (
			<Box position="fixed" bottom="30px" right="30px" zIndex="1000">
				<Popover
					isOpen={isOpen}
					onClose={onClose}
					closeOnBlur={true}  // Close on clicking outside
					placement="top-end"
				>
					<PopoverTrigger>
						<IconButton
							icon={<ChatIcon />}
							colorScheme="teal"
							aria-label="Menu"
							size={"lg"}
							borderRadius="full"
							boxShadow="lg"
							onClick={onOpen}  // Open the popover on click
						/>
					</PopoverTrigger>
					<PopoverContent width="250px">
						<PopoverArrow />
						<PopoverBody>
							<List spacing={3}>
								<ListItem>
									<Button width="100%" variant="ghost" onClick={() => {
										onClose()
										openChat()
									}}>
										ปรึกษาปัญหาสุขภาพช่องปาก
									</Button>
								</ListItem>
								<ListItem>
									<Button width="100%" variant="ghost" onClick={() => {
										onClose()
										openDiagChat()
									}}>
										ประเมินสุขภาพช่องปาก
									</Button>
								</ListItem>
							</List>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Box>
		);
	}

	return (
		<>
			<Header />
			<Navbar />
			<VStack bgColor={useColorModeValue("gray.200", "blue.900")} pb={10}>
				<VStack
					maxWidth="7xl"
					minW={{ base: "lg", md: "7xl" }}
					bgColor={useColorModeValue("gray.100", "blue.900")}
				>
					<Flex
						maxWidth="7xl"
						align="center"
						justifyContent={{ base: "center", md: "space-between" }}
						w="full"
						h="fit-content"
						bgGradient="linear(to-t, blue.400, blue.600)"
					>
						<Image
							display={{ base: "none", sm: "none", md: "flex" }}
							boxSize="xs"
							objectFit="cover"
							src="/images/decay.svg"
							alt="Teeth"
						/>

						<Container w="full" centerContent px={{ base: 2, md: 10 }}>
							<VStack w="full">
								<UploadComponent
									setActiveStep={setActiveStep}
									setDecayTeethNum={setDecayTeethNum}
								/>
							</VStack>
						</Container>
					</Flex>

					{isDesktop && <Flex p={4} justify={"space-between"} w="full" position="relative">
						<Flex direction={"column"} align={"start"} justify={"start"} width={"20%"}>
							<Button
								leftIcon={<BsQuestionCircle color={hoveredButton === "box1" ? "yellow" : "black"} />}
								bgColor={hoveredButton === "box1" ? "teal.500" : "none"}
								color={hoveredButton === "box1" ? "white" : "black"}
								w={"100%"}
								minH={"50px"}
								borderRightRadius="0"
								borderBottom="1px solid"
								borderLeft="2px solid"
								borderTop="2px solid"
								borderColor="gray.300"
								cursor={"pointer"}
								right={hoveredButton === "box1" ? "-2px" : "0px"}
								zIndex={1}
								onClick={handleConsultationClick} // Click event for the first button
								_hover={{
									backgroundColor: "none", // Keep the original background color
								}}
								justifyContent="flex-start"  // Align the content to the start (left)
								textAlign="left"
							>
								ปรึกษาปัญหาสุขภาพช่องปาก
							</Button>
							<Button
								leftIcon={<BsCheck2Circle color={hoveredButton === "box2" ? "yellow" : "black"} />}
								bgColor={hoveredButton === "box2" ? "teal.500" : "none"}
								color={hoveredButton === "box2" ? "white" : "black"}
								w={"100%"}
								minH={"50px"}
								borderRightRadius="0"
								borderBottom="2px solid"
								borderLeft="2px solid"
								borderTop="1px solid"
								borderColor="gray.300"
								cursor={"pointer"}
								right={hoveredButton === "box2" ? "-2px" : "0px"}
								zIndex={1}
								onClick={handleHealthAssessmentClick} // Click event for the second button
								_hover={{
									backgroundColor: "none", // Keep the original background color
								}}
								justifyContent="flex-start"  // Align the content to the start (left)
								textAlign="left"
							>
								ประเมินสุขภาพช่องปาก
							</Button>
						</Flex>

						{/* Image with shimmer */}
						<Flex
							bgGradient="linear(to-r, teal.500, white)"
							width={"80%"}
							border="2px solid"
							borderColor="gray.300"
							position="relative"
							direction={"column"}
						>
							<Flex justify={"space-between"} align="end" w="full" position="relative">
								<Flex width={"100%"} justify={"end"}>
									<ImageWithShimmer imageSrc={imageSrc} />
								</Flex>

								<Box mr={4} mb={4} className="speech-bubble" w="100%">
									<VStack align="start" spacing={4} position="relative" height={(currentTexts.length * 40) + "px"}>
										<TransitionGroup>
											{shownTexts.map((text, index) => (
												<CSSTransition key={index} timeout={500} classNames="fade">
													<Text
														fontSize="xl"
														color="green.700"
														style={{
															position: "absolute",
															top: `${index * 40}px`, // Set each text at a calculated position
															transition: "transform 500ms ease-in-out",
														}}
													>
														{text}
													</Text>
												</CSSTransition>
											))}
										</TransitionGroup>
									</VStack>
								</Box>
							</Flex>

							<Flex bgColor="teal.500" w={"100%"} justify={"center"} p={2}>
								{hoveredButton == "box1" && <Button onClick={() => openChat()} variant={"outline"} rightIcon={<ArrowRightIcon color={"yellow"} />}>
									<Text color="gray.800">เริ่มปรึกษาปัญหาสุขภาพช่องปากกับสุขใจ</Text>
								</Button>}
								{hoveredButton == "box2" && <Button onClick={() => openDiagChat()} variant={"outline"} rightIcon={<ArrowRightIcon color={"yellow"} />}>
									<Text color="gray.800">เริ่มประเมินปัญหาสุขภาพช่องปากกับสุขใจ</Text>
								</Button>}
							</Flex>

							<Flex
								position="absolute"
								top="-5"
								right="5"
								bg="yellow.200"
								border="2px solid"
								borderColor="gray.300"
								p={2}
								borderRadius="md"
								justify={"center"}
							>
								<ImageWithShimmer size="20px" imageSrc={"images/teeth_love.png"} />
								<Text ml={2} color="black">บริการของเรา</Text>
							</Flex>

						</Flex>
					</Flex>
					}

					{!isDesktop && <Flex
						bgGradient="linear(to-r, teal.500, white)"
						width={"80%"}
						border="2px solid"
						borderColor="gray.300"
						position="relative"
						direction={"column"}
						>
						<Flex justify={"space-between"} align="end" w="full" position="relative">
							<Flex width={"100%"} justify={"end"}>
								<ImageWithShimmer imageSrc={imageSrc} />
							</Flex>
						</Flex>
						<Flex bgColor="teal.500" w={"100%"} justify={"center"} p={2}>
							<Button onClick={() => openChat()} variant={"outline"} rightIcon={<ArrowRightIcon color={"yellow"} />}>
								<Text color="gray.800">ปรึกษาปัญหาสุขภาพช่องปากกับสุขใจ</Text>
							</Button>
						</Flex>
					</Flex>}

					{!isDesktop && <Flex
						bgGradient="linear(to-r, teal.500, white)"
						width={"80%"}
						border="2px solid"
						borderColor="gray.300"
						position="relative"
						direction={"column"}
						>
						<Flex justify={"space-between"} align="end" w="full" position="relative">
							<Flex width={"100%"} justify={"end"}>
								<ImageWithShimmer imageSrc={"images/sukjai diagnose 3.png"} />
							</Flex>
						</Flex>
						<Flex bgColor="teal.500" w={"100%"} justify={"center"} p={2}>
							<Button onClick={() => openDiagChat()} variant={"outline"} rightIcon={<ArrowRightIcon color={"yellow"} />}>
								<Text color="gray.800">ประเมินปัญหาสุขภาพช่องปากกับสุขใจ</Text>
							</Button>
						</Flex>
					</Flex>}


					<FloatingMenu openChat={openChat} />

				</VStack>
			</VStack>
			<Footer />

			<ChatModal
				id={id}
				isOpenProp={isChatOpen}
				onCloseProp={closeChat}
				initialMessages={[]}
				title="ปรึกษาปัญหาสุขภาพช่องปาก"
			/>

			<DiagChatModal
				id={diag_id}
				isOpenProp={isDiagChatOpen}
				onCloseProp={closeDiagChat}
				initialMessages={[]}
				title="ประเมินสุขภาพช่องปาก"
			/>
		</>
	);
};

export default Home;
