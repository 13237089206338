import {
	Box,
	Flex,
	Button,
	useColorModeValue,
	Stack,
	useColorMode,
	HStack,
	Image,
	Text,
	Menu,
	MenuButton,
	Avatar,
	MenuList,
	Center,
	MenuDivider,
	MenuItem,
	useDisclosure,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import { FaSignInAlt, FaUserPlus } from "react-icons/fa";
import ProfileModal from "./modal/ProfileModal";

const Header = () => {
	const { colorMode, toggleColorMode } = useColorMode();
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const auth = useAuth();
	const handleLogout = () => {
		auth.logOut();
		// navigate("/login");
	};
	const handleDashboard = () => {
		navigate("/dashboard");
	};

	return (
		<>
			<Box
				bg={useColorModeValue("white", "gray.900")}
				px={4}
				position="fixed"
				width="100%"
				zIndex={1000}
			>
				<Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
					<Box>
						<HStack>
							<Image
								boxSize={{ base: "60px", md: "100px" }}
								pt={{ base: "0", md: "6" }}
								src="/images/icons/moph.svg"
								alt="MOPH logo"
							/>
							<Text
								display={{ base: "none", md: "flex" }}
								fontSize={22}
								dropShadow="sm"
								fontWeight="semibold"
								bgClip="text"
								bgGradient={
									colorMode === "light"
										? "linear(to-t, #1D684A, #1D684A, #86AA30, #86AA30)"
										: "linear(to-t, gray.400, white)"
								}
							>
								ระบบตรวจจับฟันผุจากภาพถ่าย
							</Text>
						</HStack>
					</Box>

					<Flex alignItems={"center"}>
						<Stack direction={"row"} spacing={2}>
							<Button onClick={toggleColorMode}>
								{colorMode === "light" ? <MoonIcon /> : <SunIcon />}
							</Button>
							{auth.user.length !== 0 ? (
								<Menu>
									<MenuButton
										as={Button}
										rounded={"full"}
										variant={"link"}
										cursor={"pointer"}
										minW={0}
									>
										<Avatar
											size={"sm"}
											src={
												"https://www.shutterstock.com/image-vector/young-smiling-man-avatar-brown-600nw-2261401207.jpg"
											}
										/>
									</MenuButton>
									<MenuList alignItems={"center"}>
										<br />
										<Center>
											<Avatar
												size={"2xl"}
												src={
													"https://www.shutterstock.com/image-vector/young-smiling-man-avatar-brown-600nw-2261401207.jpg"
												}
											/>
										</Center>
										<br />
										<Center>
											<p>
												{auth.user?.first_name} {auth.user?.last_name}
											</p>
										</Center>
										<br />
										<MenuDivider />
										<MenuItem onClick={handleDashboard}>หน้าแรก</MenuItem>
										<MenuItem onClick={onOpen}>โปรไฟล์</MenuItem>
										<MenuItem onClick={handleLogout}>ลงชื่อออก</MenuItem>
									</MenuList>
								</Menu>
							) : (
								<Stack
									flex={{ base: 1, md: 0 }}
									justify="flex-end"
									direction="row"
									spacing={0}
								>
									<Button
										as={ReactRouterLink}
										to="/register"
										fontSize="sm"
										fontWeight={600}
										color="blue.600"
										bg={colorMode === "light" ? "white" : "blue"}
										_hover={{
											bg: colorMode === "light" ? "blue.50" : "blue.900",
											cursor: "pointer",
										}}
										leftIcon={<FaUserPlus />}
									>
										สมัครสมาชิก
									</Button>
									<Button
										as={ReactRouterLink}
										to="/login"
										fontSize="sm"
										fontWeight={600}
										color="blue.600"
										bg={colorMode === "light" ? "white" : "blue"}
										_hover={{
											bg: colorMode === "light" ? "blue.50" : "blue.900",
											cursor: "pointer",
										}}
										leftIcon={<FaSignInAlt />}
									>
										ลงชื่อเข้าใช้
									</Button>
								</Stack>
							)}
						</Stack>
					</Flex>
				</Flex>
			</Box>

			<ProfileModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export default Header;
