import { Box, SimpleGrid } from "@chakra-ui/react";
import UserChart from "./charts/user_chart";
import RegistrationChart from "./charts/RegistrationChart";
import DiagnosisChart from "./charts/DiagnosisChart";

function Dashboard() {
	return (
		<Box p={4}>
			<SimpleGrid columns={{ base: 1, md: 2 }} spacingX="40px" spacingY="20px">
				<RegistrationChart />
				<DiagnosisChart />
				{/* <UserChart /> */}
			</SimpleGrid>
		</Box>
	);
}

export default Dashboard;
