import { useContext, createContext, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const toast = useToast();
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("user")) || []
	);
	const navigate = useNavigate();
	const loginAction = async (data) => {
		try {
			const res = await axios.post(`${API_BASE_URL}/api/site/login`, data);
			// console.log(res.data);
			if (res.data.message === "success") {
				setUser(res.data.user);
				localStorage.setItem("user", JSON.stringify(res.data.user));
				navigate("/dashboard");
				return;
			} else {
				toast({
					title: res.data.message,
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
			}
		} catch (err) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error(err);
		}
	};

	const logOut = () => {
		setUser([]);
		localStorage.removeItem("user");
		if (user.role === "admin") {
			navigate("/loginadmin");
		} else {
			navigate("/login");
		}
	};

	return (
		<AuthContext.Provider value={{ user, loginAction, logOut }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
