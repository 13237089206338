import React, { useState } from "react";
import {
	Flex,
	Card,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Box,
	Button,
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	TableContainer,
	FormControl,
	FormLabel,
	Grid,
	Select,
	Spinner,
	Heading,
	Spacer,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import * as XLSX from "xlsx/dist/xlsx.full.min.js";
import { useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import {
	calculateAge,
	convertToThaiMonth,
	formatThaiDate,
} from "../../util/dateUtils";

function Report() {
	// Define CSS classes for consistent styling
	const styles = {
		headerCell: {
			fontSize: "100%",
			fontWeight: "bold",
			color: "white",
		},
		footerCell: {
			fontSize: "100%",
			fontWeight: "bold",
			color: "gray",
		},
	};

	const [activeTab, setActiveTab] = useState(0);
	const [selectedSubdistrictID, setSelectedSubdistrictID] = useState();
	const [selectedHospitalID, setSelectedHospitalID] = useState();
	const [tabData, setTabData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [provinces, setProvinces] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [subDistricts, setSubDistricts] = useState([]);
	const [hospitals, setHospitals] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState({
		province: "",
		district: "",
		subdistrict: "",
		hospital: "",
	});

	const auth = useAuth();
	const role = auth.user.role;
	const headers = {
		Authorization: auth.user.token,
	};

	useEffect(() => {
		if (provinces.length <= 0) {
			fetchProvinces();
		}
	}, []);

	const [doctorHospitalName, setDoctorHospitalName] = useState();
	useEffect(() => {
		if (role === "doctor") {
			setDoctorHospitalName(auth.user.hospital_name);
		}
	}, [auth, role]);

	useEffect(() => {
		const fetchHospitalReport = async () => {
			if (activeTab == 2 && selectedHospitalID) {
				setLoading(true);
				let response = await axios.post(
					`${API_BASE_URL}/api/site/diagnosis_by_hospital_id_report`,
					{ hospitalID: selectedHospitalID },
					{ headers }
				);
				if (response) {
					setTabData(response.data.results);
				}
				setLoading(false);
			}
		};
		fetchHospitalReport();
	}, [selectedHospitalID]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/diagnosis_by_hospital_id_report`,
					{ hospitalID: auth.user.hospitals_id },
					{ headers }
				);
				if (response) {
					setTabData(response.data.results);
				}
			} catch (error) {
				// Handle error if needed
				console.error("Error fetching data:", error);
			}
			setLoading(false);
		};

		if (activeTab === 2 && role === "doctor") {
			fetchData();
		}
	}, [activeTab]);

	useEffect(() => {
		const fetchHospitalReport = async () => {
			if (activeTab === 3 && selectedSubdistrictID !== undefined) {
				setLoading(true);
				let response = await axios.post(
					`${API_BASE_URL}/api/site/diagnosis_by_subdistrict_id_report`,
					{ subdistrictID: selectedSubdistrictID },
					{ headers }
				);
				if (response) {
					setTabData(response.data.results);
				}
				setLoading(false);
			}
		};
		fetchHospitalReport();
	}, [activeTab, selectedSubdistrictID]);

	useEffect(() => {
		const fetchDataForTab = async () => {
			try {
				let response;

				if (activeTab === 0) {
					setLoading(true);
					response = await axios.post(
						`${API_BASE_URL}/api/site/user_age_report`,
						{},
						{ headers }
					);
					setLoading(false);
				} else if (activeTab === 1) {
					setLoading(true);
					const currentYear = new Date().getFullYear();
					response = await axios.post(
						`${API_BASE_URL}/api/site/diagnosis_monthly_report`,
						{ year: currentYear },
						{ headers }
					);
					setLoading(false);
				} else if (activeTab === 2) {
					setTabData([]);

					if (auth.user.role === "admin") {
						await fetchProvinces();
					}
				} else if (activeTab === 3) {
					setTabData([]);
					await fetchProvinces();
				}

				if (activeTab == 0 || activeTab == 1) {
					if (response) {
						const data = await response.data.results;
						setTabData(data);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchDataForTab();

		return () => {
			console.log(`Cleanup for tab ${activeTab}`);
		};
	}, [activeTab]);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	const fetchProvinces = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/province_list`,
				{},
				{ headers }
			);

			const data = await response.data.results;
			setProvinces(data);
			setDistricts([]);
			setSubDistricts([]);
			setHospitals([]);

			setSelectedOptions((prevOptions) => ({
				...prevOptions,
				province: "",
				selected_province: "",
				district: "",
				selected_district: "",
				subdistrict: "",
				selected_subdistrict: "",
				hospital: "",
				selected_hospital: "",
			}));
		} catch (error) {
			console.error("Error fetching provinces:", error);
		}
	};

	const fetchDistricts = async (provinceId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/district_list`,
				{ provinceId },
				{ headers }
			);
			const data = await response.data.results;
			setDistricts(data);
			setHospitals([]);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const fetchSubDistricts = async (districtId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/sub_district_list`,
				{ districtId },
				{ headers }
			);
			const data = await response.data.results;
			setSubDistricts(data);
		} catch (error) {
			console.error("Error fetching sub-districts:", error);
		}
	};

	const fetchHospitals = async (subDistrictId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/hospital_list`,
				{ subDistrictId },
				{ headers }
			);
			const data = await response.data.results;
			setHospitals(data);
		} catch (error) {
			console.error("Error fetching hospitals:", error);
		}
	};

	const handleSelectChange = (field, value, key) => {
		setSelectedOptions((prevOptions) => ({
			...prevOptions,
			[field]: value,
		}));

		if (field === "province") {
			fetchDistricts(value);
			setSubDistricts([]);
			setHospitals([]);

			setSelectedOptions((prevOptions) => ({
				...prevOptions,
				selected_province: key,
			}));
		} else if (field === "district") {
			fetchSubDistricts(value);
			setHospitals([]);

			setSelectedOptions((prevOptions) => ({
				...prevOptions,
				selected_district: key,
			}));
		} else if (field === "subdistrict") {
			fetchHospitals(value);
			setSelectedOptions((prevOptions) => ({
				...prevOptions,
				selected_subdistrict: key,
			}));
			setSelectedSubdistrictID(value);
		} else if (field === "hospital") {
			setSelectedOptions((prevOptions) => ({
				...prevOptions,
				selected_hospital: key,
			}));

			setSelectedHospitalID(value);
		}
	};

	const exportToExcel = () => {
		let excelData = [];

		if (activeTab === 0 || activeTab === 1) {
			// Process data for tab 0 or tab 1
			excelData = tabData.map((row) => [
				activeTab === 0 ? row.age_range : convertToThaiMonth(row.Month),
				row.Number,
				row.suspicious_point_sum,
				activeTab === 0 ? row.user_age_avg : row.avg_age,
			]);
		} else if (activeTab === 2) {
			// Process data for tab 2
			excelData = tabData.map((row) => [
				`${row.first_name} ${row.last_name}`,
				row.suspicious_point,
				formatThaiDate(row.created_datetime),
				calculateAge(row.user_birthday),
			]);
		} else if (activeTab === 3) {
			// Process data for tab 3
			excelData = tabData.map((row) => [
				row.hospital_name,
				row.number,
				row.suspicious_count,
				row.metal_count,
			]);
		}

		const ws = XLSX.utils.aoa_to_sheet([
			getExcelHeader(activeTab),
			...excelData,
		]);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		//const wbBlob = XLSX.write(wb, { bookType: "xlsx", type: "file" });
		XLSX.writeFile(wb, "export.xlsx", { bookType: "xlsx", type: "file" });

		//saveAs(wbBlob, "table.xlsx");
	};

	const getExcelHeader = (activeTab) => {
		// Define header based on the active tab
		if (activeTab === 0) {
			return ["ช่วงอายุ(ปี)", "จำนวนคน", "จำนวนจุดต้องสงสัย", "อายุเฉลี่ย(ปี)"];
		} else if (activeTab === 1) {
			return [
				"เดือน",
				"จำนวนครั้งในการตรวจ",
				"จำนวนจุดต้องสงสัย",
				"อายุเฉลี่ย(ปี)",
			];
		} else if (activeTab === 2) {
			return ["ชื่อ-สกุล", "จำนวนจุดต้องสงสัย", "วันที่ตรวจ", "อายุ"];
		} else if (activeTab === 3) {
			return [
				"โรงพยาบาล",
				"จำนวนการตรวจ",
				"จำนวนการตรวจที่พบฟันผุ",
				"จำนวนการตรวจที่พบอะมัลกัม",
			];
		}
	};

	const renderTableRows = () => {
		let sumNumber = 0;
		let sumSuspiciousPoint = 0;
		let sumMetal = 0;
		let sumAvgAge = 0;

		if (activeTab === 0) {
			return (
				<>
					{tabData.map((row) => {
						sumNumber += row.Number;
						sumSuspiciousPoint += row.suspicious_point_sum;
						sumAvgAge += row.user_age_avg;

						return (
							<Tr key={row.age_range}>
								<Td>{row.age_range}</Td>
								<Td isNumeric>{row.Number}</Td>
								<Td isNumeric>{row.suspicious_point_sum}</Td>
								<Td isNumeric>{row.user_age_avg}</Td>
							</Tr>
						);
					})}
					<Tr bgColor={"gray.100"}>
						<Th style={styles.footerCell}>รวม</Th>
						<Th isNumeric style={styles.footerCell}>
							{sumNumber}
						</Th>
						<Th isNumeric style={styles.footerCell}>
							{sumSuspiciousPoint}
						</Th>
						<Th isNumeric style={styles.footerCell}>
							-
						</Th>
					</Tr>
				</>
			);
		} else if (activeTab === 1) {
			return (
				<>
					{tabData.map((row) => {
						sumNumber += row.Number;
						sumSuspiciousPoint += row.suspicious_point_sum;
						sumAvgAge += row.avg_age;

						return (
							<Tr key={row.Month}>
								<Td>{convertToThaiMonth(row.Month)}</Td>
								<Td isNumeric>{row.Number}</Td>
								<Td isNumeric>{row.suspicious_point_sum}</Td>
								<Td isNumeric>{row.avg_age}</Td>
							</Tr>
						);
					})}
					<Tr bgColor={"gray.100"}>
						<Th style={styles.footerCell}>รวม</Th>
						<Th style={styles.footerCell} isNumeric>
							{sumNumber}
						</Th>
						<Th style={styles.footerCell} isNumeric>
							{sumSuspiciousPoint}
						</Th>
						<Th style={styles.footerCell} isNumeric>
							-
						</Th>
					</Tr>
				</>
			);
		} else if (activeTab === 2) {
			return (
				<>
					{tabData.map((row) => {
						sumSuspiciousPoint += row.suspicious_point;

						return (
							<Tr key={row.id}>
								<Td>
									{row.first_name} {row.last_name}
								</Td>
								<Td textAlign={"center"}>{row.suspicious_point}</Td>
								<Td textAlign={"center"}>
									{formatThaiDate(row.created_datetime)}
								</Td>
								<Td textAlign={"center"}>{calculateAge(row.user_birthday)}</Td>
							</Tr>
						);
					})}
				</>
			);
		} else if (activeTab === 3) {
			return (
				<>
					{tabData.map((row) => {
						sumNumber += row.number;
						sumSuspiciousPoint += row.suspicious_count;
						sumMetal += row.metal_count;

						return (
							<Tr key={row.id}>
								<Td>{row.hospital_name}</Td>
								<Td textAlign={"right"}>{row.number}</Td>
								<Td textAlign={"right"}>{row.suspicious_count}</Td>
								<Td textAlign={"right"}>{row.metal_count}</Td>
							</Tr>
						);
					})}
					{tabData.length !== 0 ? (
						<Tr bgColor={"gray.100"}>
							<Th style={styles.footerCell}>รวม</Th>
							<Th style={styles.footerCell} textAlign={"right"}>
								{sumNumber}
							</Th>
							<Th style={styles.footerCell} textAlign={"right"}>
								{sumSuspiciousPoint}
							</Th>
							<Th style={styles.footerCell} textAlign={"right"}>
								{sumMetal}
							</Th>
						</Tr>
					) : (
						<Tr>
							<Td colSpan={4} textAlign={"center"} py={20}>
								- เลือกสังกัด -
							</Td>
						</Tr>
					)}
				</>
			);
		}
	};

	const AddressPanel = () => {
		return (
			<FormControl mb={8}>
				<FormLabel>สังกัด</FormLabel>
				<Grid
					templateColumns={{
						base: "repeat(2, 1fr)",
						md: "repeat(4, 1fr)",
					}}
					gap={2}
				>
					<Select
						placeholder="- เลือกจังหวัด -"
						onChange={(e) => {
							const selectedIndex = e.target.selectedIndex;
							const selectedOptionText = e.target.options[selectedIndex].text;
							handleSelectChange(
								"province",
								e.target.value,
								selectedOptionText
							);
						}}
						value={selectedOptions.province || ""}
					>
						{provinces.map((province) => (
							<option key={province.id} value={province.id}>
								{province.name}
							</option>
						))}
					</Select>

					<Select
						placeholder="- เลือกอำเภอ -"
						onChange={(e) => {
							const selectedIndex = e.target.selectedIndex;
							const selectedOptionText = e.target.options[selectedIndex].text;
							handleSelectChange(
								"district",
								e.target.value,
								selectedOptionText
							);
						}}
						value={selectedOptions.district || ""}
					>
						{districts.map((district) => (
							<option key={district.id} value={district.id}>
								{district.name}
							</option>
						))}
					</Select>
					<Select
						placeholder="- เลือกตำบล -"
						onChange={(e) => {
							const selectedIndex = e.target.selectedIndex;
							const selectedOptionText = e.target.options[selectedIndex].text;
							handleSelectChange(
								"subdistrict",
								e.target.value,
								selectedOptionText
							);
						}}
						value={selectedOptions.subdistrict || ""}
					>
						{subDistricts.map((subDistrict) => (
							<option key={subDistrict.id} value={subDistrict.id}>
								{subDistrict.name}
							</option>
						))}
					</Select>
					{activeTab != 3 && (
						<Select
							placeholder="- เลือกโรงพยาบาล -"
							onChange={(e) => {
								const selectedIndex = e.target.selectedIndex;
								const selectedOptionText = e.target.options[selectedIndex].text;
								handleSelectChange(
									"hospital",
									e.target.value,
									selectedOptionText
								);
							}}
							value={selectedOptions.hospital || ""}
						>
							{hospitals.map((hospital) => (
								<option key={hospital.id} value={hospital.id}>
									{hospital.name}
								</option>
							))}
						</Select>
					)}
				</Grid>
			</FormControl>
		);
	};

	return (
		<Box p={4}>
			<Card p={4}>
				<Tabs
					isFitted
					variant="line"
					colorScheme="green"
					index={activeTab}
					onChange={handleTabClick}
				>
					<TableContainer bgColor={"gray.100"} p={3}>
						<TabList border={"none"} gap={2}>
							<Tab
								_selected={{ color: "white", bg: "cyan.700" }}
								borderRadius={10}
								fontWeight={"bold"}
							>
								<Flex direction="row" align="center" py={2}>
									<Icon icon="fluent:people-28-filled" width="2em" />
									<Text ml={2}>รายงานอายุผู้ใช้งาน</Text>
								</Flex>
							</Tab>
							<Tab
								_selected={{ color: "white", bg: "cyan.700" }}
								borderRadius={10}
								fontWeight={"bold"}
							>
								<Flex direction="row" align="center" py={2}>
									<Icon icon="solar:syringe-bold" width="2em" />
									<Text ml={2}>รายงานจำนวนครั้งในการตรวจประจำเดือน</Text>
								</Flex>
							</Tab>
							{role === "admin" || role === "doctor" ? (
								<Tab
									_selected={{ color: "white", bg: "cyan.700" }}
									borderRadius={10}
									fontWeight={"bold"}
								>
									<Flex direction="row" align="center" py={2}>
										<Icon icon="fluent:people-add-16-filled" width="2em" />
										<Text ml={2}>รายงานแยกตามผู้ใช้งาน</Text>
									</Flex>
								</Tab>
							) : (
								""
							)}
							{role === "admin" ? (
								<Tab
									_selected={{ color: "white", bg: "cyan.700" }}
									borderRadius={10}
									fontWeight={"bold"}
								>
									<Flex direction="row" align="center" py={2}>
										<Icon
											icon="fluent-emoji-high-contrast:hospital"
											width="2em"
										/>
										<Text ml={1}>รายงานแยกตามโรงพยาบาล</Text>
									</Flex>
								</Tab>
							) : (
								""
							)}
						</TabList>
					</TableContainer>

					{!loading && (
						<TabPanels mt={8} py={1} boxShadow={"md"} borderRadius={10}>
							<TabPanel>
								<Flex>
									<Button
										rightIcon={<Icon icon="file-icons:microsoft-excel" />}
										colorScheme="blue"
										mb={3}
										onClick={exportToExcel}
									>
										Download Excel
									</Button>
									<Spacer />
									<Box textAlign={"right"} mt={3} fontSize={"lg"}>
										{doctorHospitalName}
									</Box>
								</Flex>
								<TableContainer>
									<Table variant="simple" colorScheme="gray" id="table-to-xls1">
										<Thead>
											<Tr bgColor={"cyan.600"}>
												<Th style={styles.headerCell}>ช่วงอายุ(ปี)</Th>
												<Th style={styles.headerCell} isNumeric>
													จำนวนคน
												</Th>
												<Th style={styles.headerCell} isNumeric>
													จำนวนจุดต้องสงสัย
												</Th>
												<Th style={styles.headerCell} isNumeric>
													อายุเฉลี่ย(ปี)
												</Th>
											</Tr>
										</Thead>
										<Tbody>{renderTableRows()}</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
							<TabPanel>
								<Flex>
									<Button
										rightIcon={<Icon icon="file-icons:microsoft-excel" />}
										colorScheme="blue"
										mb={3}
										onClick={exportToExcel}
									>
										Download Excel
									</Button>
									<Spacer />
									<Box textAlign={"right"} mt={3} fontSize={"lg"}>
										{doctorHospitalName}
									</Box>
								</Flex>
								<TableContainer>
									<Table variant="simple" colorScheme="gray" id="table-to-xls2">
										<Thead>
											<Tr bgColor={"cyan.600"}>
												<Th style={styles.headerCell}>เดือน</Th>
												<Th style={styles.headerCell} isNumeric>
													จำนวนครั้งในการตรวจ
												</Th>
												<Th style={styles.headerCell} isNumeric>
													จำนวนจุดต้องสงสัย
												</Th>
												<Th style={styles.headerCell} isNumeric>
													อายุเฉลี่ย(ปี)
												</Th>
											</Tr>
										</Thead>
										<Tbody>{renderTableRows()}</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
							<TabPanel minH={"600px"}>
								{role === "doctor" ? (
									<Flex>
										<Button
											rightIcon={<Icon icon="file-icons:microsoft-excel" />}
											colorScheme="blue"
											mb={3}
											onClick={exportToExcel}
										>
											Download Excel
										</Button>
										<Spacer />
										<Box textAlign={"right"} mt={3} fontSize={"lg"}>
											{doctorHospitalName}
										</Box>
									</Flex>
								) : (
									<>
										{AddressPanel()}
										<Button
											rightIcon={<Icon icon="file-icons:microsoft-excel" />}
											colorScheme="blue"
											mb={3}
											onClick={exportToExcel}
										>
											Download Excel
										</Button>
									</>
								)}
								<TableContainer>
									<Table variant="simple" colorScheme="gray" id="table-to-xls5">
										<Thead bgColor={"cyan.600"}>
											<Tr>
												<Th style={styles.headerCell}>ชื่อ-สกุล</Th>
												<Th style={styles.headerCell} textAlign={"center"}>
													จำนวนจุดต้องสงสัย
												</Th>
												<Th style={styles.headerCell} textAlign={"center"}>
													วันที่ตรวจ
												</Th>
												<Th style={styles.headerCell} textAlign={"center"}>
													อายุ
												</Th>
											</Tr>
										</Thead>
										<Tbody>{renderTableRows()}</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
							<TabPanel minH={"600px"}>
								{AddressPanel()}
								<Button
									rightIcon={<Icon icon="file-icons:microsoft-excel" />}
									colorScheme="blue"
									mb={3}
									onClick={exportToExcel}
								>
									Download Excel
								</Button>
								<TableContainer>
									<Table variant="simple" colorScheme="gray" id="table-to-xls3">
										<Thead bgColor={"cyan.600"}>
											<Tr>
												<Th style={styles.headerCell}>โรงพยาบาล</Th>
												<Th style={styles.headerCell} textAlign={"right"}>
													จำนวนการตรวจ
												</Th>
												<Th style={styles.headerCell} textAlign={"right"}>
													จำนวนการตรวจที่พบฟันผุ
												</Th>
												<Th style={styles.headerCell} textAlign={"right"}>
													จำนวนการตรวจที่พบอะมัลกัม
												</Th>
											</Tr>
										</Thead>
										<Tbody>{renderTableRows()}</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
						</TabPanels>
					)}
					{loading && (
						<Flex h={250} align={"center"} justify={"center"}>
							<Flex>
								<Spinner size={"md"} mr={2} />
								กำลังโหลดข้อมูล...
							</Flex>
						</Flex>
					)}
				</Tabs>
			</Card>
		</Box>
	);
}

export default Report;
