import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Flex,
    useDisclosure,
    Button,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TimeAgo from 'javascript-time-ago';
import th from 'javascript-time-ago/locale/th'; // Import Thai locale

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    ConversationHeader,
    InfoButton,
    TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { MdClear, MdClearAll, MdLayersClear, MdOutlineClear } from 'react-icons/md';
import { API_BASE_URL } from '../../config/apiConfig';

// Initialize Thai locale for TimeAgo
TimeAgo.addLocale(th);
const timeAgo = new TimeAgo('th-TH');

function DiagChatModal({ id, isOpenProp, onCloseProp, initialMessages = [], title = 'Chat' }) {
    const MySwal = withReactContent(Swal);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [chatID, setChatID] = useState(id);
    const [messages, setMessages] = useState(initialMessages);
    const [msgInputValue, setMsgInputValue] = useState("");
    const inputRef = useRef(null);
    const msgListRef = useRef(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const apiKey = "PFb6d9uSIuFLaoQ8WPZ3jOvxL4lXKrY8";
    const [autoScrollToBottom, setAutoScrollToBottom] = useState(false);

    // Save messages to localStorage whenever they change
    useEffect(() => {
        if (chatID && messages.length > 0) {
            localStorage.setItem(`diag_chat_${chatID}`, JSON.stringify(messages));
        }
    }, [messages, chatID]);

    // Load messages from localStorage when chatID changes or modal opens
    useEffect(() => {
        if (id && id !== chatID) {
            setChatID(id);
            checkChatIDExistence(id);
        }
    }, [id]);

    const handleReset = () => {
        MySwal.fire({
            title: 'คุณแน่ใจหรือไม่?',
            text: "คุณต้องการเริ่มการสนทนาใหม่ทั้งหมดหรือไม่?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ใช่, เริ่มใหม่!',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const config = {
                        method: 'post',
                        url: `${API_BASE_URL}/api/site/diag_remove_chat_by_id`, // Update to your API endpoint
                        headers: {
                            'x-api-key': apiKey,
                        },
                        data: {
                            id: chatID
                        },
                    };
        
                    const response = await axios.request(config);
    
                    if (response.data.status === 'success') {
                        setMessages([]);
                        localStorage.removeItem(`diag_chat_${chatID}`);
                        fetchGreetingMessage();
                        inputRef.current?.focus();
                        
                        Swal.fire(
                            'รีเซ็ตแล้ว!',
                            'การสนทนาได้ถูกเริ่มใหม่.',
                            'success'
                        );
                    } else {
                        Swal.fire(
                            'เกิดข้อผิดพลาด!',
                            response.data.message,
                            'error'
                        );
                    }
                } catch (error) {
                    Swal.fire(
                        'เกิดข้อผิดพลาด!',
                        'ไม่สามารถลบการสนทนาได้',
                        'error'
                    );
                    console.error('Error resetting chat session:', error);
                }
            }
        });
    };

    // Function to check if the chat ID exists on the server
    const checkChatIDExistence = async (chatID) => {
        try {
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}/api/site/diag_chat_id_existence`,
                headers: {
                    'x-api-key': apiKey,
                },
                data: { id: chatID },
            };

            const response = await axios.request(config);
            if (response.data.has_id) {
                const storedMessages = localStorage.getItem(`diag_chat_${chatID}`);
                if (storedMessages) {
                    setMessages(JSON.parse(storedMessages));
                } else {
                    fetchGreetingMessage();
                }
            } else {
                // If chat ID doesn't exist, clear messages from localStorage
                localStorage.removeItem(`diag_chat_${chatID}`);
                setMessages([]);
                fetchGreetingMessage();
            }
        } catch (error) {
            console.error('Error checking chat ID existence:', error);
        }
    };

    // Open the modal if isOpenProp is passed as true and fetch greeting
    useEffect(() => {
        if (isOpenProp) {
            onOpen();
            if (!isInitialized) {
                checkChatIDExistence(id);
            }
            inputRef.current?.focus();
        }
    }, [isOpenProp, onOpen]);

    useEffect(() => {
        if (msgListRef && msgListRef.current) {
            msgListRef.current?.scrollToBottom("smooth");
            inputRef.current?.focus();
        }
    }, [messages]);

    // Fetch greeting message when the modal opens
    const fetchGreetingMessage = async () => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_BASE_URL}/api/site/diag_chat`,
            headers: {
                'x-api-key': apiKey,
            },
            data: { id: chatID, msg: "สวัสดี" },
        };

        try {
            setIsFetching(true);
            setIsInitialized(true);
            const response = await axios.request(config);
            const msg = response.data.message;
            const botMessage = msg.candidates[0].content.parts[0].text;

            setMessages((prevMessages) => [
                ...prevMessages,
                { message: botMessage, direction: 'incoming', sender: 'สุขใจ', sentTime: new Date() },
            ]);
        } catch (error) {
            console.error('Error fetching greeting:', error);
        } finally {
            setIsFetching(false);
        }
    };

    function removeTrailingNewlines(str) {
        return str.replace(/\n+$/, '');
    }

    const handleSend = async (message) => {
        // Add the outgoing message with sentTime
        setMessages([...messages, { message, direction: 'outgoing', sentTime: new Date() }]);
        setMsgInputValue("");

        // Call the backend API to handle the user's message
        try {
            setAutoScrollToBottom(false);
            setIsFetching(true);

            const config = {
                method: 'post',
                url: `${API_BASE_URL}/api/site/diag_chat`, // Update to your API endpoint
                headers: {
                    'x-api-key': apiKey,
                },
                data: {
                    id: chatID,
                    msg: message,
                },
            };

            const response = await axios.request(config);
            const botResponseData = response.data.message;
            const botMessage = botResponseData.candidates[0].content.parts[0].text;

            // Add the bot's response to the chat
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: botMessage, direction: 'incoming', sender: 'สุขใจ', sentTime: new Date() },
            ]);

            inputRef.current?.focus();
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsFetching(false);
            setAutoScrollToBottom(true);
            msgListRef.current?.scrollToBottom("smooth");
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onCloseProp || onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton onClick={() => { onClose(); }} />
                <ModalBody>
                    <Flex bgColor="yellow.100" width={"100%"} height="80vh">
                        <MainContainer style={{ height: '100%', width: '100%' }}>
                            <ChatContainer>
                                <ConversationHeader>
                                    <Avatar name="สุขใจ" src="images/sukjai.png" />
                                    <ConversationHeader.Content info="ผู้ช่วยทันตแพทย์" userName="สุขใจ" />
                                    <ConversationHeader.Actions>
                                        {messages.length > 1 && <Button
                                            leftIcon={<MdLayersClear />}
                                            variant="outline"
                                            fontFamily="kanit"
                                            colorScheme='blue'
                                            fontWeight="100"
                                            size="sm"
                                            onClick={handleReset}
                                        >
                                            เริ่มใหม่ (reset)
                                        </Button>}
                                    </ConversationHeader.Actions>
                                </ConversationHeader>

                                <MessageList ref={msgListRef} typingIndicator={isFetching && <TypingIndicator content="สุขใจกำลังพิมพ์..." />}>
                                    {messages.length > 0 && messages.map((m, i) => (
                                        <Message
                                            key={i}
                                            model={{
                                                message: removeTrailingNewlines(m.message),
                                                direction: m.direction,
                                                sender: m.sender,
                                                sentTime: `${m.sentTime}`,
                                            }}
                                        />
                                    ))}
                                </MessageList>
                                <MessageInput
                                    autoFocus={true}
                                    attachButton={false}
                                    disabled={isFetching}
                                    placeholder="พิมพ์ข้อความที่นี้"
                                    onSend={handleSend}
                                    ref={inputRef}
                                />
                            </ChatContainer>
                        </MainContainer>
                    </Flex>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default DiagChatModal;
