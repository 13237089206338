import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Button,
	Box,
	Flex,
	Text,
	Spacer,
	InputGroup,
	InputLeftElement,
	Icon,
	Badge,
	HStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Select,
	Grid,
	useToast,
	TableContainer,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import ReactPaginate from "react-paginate";
import { API_BASE_URL } from "../../../config/apiConfig";
import { useAuth } from "../../../hooks/AuthProvider";

const DoctorListTable = ({ setCountDoctor, countDoctor }) => {
	const toast = useToast();
	const [doctors, setDoctors] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(10); // Default page size
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [totalRecords, setTotalRecords] = useState();
	const [currentPage, setCurrentPage] = useState(1); // State for current page

	const [optionProvince, setOptionProvince] = useState();
	const [optionDistrict, setOptionDistrict] = useState();
	const [optionSubDistrict, setOptionSubDistrict] = useState();
	const [optionHospital, setOptionHospital] = useState();

	const auth = useAuth();
	const role = auth.user.role;
	const headers = {
		Authorization: auth.user.token,
	};

	const tableHeaders = [
		"ชื่อ - สกุล",
		"อีเมลติดต่อ",
		"สังกัดโรงพยาบาล",
		"สถานะ",
	];

	const fetchData = async (pageNumber = 1, searchTerm = "") => {
		try {
			const response1 = axios.post(
				`${API_BASE_URL}/api/site/doctor`,
				{ pageNumber, pageSize, searchTerm },
				{ headers }
			);
			const response2 = axios.get(`${API_BASE_URL}/api/site/provinces`);

			const [data1, data2] = await axios.all([response1, response2]);

			const totalCount = data1.data.totalCount;

			setDoctors(data1.data.doctors);
			setPageCount(Math.ceil(totalCount / pageSize));
			setTotalRecords(totalCount);
			setOptionProvince(data2.data.results);

			if (searchTerm === "") {
				setCountDoctor(totalCount);
			}
		} catch (error) {
			console.error("Error fetching doctors:", error);
		}
	};

	const handlePageChange = ({ selected }) => {
		const newPage = selected + 1;
		setCurrentPage(newPage);
		fetchData(newPage, searchTerm);
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchTerm(value);

		clearTimeout(searchTimeout);

		const newSearchTimeout = setTimeout(() => {
			setCurrentPage(1); // Reset to first page on search
			fetchData(1, value);
		}, 1500);

		setSearchTimeout(newSearchTimeout);
	};

	const handlePageSizeChange = (e) => {
		setPageSize(parseInt(e.target.value, 10));
		setCurrentPage(1); // Reset to first page on page size change
	};

	useEffect(() => {
		fetchData(currentPage, searchTerm);
	}, [pageSize, currentPage]);

	const fetchDistricts = async (provinceId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/districts/${provinceId}`
			);
			setOptionDistrict(response.data.results);
			setOptionSubDistrict([]);
			setOptionHospital([]);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const fetchSubDistricts = async (districtId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/subdistricts/${districtId}`
			);
			setOptionSubDistrict(response.data.results);
			setOptionHospital([]);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const fetchHospital = async (subdistrictId) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/api/site/hospital/${subdistrictId}`
			);
			setOptionHospital(response.data.results);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	// Add New Doctor
	const [newDoctor, setNewDoctor] = useState(null);
	const [isNewDoctorModalOpen, setIsNewDoctorModalOpen] = useState(false);
	const handleNewDoctorModalOpen = () => {
		setNewDoctor(null);
		setIsNewDoctorModalOpen(true);
	};

	const validateNewDoctor = () => {
		if (
			!newDoctor ||
			!newDoctor.first_name ||
			!newDoctor.last_name ||
			!newDoctor.birthday ||
			!newDoctor.email ||
			!newDoctor.id_card ||
			!newDoctor.hospitals_id ||
			!newDoctor.status
		) {
			toast({
				title: "กรุณากรอกข้อมูลให้ครบก่อนยืนยัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return false;
		} else if (newDoctor.id_card.toString().length !== 13) {
			toast({
				title: "เลขบัตรประจำตัวต้องเป็นตัวเลข 13 หลัก",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return false;
		}
		return true;
	};

	const handleAddNewDoctor = async () => {
		if (validateNewDoctor() && validateEmail(newDoctor.email)) {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/doctor_add`,
					{ newDoctor },
					{ headers }
				);

				if (response.data.message === "success") {
					await fetchData(currentPage, searchTerm); // Fetch data with the current page
					setIsNewDoctorModalOpen(false);
					setNewDoctor(null);
					setCountDoctor(countDoctor + 1);
					toast({
						title: "ส่งอีเมลแจ้งให้ผู้ใช้งานเข้าไปทำการตั้งค่ารหัสผ่านเรียบร้อย",
						position: "top-right",
						status: "success",
						isClosable: true,
					});
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						duration: 30000,
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	// Edit Doctor
	const [editDoctor, setEditDoctor] = useState(null);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const handleEdit = (row) => {
		row["birthday"] = convertToBangkokTime(row.birthday);
		setEditDoctor(row);
		setIsEditModalOpen(true);
		fetchDataSequentially(
			row.province_id,
			row.districts_id,
			row.sub_districts_id
		);
	};

	const fetchDataSequentially = async (
		provinceId,
		districtId,
		subdistrictId
	) => {
		try {
			await fetchDistricts(provinceId);
			await fetchSubDistricts(districtId);
			await fetchHospital(subdistrictId);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const validateEditDoctor = () => {
		if (
			!editDoctor ||
			!editDoctor.first_name ||
			!editDoctor.last_name ||
			!editDoctor.birthday ||
			!editDoctor.email ||
			!editDoctor.id_card ||
			!editDoctor.hospitals_id ||
			!editDoctor.status
		) {
			toast({
				title: "กรุณากรอกข้อมูลให้ครบก่อนยืนยัน",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return false;
		} else if (editDoctor.id_card.toString().length !== 13) {
			toast({
				title: "เลขบัตรประจำตัวต้องเป็นตัวเลข 13 หลัก",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return false;
		}
		return true;
	};

	const handleEditDone = async () => {
		if (validateEditDoctor() && validateEmail(editDoctor.email)) {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/doctor_edit`,
					{ editedDoctor: editDoctor },
					{ headers }
				);

				if (response.data.message === "success") {
					await fetchData(currentPage, searchTerm); // Fetch data with the current page
					setIsEditModalOpen(false);
					toast({
						title: "เรียบร้อย",
						position: "top-right",
						status: "success",
						isClosable: true,
					});
				} else {
					toast({
						title: response.data.message,
						position: "top-right",
						status: "warning",
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: "ไม่สามารถติดต่อ server ได้",
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
				console.error("Error making API calls:", error.message);
			}
		}
	};

	const convertToBangkokTime = (utcTimeString) => {
		const utcDate = new Date(utcTimeString);
		const bangkokTimeOffset = 7 * 60 * 60 * 1000; // Bangkok is UTC+7
		const bangkokTime = new Date(utcDate.getTime() + bangkokTimeOffset);
		return bangkokTime.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
	};

	// Delete Doctor
	const [deleteDoctorId, setDeleteDoctorId] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const handleDelete = (userId) => {
		setDeleteDoctorId(userId);
		setIsDeleteModalOpen(true);
	};

	const handleDeleteConfirm = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/doctor_delete`,
				{ deleteDoctorId },
				{ headers }
			);

			if (response.data.message === "success") {
				await fetchData(currentPage, searchTerm); // Fetch data with the current page
				setIsDeleteModalOpen(false);
				setCountDoctor(countDoctor - 1);
			} else {
				toast({
					title: response.data.message,
					position: "top-right",
					status: "warning",
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: "ไม่สามารถติดต่อ server ได้",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			console.error("Error making API calls:", error.message);
		}
	};

	const handleDeleteCancel = () => {
		setIsDeleteModalOpen(false);
	};

	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (re.test(String(email).toLowerCase())) {
			return true;
		} else {
			toast({
				title: "รูปแบบอีเมลไม่ถูกต้อง",
				position: "top-right",
				status: "warning",
				isClosable: true,
			});
			return false;
		}
	};

	return (
		<>
			<Flex mb={3}>
				<Box>
					<Button p={3} colorScheme="teal" onClick={handleNewDoctorModalOpen}>
						เพิ่มหมอ
					</Button>
				</Box>
				<Spacer />
				<Box>
					<InputGroup>
						<InputLeftElement
							pointerEvents="none"
							children={<Icon as={SearchIcon} color="gray.300" />}
						/>
						<Input
							placeholder="ค้นหาชื่อ..."
							value={searchTerm}
							onChange={handleSearch}
						/>
					</InputGroup>
				</Box>
			</Flex>
			<Box border="1px solid" borderColor="gray.200" borderRadius="md">
				<Flex
					mb={2}
					pb={3}
					px={3}
					justify={"space-between"}
					bgColor="gray.50"
					border="1px solid"
					borderColor="gray.200"
				>
					<Select
						maxW={150}
						mt={3}
						size="sm"
						value={pageSize}
						onChange={handlePageSizeChange}
					>
						<option value={10}>แสดง 10 รายการ</option>
						<option value={20}>แสดง 20 รายการ</option>
						<option value={50}>แสดง 50 รายการ</option>
						{/* Add more options as needed */}
					</Select>
					<Text mt={4} color="gray">
						พบ {totalRecords} รายการ
					</Text>
					<ReactPaginate
						pageCount={pageCount}
						pageRangeDisplayed={5}
						marginPagesDisplayed={2}
						onPageChange={handlePageChange}
						previousLabel="ก่อนหน้า"
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextLabel="ถัดไป"
						nextClassName="page-item"
						nextLinkClassName="page-link"
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName="page-link"
						containerClassName="pagination"
						activeClassName="active"
						activeLinkClassName="active-link"
						renderOnZeroPageCount={null}
					/>
				</Flex>
				<TableContainer>
					<Table variant="simple">
						<Thead>
							<Tr>
								{tableHeaders.map((header, index) => (
									<Th
										key={index}
										style={{ fontSize: "16px", fontWeight: "900" }}
									>
										{header}
									</Th>
								))}
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{doctors?.map((doctor) => (
								<Tr key={doctor.id}>
									<Td>
										{doctor.first_name} {doctor.last_name}
									</Td>
									<Td>{doctor.email}</Td>
									<Td>{doctor.hospital}</Td>
									<Td>
										{doctor.status === "active" ? (
											<Badge colorScheme="green">Active</Badge>
										) : (
											<Badge colorScheme="red">Inactive</Badge>
										)}
									</Td>
									<Td>
										<HStack spacing={2}>
											<button onClick={() => handleEdit(doctor)}>
												<EditIcon boxSize={5} color="blue.500" />
											</button>
											<button onClick={() => handleDelete(doctor.id)}>
												<DeleteIcon boxSize={5} color="red.500" />
											</button>
										</HStack>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>

			{/* Add New Doctor Modal */}
			<Modal
				isOpen={isNewDoctorModalOpen}
				onClose={() => setIsNewDoctorModalOpen(false)}
				size={"3xl"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color="green">เพิ่มหมอใหม่</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl mb="3">
								<FormLabel>ชื่อ</FormLabel>
								<Input
									type="text"
									value={newDoctor?.first_name}
									onChange={(e) =>
										setNewDoctor((prevDoctor) => ({
											...prevDoctor,
											first_name: e.target.value,
										}))
									}
								/>
							</FormControl>
							<FormControl mb="3">
								<FormLabel>สกุล</FormLabel>
								<Input
									type="text"
									value={newDoctor?.last_name}
									onChange={(e) =>
										setNewDoctor((prevDoctor) => ({
											...prevDoctor,
											last_name: e.target.value,
										}))
									}
								/>
							</FormControl>
						</Grid>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl mb="3">
								<FormLabel>วันเกิด</FormLabel>
								<Input
									type="date"
									value={newDoctor?.birthday}
									onChange={(e) =>
										setNewDoctor((prevDoctor) => ({
											...prevDoctor,
											birthday: e.target.value,
										}))
									}
								/>
							</FormControl>
							<FormControl mb="3">
								<FormLabel>อีเมล</FormLabel>
								<Input
									type="text"
									value={newDoctor?.email}
									onChange={(e) =>
										setNewDoctor((prevDoctor) => ({
											...prevDoctor,
											email: e.target.value,
										}))
									}
								/>
							</FormControl>
						</Grid>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl mb="3">
								<FormLabel>เลขบัตรประชาชน</FormLabel>
								<Input
									type="number"
									maxLength={13}
									value={newDoctor?.id_card}
									onChange={(e) =>
										setNewDoctor((prevDoctor) => ({
											...prevDoctor,
											id_card: e.target.value,
										}))
									}
								/>
							</FormControl>
							<FormControl mb="3">
								<FormLabel>สถานะ</FormLabel>
								<Select
									value={newDoctor?.status}
									placeholder="- เลือก -"
									onChange={(e) =>
										setNewDoctor((prevDoctor) => ({
											...prevDoctor,
											status: e.target.value,
										}))
									}
								>
									<option value="active">active</option>
									<option value="inactive">inactive</option>
								</Select>
							</FormControl>
						</Grid>

						<FormControl my="4">
							<FormLabel>สังกัดโรงพยาบาล</FormLabel>
							<Select
								value={newDoctor?.province_id}
								placeholder="- เลือจังหวัด -"
								onChange={(e) => {
									const selectedProvinceId = e.target.value;
									setNewDoctor((prevDoctor) => ({
										...prevDoctor,
										province_id: selectedProvinceId,
									}));
									fetchDistricts(selectedProvinceId);
								}}
							>
								{optionProvince &&
									optionProvince.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
							<Select
								mt={3}
								value={newDoctor?.districts_id}
								placeholder="- เลือกอำเภอ -"
								onChange={(e) => {
									const selectedDistrictId = e.target.value;
									setNewDoctor((prevDoctor) => ({
										...prevDoctor,
										districts_id: selectedDistrictId,
									}));
									fetchSubDistricts(selectedDistrictId);
								}}
							>
								{optionDistrict &&
									optionDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
							<Select
								mt={3}
								value={newDoctor?.sub_districts_id}
								placeholder="- เลือกตำบล -"
								onChange={(e) => {
									const selectedSubDistrictId = e.target.value;
									setNewDoctor((prevDoctor) => ({
										...prevDoctor,
										sub_districts_id: selectedSubDistrictId,
									}));
									fetchHospital(selectedSubDistrictId);
								}}
							>
								{optionSubDistrict &&
									optionSubDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
							<Select
								mt={3}
								value={newDoctor?.hospitals_id}
								placeholder="- เลือกโรงพยาบาล -"
								onChange={(e) =>
									setNewDoctor((prevDoctor) => ({
										...prevDoctor,
										hospitals_id: e.target.value,
										hospital_name: e.target.selectedOptions[0].textContent,
									}))
								}
							>
								{optionHospital &&
									optionHospital?.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="green" onClick={handleAddNewDoctor}>
							ยืนยัน
						</Button>
						<Button onClick={() => setIsNewDoctorModalOpen(false)} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Edit Modal */}
			<Modal
				isOpen={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
				size={"3xl"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"blue.500"}>แก้ไขข้อมูล</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl mb="3">
								<FormLabel>ชื่อ</FormLabel>
								<Input
									type="text"
									value={editDoctor?.first_name}
									onChange={(e) =>
										setEditDoctor((prevDoctor) => ({
											...prevDoctor,
											first_name: e.target.value,
										}))
									}
								/>
							</FormControl>
							<FormControl mb="3">
								<FormLabel>สกุล</FormLabel>
								<Input
									type="text"
									value={editDoctor?.last_name}
									onChange={(e) =>
										setEditDoctor((prevDoctor) => ({
											...prevDoctor,
											last_name: e.target.value,
										}))
									}
								/>
							</FormControl>
						</Grid>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl mb="3">
								<FormLabel>วันเกิด</FormLabel>
								<Input
									type="date"
									value={editDoctor?.birthday}
									onChange={(e) =>
										setEditDoctor((prevDoctor) => ({
											...prevDoctor,
											birthday: e.target.value,
										}))
									}
								/>
							</FormControl>
							<FormControl mb="3">
								<FormLabel>อีเมล</FormLabel>
								<Input
									type="text"
									value={editDoctor?.email}
									onChange={(e) =>
										setEditDoctor((prevDoctor) => ({
											...prevDoctor,
											email: e.target.value,
										}))
									}
								/>
							</FormControl>
						</Grid>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl mb="3">
								<FormLabel>เลขบัตรประชาชน</FormLabel>
								<Input
									type="number"
									value={editDoctor?.id_card}
									onChange={(e) =>
										setEditDoctor((prevDoctor) => ({
											...prevDoctor,
											id_card: e.target.value,
										}))
									}
								/>
							</FormControl>
							<FormControl mb="3">
								<FormLabel>สถานะ</FormLabel>
								<Select
									value={editDoctor?.status}
									placeholder="- เลือก -"
									onChange={(e) =>
										setEditDoctor((prevDoctor) => ({
											...prevDoctor,
											status: e.target.value,
										}))
									}
								>
									<option value="active">active</option>
									<option value="inactive">inactive</option>
								</Select>
							</FormControl>
						</Grid>

						<FormControl my="4">
							<FormLabel>สังกัดโรงพยาบาล</FormLabel>
							<Select
								value={editDoctor?.province_id}
								placeholder="- เลือจังหวัด -"
								onChange={(e) => {
									const selectedProvinceId = e.target.value;
									setEditDoctor((prevDoctor) => ({
										...prevDoctor,
										province_id: selectedProvinceId,
									}));
									fetchDistricts(selectedProvinceId);
								}}
							>
								{optionProvince &&
									optionProvince.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
							<Select
								mt={3}
								value={editDoctor?.districts_id}
								placeholder="- เลือกอำเภอ -"
								onChange={(e) => {
									const selectedDistrictId = e.target.value;
									setEditDoctor((prevDoctor) => ({
										...prevDoctor,
										districts_id: selectedDistrictId,
									}));
									fetchSubDistricts(selectedDistrictId);
								}}
							>
								{optionDistrict &&
									optionDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
							<Select
								mt={3}
								value={editDoctor?.sub_districts_id}
								placeholder="- เลือกตำบล -"
								onChange={(e) => {
									const selectedSubDistrictId = e.target.value;
									setEditDoctor((prevDoctor) => ({
										...prevDoctor,
										sub_districts_id: selectedSubDistrictId,
									}));
									fetchHospital(selectedSubDistrictId);
								}}
							>
								{optionSubDistrict &&
									optionSubDistrict.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
							<Select
								mt={3}
								value={editDoctor?.hospitals_id}
								placeholder="- เลือกโรงพยาบาล -"
								onChange={(e) =>
									setEditDoctor((prevDoctor) => ({
										...prevDoctor,
										hospitals_id: e.target.value,
									}))
								}
							>
								{optionHospital &&
									optionHospital?.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" onClick={handleEditDone}>
							บันทึก
						</Button>
						<Button onClick={() => setIsEditModalOpen(false)} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"red.500"}>ลบข้อมูล</ModalHeader>
					<ModalCloseButton />
					<ModalBody>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" onClick={handleDeleteConfirm}>
							ลบ
						</Button>
						<Button onClick={handleDeleteCancel} ml={2}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default DoctorListTable;
