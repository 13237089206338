import React, { useState, useEffect } from "react";
import { useLocation, Outlet, Link as ReactRouterLink } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import {
  Text,
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import {
  FiMenu,
  FiPieChart,
  FiActivity,
  FiUser,
  FiList,
  FiFile,
  FiCompass,
  FiUsers,
} from "react-icons/fi";

export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNavItem, setSelectedNavItem] = useState();

  return (
    <Box
      pt={"60px"}
      minH="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      <SidebarContent
        onClose={onClose}
        selectedNavItem={selectedNavItem}
        handleNavItemClick={setSelectedNavItem}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            handleNavItemClick={setSelectedNavItem}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        display={{ base: "flex", md: "none" }}
        onOpen={onOpen}
        //handleNavItemClick={setSelectedNavItem} // Pass handleNavItemClick to MobileNav
      />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  selectedNavItem,
  handleNavItemClick,
  ...rest
}) => {
  const LinkItemsAdmin = [
    { name: "สถิติ", code: "dashboard", path: "/dashboard", icon: FiPieChart },
    {
      name: "รายการวินิจฉัยทั้งหมด",
      code: "diagnosis",
      path: "/diagnosis",
      icon: FiActivity,
    },
    {
      name: "จัดการผู้ใช้งาน",
      code: "usermanagement",
      path: "/usermanagement",
      icon: FiCompass,
    },
    { name: "รายงาน", code: "report", path: "/report", icon: FiFile },
  ];
  const LinkItemsDoctor = [
    { name: "สถิติ", code: "dashboard", path: "/dashboard", icon: FiPieChart },
    {
      name: "รายการวินิจฉัยทั้งหมด",
      code: "diagnosis",
      path: "/diagnosis",
      icon: FiActivity,
    },
    { name: "รายงาน", code: "report", path: "/report", icon: FiFile },
    { name: "ผู้ใช้งานในสังกัด", code: "userlist", path: "/userlist", icon: FiUsers },
  ];
  const LinkItemsPublic = [
    { name: "สถิติ", code: "dashboard", path: "/dashboard", icon: FiPieChart },
    {
      name: "รายการวินิจฉัยทั้งหมด",
      code: "diagnosis",
      path: "/diagnosis",
      icon: FiActivity,
    },
    {
      name: "รายการวินิจฉัยส่วนตัว",
      code: "profile",
      path: "/profile",
      icon: FiUser,
    },
    {
      name: "รายชื่อผู้ใต้ความดูแล",
      code: "undercare",
      path: "/undercare",
      icon: FiList,
    },
    { name: "รายงาน", code: "report", path: "/report", icon: FiFile },
  ];

  const auth = useAuth();
  const LinkItems =
    auth.user.role === "admin"
      ? LinkItemsAdmin
      : auth.user.role === "doctor"
      ? LinkItemsDoctor
      : LinkItemsPublic;

  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    const selectedItem = LinkItems.find((item) => item.path === currentPath);
    if (selectedItem) {
      handleNavItemClick(selectedItem.code);
    }
  }, [currentPath, handleNavItemClick, LinkItems]);

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      pt={4}
      {...rest}
    >
      <Flex h="10" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold"></Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map(({ name, icon, code, path }) => (
        <NavItem
          key={name}
          icon={icon}
          as={ReactRouterLink}
          to={path}
          selected={selectedNavItem === code}
          onClick={() => handleNavItemClick(code)}
          mb={1}
          pl={3}
        >
          {name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, selected, onClick, to, ...rest }) => {
  return (
    <Box onClick={onClick}>
      <Flex
        as={Link}
        to={to}
        align="center"
        p="4"
        mx="4"
        cursor="pointer"
        _hover={{
          bg: "green.700",
          color: "white",
        }}
        bg={selected ? "green.700" : ""}
        color={selected ? "white" : ""}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="16"
      alignItems="center"
      bg={useColorModeValue("green.700", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        color={"white"}
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};
