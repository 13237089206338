import { Box, Button, Flex, HStack } from "@chakra-ui/react";
import { converterToAge, converterToThaiDate } from "./dateUtils";
import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import moment from "moment";

export const validateNewUndercare = (userData, toast) => {
    console.log(userData)
    if (
        !userData ||
        typeof userData.first_name !== "string" ||
        !userData.first_name.trim() ||
        typeof userData.last_name !== "string" ||
        !userData.last_name.trim() ||
        !userData.id_card ||
        typeof userData.id_card !== "string" ||
        !userData.hospitals_id
    ) {
        toast({
            title: "กรุณากรอกข้อมูลให้ครบก่อนยืนยัน",
            description: (
                <>
                    <p>- ชื่อ สกุล</p>
                    <p>- บัตรประชาชน</p>
                    <p>- โรงพยาบาล</p>
                </>
            ),
            position: "top-right",
            status: "warning",
            isClosable: true,
        });
        return false;
    } else {
        const numericId = Number(userData.id_card);
        const isValidLength = userData.id_card.trim().length === 13;
        const isValidNumber = !isNaN(numericId) && Number.isInteger(numericId);

        if (!isValidLength || !isValidNumber) {
            toast({
                title: "รหัสประจำตัวประชาชนไม่ถูกต้อง",
                position: "top-right",
                status: "warning",
                isClosable: true,
            });
            return false;
        }
    }
    return true;
};

export const getUndercareColumns = (handleEdit, handleDelete) => {
    return [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: false,
            width: "40px",
            cell: (row, index) => <span>{index + 1}</span>,
        },
        {
            name: "ชื่อ - สกุล",
            selector: (row) => row.fullName,
            sortable: true,
            cell: (row) => (
                <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                    <Box>
                        {row.first_name} {row.last_name}
                    </Box>
                </Flex>
            ),
        },
        {
            name: "บัตรประชาชน",
            selector: (row) => row.id_card,
            sortable: true,
        },
        {
            name: "วันเกิด",
            selector: (row) => row.birthday,
            sortable: true,
            cell: (row) => (
                <Box style={{ whiteSpace: "break-spaces" }}>
                    {(row.birthday !== null && row.birthday !== "") ? (
                        <>
                            {converterToThaiDate(row.birthday)} <br />
                            อายุ {converterToAge(row.birthday)} ปี
                        </>
                    ) : (
                        ""
                    )}
                </Box>
            ),
        },
        {
            name: "สังกัด/หน่วยงาน",
            selector: (row) => row.organization,
            sortable: true,
            cell: (row) => (
                <Box style={{ whiteSpace: "break-spaces" }}>{row.organization}</Box>
            ),
        },
        {
            name: "สังกัด/หน่วยงาน ย่อย",
            selector: (row) => row.organization_sub,
            sortable: true,
            cell: (row) => (
                <Box style={{ whiteSpace: "break-spaces" }}>{row.organization_sub}</Box>
            ),
        },
        {
            name: "สังกัดโรงพยาบาล",
            selector: (row) => row.hospital,
            sortable: true,
            cell: (row) => (
                <Box style={{ whiteSpace: "break-spaces" }}>{row.hospital}</Box>
            ),
        },
        {
            name: "บันทึกการวินิจฉัย",
            selector: (row) => row.diagnosis,
            sortable: true,
            cell: (row) => (
                <ReactRouterLink to={`/diagnosis/${row.id}`}>
                    คลิ๊กดู <ExternalLinkIcon mx="2px" />
                </ReactRouterLink>
            ),
        },
        {
            name: "",
            width: "80px",
            cell: (row) => (
                <HStack spacing={2}>
                    <Button onClick={() => handleEdit(row)}>
                        <EditIcon boxSize={5} color="blue.500" />
                    </Button>
                    <Button onClick={() => handleDelete(row.id)}>
                        <DeleteIcon boxSize={5} color="red.500" />
                    </Button>
                </HStack>
            ),
        },
    ];
};

export const validateFirstName = (firstName) => {
    if (typeof firstName !== "string" || !firstName.trim()) {
        return "ระบุชื่อให้ถูกต้อง";
    }
    return true;
};

export const validateLastName = (lastName) => {
    if (typeof lastName !== "string" || !lastName.trim()) {
        return "ระบุสกุลให้ถูกต้อง";
    }
    return true;
};

export const validateBirthDate = (birthDate) => {
    // Check if the serial is a number and within a reasonable range
    //console.log(`birthDate: ${birthDate}`)
    if (
        typeof birthDate !== "number" ||
        isNaN(birthDate) ||
        birthDate < 1 ||
        birthDate > 2958465
    ) {
        return "รูปแบบวันเกิดต้องเป็น วัน/เดือน/ปี";
    }

    // Check if the serial is an integer
    if (!Number.isInteger(birthDate)) {
        return "รูปแบบวันเกิดต้องเป็น วัน/เดือน/ปี";
    }

    return true;
};