const first = {
	rest: {
		rotate: "-15deg",
		scale: 0.95,
		x: "-50%",
		filter: "grayscale(80%)",
		transition: {
			duration: 0.5,
			type: "tween",
			ease: "easeIn",
		},
	},
	hover: {
		x: "-70%",
		scale: 1.1,
		rotate: "-20deg",
		filter: "grayscale(0%)",
		transition: {
			duration: 0.4,
			type: "tween",
			ease: "easeOut",
		},
	},
};

const second = {
	rest: {
		rotate: "15deg",
		scale: 0.95,
		x: "50%",
		filter: "grayscale(80%)",
		transition: {
			duration: 0.5,
			type: "tween",
			ease: "easeIn",
		},
	},
	hover: {
		x: "70%",
		scale: 1.1,
		rotate: "20deg",
		filter: "grayscale(0%)",
		transition: {
			duration: 0.4,
			type: "tween",
			ease: "easeOut",
		},
	},
};

const third = {
	rest: {
		scale: 1.1,
		filter: "grayscale(80%)",
		transition: {
			duration: 0.5,
			type: "tween",
			ease: "easeIn",
		},
	},
	hover: {
		scale: 1.3,
		filter: "grayscale(0%)",
		transition: {
			duration: 0.4,
			type: "tween",
			ease: "easeOut",
		},
	},
};

export default { first, second, third };
